import React from 'react';
import { Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

const NoMetricsMessage = () => (
  <Typography variant="h6" align="center">
    No se está haciendo seguimiento de ninguna métrica. Puedes actualizar tu configuración 
    <MuiLink component={Link} to="/onboarding" style={{ marginLeft: '5px' }}>
      aquí
    </MuiLink>.
  </Typography>
);

export default NoMetricsMessage;