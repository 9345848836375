import React, { useState, useEffect } from 'react';
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Autocomplete, 
  TextField, 
  Box, 
  Chip,
  Typography,
  CircularProgress
} from '@mui/material';
import { useAuth } from '../../../context/AuthContext';

const DietaryHabits = ({ userData, handleInputChange }) => {
  const [ingredientCategories, setIngredientCategories] = useState([]);
  const [allergens, setAllergens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { authToken } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const categoriesResponse = await fetch(`${process.env.REACT_APP_API_URL}/ingredient-categories`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });
        const allergensResponse = await fetch(`${process.env.REACT_APP_API_URL}/allergens`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });

        if (!categoriesResponse.ok || !allergensResponse.ok) {
          throw new Error('Error al obtener datos');
        }

        const categoriesData = await categoriesResponse.json();
        const allergensData = await allergensResponse.json();

        setIngredientCategories(categoriesData);
        setAllergens(allergensData);
      } catch (error) {
        console.error('Error:', error);
        setError('No se pudieron cargar los datos');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authToken]);

  const handleAllergensChange = (event, newValue) => {
    handleInputChange('allergies', newValue.map(allergen => allergen._id));
  };

  const handleDislikedFoodsChange = (event, newValue) => {
    handleInputChange('dislikedFoods', newValue.map(category => category._id));
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      {/* <FormControl fullWidth margin="normal">
        <InputLabel>Frecuencia de comidas</InputLabel>
        <Select
          value={userData.mealsPerDay}
          onChange={(e) => handleInputChange('mealsPerDay', e.target.value)}
        >
          {[3, 4, 5, 6].map((num) => (
            <MenuItem key={num} value={num}>{num} comidas por día</MenuItem>
          ))}
        </Select>
      </FormControl> */}

      <Autocomplete
        multiple
        options={allergens}
        getOptionLabel={(option) => option.name}
        value={allergens.filter(allergen => userData.allergies.includes(allergen._id))}
        onChange={handleAllergensChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Alergias o intolerancias"
            placeholder="Selecciona alérgenos"
            margin="normal"
            fullWidth
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option.name} {...getTagProps({ index })} />
          ))
        }
      />

      <Autocomplete
        multiple
        options={ingredientCategories}
        getOptionLabel={(option) => option.name}
        value={ingredientCategories.filter(category => userData.dislikedFoods.includes(category._id))}
        onChange={handleDislikedFoodsChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Categorías de alimentos que no te gustan"
            placeholder="Selecciona categorías"
            margin="normal"
            fullWidth
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option.name} {...getTagProps({ index })} />
          ))
        }
      />
    </Box>
  );
};

export default DietaryHabits;