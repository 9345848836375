import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Grid, Typography } from '@mui/material';

function EditarAlergeno() {
  const [alergeno, setAlergeno] = useState({ name: '', description: '' });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/allergens/${id}`)
      .then(response => response.json())
      .then(data => setAlergeno(data))
      .catch(error => console.error('Error:', error));
  }, [id]);

  const handleChange = (event) => {
    setAlergeno({ ...alergeno, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/allergens/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(alergeno),
    })
      .then(response => response.json())
      .then(() => {
        navigate('/gestion-taxonomias');
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Editar Alérgeno</Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Nombre"
            name="name"
            value={alergeno.name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Descripción"
            name="description"
            value={alergeno.description}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
          />
          <Button type="submit" variant="contained" color="primary">
            Guardar Cambios
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}

export default EditarAlergeno;