import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

function EditarRestriccion() {
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/restricciones/${id}`)
      .then(response => response.json())
      .then(data => setName(data.name))
      .catch(error => console.error(error));
  }, [id]);

  const handleSave = () => {
    fetch(`${process.env.REACT_APP_API_URL}/restricciones/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      navigate('/gestion-taxonomias'); // Redirige a /gestion-taxonomias después de guardar correctamente
    })
    .catch(error => console.error(error));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField label="Nombre" value={name} onChange={e => setName(e.target.value)} />
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={handleSave}>Guardar</Button>
      </Grid>
    </Grid>
  );
}

export default EditarRestriccion;