import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Alert, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CancelOutlined from '@mui/icons-material/CancelOutlined';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const passwordCriteria = [
    { label: 'Al menos 8 caracteres', test: (password) => password.length >= 8 },
    { label: 'Una letra mayúscula', test: (password) => /[A-Z]/.test(password) },
    { label: 'Una letra minúscula', test: (password) => /[a-z]/.test(password) },
    { label: 'Un número', test: (password) => /\d/.test(password) },
    { label: 'Un carácter especial', test: (password) => /[\W_]/.test(password) },
  ];

  const isPasswordSecure = (password) => {
    return passwordCriteria.every((criteria) => criteria.test(password));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    setError('');

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Tu contraseña ha sido restablecida con éxito.');
        setTimeout(() => navigate('/login'), 3000);
      } else {
        setError(data.message || 'Error al restablecer la contraseña.');
      }
    } catch (error) {
      setError('Error al conectar con el servidor');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Restablecer Contraseña
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Nueva Contraseña"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <List>
            {passwordCriteria.map((criteria, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  {criteria.test(password) ? <CheckCircleOutline color="success" /> : <CancelOutlined color="error" />}
                </ListItemIcon>
                <ListItemText primary={criteria.label} />
              </ListItem>
            ))}
          </List>
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirmar Nueva Contraseña"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {message && <Alert severity="success" sx={{ mt: 2 }}>{message}</Alert>}
          {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Restablecer Contraseña
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default ResetPassword;