import React, { useState, useEffect, useMemo, useCallback, createContext, useRef, useContext } from "react";
import { useAuth } from "../context/AuthContext";
import { useMediaQuery } from "@mui/material"; // Importar useTheme
import { useTheme } from '@mui/material/styles';

import './MenuSemanal.css';
import {
  addDays,
  subDays,
  addWeeks,
  subWeeks,
  format,
  eachDayOfInterval,
  isMonday,
  startOfWeek,
  endOfWeek,
  isValid,
  addHours,
} from "date-fns";
import { es } from "date-fns/locale";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import CommentIcon from '@mui/icons-material/Comment';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Divider from '@mui/material/Divider';
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer, PolarAngleAxis } from 'recharts';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import {
  Tabs,
  Tab,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Modal,
  CircularProgress,
  LinearProgress,
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Search as SearchIcon } from '@mui/icons-material';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';
import LockIcon from '@mui/icons-material/Lock';


export const MacrosDailyContext = createContext();
export const MacrosDailyProvider = ({ children }) => {
  const createDayMacros = () => ({
    date: null,
    meals: {
      "Desayuno": { kilocalories: 0, carbs: 0, protein: 0, fat: 0 },
      "Media mañana": { kilocalories: 0, carbs: 0, protein: 0, fat: 0 },
      "Comida": { kilocalories: 0, carbs: 0, protein: 0, fat: 0 },
      "Media tarde": { kilocalories: 0, carbs: 0, protein: 0, fat: 0 },
      "Cena": { kilocalories: 0, carbs: 0, protein: 0, fat: 0 }
    }
  });

  const initialMacros = {
    "Lunes": createDayMacros(),
    "Martes": createDayMacros(),
    "Miércoles": createDayMacros(),
    "Jueves": createDayMacros(),
    "Viernes": createDayMacros(),
    "Sábado": createDayMacros(),
    "Domingo": createDayMacros()
  };

  const [totalMacronutrientsForDay, setTotalMacronutrientsForDay] = useState(initialMacros);

  // useEffect(() => {
  //   console.log("Updated totalMacronutrientsForDay Estado: ", totalMacronutrientsForDay);
  // }, [totalMacronutrientsForDay]);

  // Función para actualizar macronutrientes
  const updateTotalMacronutrientsForDay = (date, mealName, macros) => {
    //console.log("updateTotalMacronutrientsForDayLog", date, mealName, macros)

    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const dayName = days[date.getDay()];

    setTotalMacronutrientsForDay(prevMacros => {
      const dayMacros = prevMacros[dayName];

      // Si la fecha proporcionada no coincide con la almacenada para ese día, resetea los macros para ese día
      if (dayMacros.date && (dayMacros.date.getDate() !== date.getDate() ||
        dayMacros.date.getMonth() !== date.getMonth() ||
        dayMacros.date.getFullYear() !== date.getFullYear())) {
        dayMacros.meals = createDayMacros().meals;
      }

      // Actualiza la fecha y los macros para la comida específica
      dayMacros.date = date;
      dayMacros.meals[mealName] = macros;

      return {
        ...prevMacros,
        [dayName]: dayMacros
      };
    });
  };

  return (
    <MacrosDailyContext.Provider value={{ totalMacronutrientsForDay, updateTotalMacronutrientsForDay }}>
      {children}
    </MacrosDailyContext.Provider>
  );
};

function MenuSemanal() {
  const { authToken } = useAuth();
  const theme = useTheme(); // Usar el tema
  const [menus, setMenus] = useState([]);
  useEffect(() => {
    console.log("menus actualizado: ", menus);
  }, [menus]);
  const days = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];
  const meals = ["Desayuno", "Media mañana", "Comida", "Media tarde", "Cena"];
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [role, setRole] = useState('regular'); // Estado para el rol del usuario

  /****** Gestión Fechas *************/
  const hoy = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      0,
      0,
      0
    )
  );
  const [fechaInicioSemana, setFechaInicioSemana] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );

  let fechaInicioSemanaMostrada = fechaInicioSemana;

  // Obtener el rol del usuario
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/role`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Error fetching user role');
        }

        const roleData = await response.json();
        setRole(roleData.role);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (authToken) {
      fetchUserRole();
    }
  }, [authToken]);

  //Menú para la semana activa
  useEffect(() => {
    const fetchMenuData = async () => {
      let fechaFinSemanaMostrada = new Date(fechaInicioSemanaMostrada);
      fechaFinSemanaMostrada.setDate(fechaInicioSemanaMostrada.getDate() + 6);
      let fechaInicio = `${fechaInicioSemanaMostrada.getFullYear()}-${fechaInicioSemanaMostrada.getMonth() + 1}-${fechaInicioSemanaMostrada.getDate()}`;
      let fechaFin = `${fechaFinSemanaMostrada.getFullYear()}-${fechaFinSemanaMostrada.getMonth() + 1}-${fechaFinSemanaMostrada.getDate()}`;
      try {
        const url = `${process.env.REACT_APP_API_URL}/menuschema?start=${fechaInicio}&end=${fechaFin}`;
        // console.log("MenuDataURL: ", url);
        const menuResponse = await fetch(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!menuResponse.ok) {
          throw new Error('Server response was not ok');
        }

        if (!menuResponse.headers.get('content-type').includes('application/json')) {
          throw new Error('Received content is not valid JSON');
        }

        const menuData = await menuResponse.json();
        setMenus(menuData);
        // console.log("MenuData Obtenido: ", menuData)
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    if (authToken) {
      fetchMenuData();
    }
  }, [authToken, fechaInicioSemanaMostrada]);

  /******************************** FIN GESTIÓN SEMANA ************/


  /*******************************  RESPONSIVE */
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [circleSize, setCircleSize] = useState(50);
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    if (windowWidth < 500) {
      setCircleSize(60);
      setFontSize('13px');
    } else {
      setCircleSize(50);
      setFontSize('11px');
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const fontSizeH2 = isSmallScreen ? '40px' : '30px';
  const fontSizeH3 = isSmallScreen ? '30px' : '25px';
  const fontSizeMealTitle = isSmallScreen ? '25px' : '18px';


  /******************************************** FIN RESPONSIVE *************************/

  const [userId, setUserId] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Error fetching user');
        }

        const userData = await response.json();
        setUserId(userData.id);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (authToken) {
      fetchUser();
    }
  }, [authToken]);

  const [userInfoSemana, setUserInfoSemana] = useState(null);

  useEffect(() => {
    if (userInfo) {
      let userInfoSemanaTemp = JSON.parse(JSON.stringify(userInfo)); // Crear una copia profunda del objeto userInfo

      // Multiplicar los valores de los macronutrientes por 7
      userInfoSemanaTemp.macros.kilocalories *= 7;
      userInfoSemanaTemp.macros.carbs *= 7;
      userInfoSemanaTemp.macros.protein *= 7;
      userInfoSemanaTemp.macros.fat *= 7;

      setUserInfoSemana(userInfoSemanaTemp);
    }
  }, [userInfo]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/userinfo/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Error fetching user info');
        }

        const userInfoData = await response.json();
        setUserInfo(userInfoData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (userId) {
      fetchUserInfo();
    }
  }, [userId, authToken]);


  const [diaSeleccionado, setDiaSeleccionado] = useState(new Date());
  //console.log("Diaseleccionado es: ", diaSeleccionado);
  const [isPopupVisible, setPopupVisible] = useState(false);

  const irAlDiaAnterior = () => {
    setDiaSeleccionado(diaActual => new Date(diaActual.getFullYear(), diaActual.getMonth(), diaActual.getDate() - 1));
  };

  // Función para cambiar al día siguiente
  const irAlDiaSiguiente = () => {
    setDiaSeleccionado(diaActual => new Date(diaActual.getFullYear(), diaActual.getMonth(), diaActual.getDate() + 1));
  };


  const handleButtonClick = (setPopupVisible) => {
    return () => {
      setPopupVisible(true);
    };
  };


  const AddMealPopup = ({ isVisible, onClose, onSelectRecipe, onSelectIngredient }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState('recipes');
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [ingredientQuantity, setIngredientQuantity] = useState('');
    const ITEMS_PER_PAGE = 20;
    const [page, setPage] = useState(1);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [searchMessage, setSearchMessage] = useState('Escribe al menos 3 caracteres para buscar');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const fetchItems = async (searchTerm) => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${activeTab}/search?name=${searchTerm}`);
      const data = await response.json();
      return data;
    };

    const handleSearchTermChange = (term) => {
      setSearchTerm(term);
      setPage(1);
      if (term.length < 3) {
        setSearchMessage('Escribe al menos 3 caracteres para buscar');
        setIsLoadingSearch(false);
      } else {
        setIsLoadingSearch(false);
        setSearchMessage('');
      }
    };

    useEffect(() => {
      let cancel = false;
      if (searchTerm && searchTerm.length >= 3) {
        setIsLoadingSearch(true);
        fetchItems(searchTerm).then(data => {
          if (!cancel) {
            setFilteredItems(data);
            setIsLoadingSearch(false);
          }
        });
      } else {
        setIsLoadingSearch(false);
      }
      return () => {
        cancel = true;
      };
    }, [searchTerm, activeTab]);

    const handleSelectIngredient = (ingredient) => {
      setSelectedIngredient(ingredient);
    };

    const handleQuantityChange = (quantity) => {
      setIngredientQuantity(quantity);
    };

    const handleConfirmQuantity = () => {
      onSelectIngredient({
        ingredient: selectedIngredient._id,
        quantity: ingredientQuantity,
        unit: selectedIngredient.standardUnit,
        name: selectedIngredient.name
      });
      setSelectedIngredient(null);
      setIngredientQuantity('');
    };

    return (
      <Modal open={isVisible} onClose={onClose}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '90%' : '80%',
          maxWidth: '600px',
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">{activeTab === 'recipes' ? 'Añadir Receta' : 'Añadir Ingrediente'}</Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)} sx={{ mb: 2 }}>
            <Tab label="Recetas" value="recipes" />
            <Tab label="Ingredientes" value="ingredients" />
          </Tabs>

          <Box sx={{ display: 'flex', mb: 2 }}>
            <TextField
              fullWidth
              value={searchTerm}
              onChange={(e) => handleSearchTermChange(e.target.value)}
              placeholder={`Buscar ${activeTab === 'recipes' ? 'recetas' : 'ingredientes'}`}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
              }}
            />
          </Box>
          {isLoadingSearch ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : searchTerm.length < 3 ? (
            <Typography variant="body2" sx={{ textAlign: 'center', my: 2 }}>{searchMessage}</Typography>
          ) : (
            <List sx={{ flexGrow: 1, overflow: 'auto' }}>
              {Array.isArray(filteredItems) && filteredItems.length > 0 ? (
                filteredItems.map((item, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" onClick={() => activeTab === 'recipes' ? onSelectRecipe(item) : handleSelectIngredient(item)}>
                        <AddIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={item.name}
                      secondary={activeTab === 'recipes' ? item.description : null}
                    />
                  </ListItem>
                ))
              ) : (
                <Typography variant="body2" sx={{ textAlign: 'center', my: 2 }}>
                  No se han encontrado {activeTab === 'recipes' ? 'recetas' : 'ingredientes'}
                </Typography>
              )}
            </List>
          )}

          {selectedIngredient && (
            <Modal open={!!selectedIngredient} onClose={() => setSelectedIngredient(null)}>
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? '90%' : '400px',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
              }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Añadir {selectedIngredient.name}</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  ¿Cuántos {selectedIngredient.standardUnit} quieres añadir?
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>
                  El peso medio por unidad es de {selectedIngredient.gramsPerUnit}
                </Typography>
                <TextField
                  fullWidth
                  value={ingredientQuantity.replace('.', ',')}
                  onChange={(e) => {
                    const value = e.target.value.replace(',', '.');
                    handleQuantityChange(value);
                  }}
                  placeholder="Cantidad"
                  type="number"
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                  <Button onClick={() => setSelectedIngredient(null)} variant="outlined">
                    Cancelar
                  </Button>
                  <Button onClick={handleConfirmQuantity} variant="contained" color="primary">
                    Confirmar
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}
        </Box>
      </Modal>
    );
  };

  const MealComponent = ({ mealName, mealData, diaFormato, authToken }) => {
    console.log("MealComponentLog menus", mealName, mealData, diaFormato);
    const [ingredientNames, setIngredientNames] = useState([]);
    const [recipesNames, setRecipesNames] = useState([]);
    const [localMealData, setLocalMealData] = useState(mealData);
    const [expanded, setExpanded] = useState(false);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const isFirstRender = useRef(true);
    const { updateTotalMacronutrientsForDay } = useContext(MacrosDailyContext);

    const handleChange = () => {
      setExpanded(!expanded);
    };

    useEffect(() => {
      setLocalMealData(mealData);
    }, [mealData]);

    useEffect(() => {
      console.log("Updated Estado localMealData: ", localMealData, "en base al mealData: ", mealData);
    }, [localMealData]);

    useEffect(() => {
      if (isFirstRender.current) {
        const mealDataToUse = mealData || {
          macronutrients: {
            kilocalories: 0,
            carbs: 0,
            protein: 0,
            fat: 0
          },
          _id: "",
          user: "",
          date: new Date(),
          meal: mealName,
          recipes: [],
          ingredients: [],
          __v: 0
        };

        updateTotalMacronutrientsForDay(diaFormato, mealDataToUse.meal, mealDataToUse.macronutrients);
        isFirstRender.current = false;
      }
    }, [updateTotalMacronutrientsForDay, mealData, diaFormato, mealName]);

    useEffect(() => {
      const fetchIngredientNames = async () => {
        if (mealData && mealData.ingredients) {
          const ingredientIds = mealData.ingredients.map(ingredient => ingredient.ingredient).join(',');
          if (ingredientIds.length > 0) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/ingredients/by-ids?ids=${ingredientIds}`);
            const data = await response.json();
            const names = data.map(ingredientData => ({
              _id: ingredientData._id,
              name: ingredientData.name,
              standardUnit: ingredientData.standardUnit,
              gramsPerUnit: ingredientData.gramsPerUnit,
              quantity: mealData.ingredients.find(ingredient => ingredient.ingredient === ingredientData._id).quantity,
              kilocalories: ingredientData.kilocalories,
              carbs: ingredientData.carbs,
              protein: ingredientData.protein,
              fat: ingredientData.fat
            }));
            setIngredientNames(names);
          }
        }
      };

      const fetchRecipes = async () => {
        if (mealData && mealData.recipes) {
          if (mealData.recipes.length > 0) {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/recipes/by-ids?ids=${mealData.recipes.join(',')}`
            );
            const data = await response.json();
            if (Array.isArray(data)) {
              setRecipesNames(data);
            }
          }
        }
      };

      if (mealData) {
        fetchIngredientNames();
        fetchRecipes();
      }
    }, [mealData]);

    const handleRemoveRecipe = useCallback(async (recipeId) => {
      console.log("Removing recipe with ID: ", recipeId);
      console.log("Removing LocalMealData: ", localMealData);
      if (!localMealData || !localMealData._id || !recipeId) {
        console.error("Cannot remove recipe, localMealData or _id or recipeId is undefined");
        return;
      }
      console.log("Removing recipe with ID: ", recipeId);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/menuschema/${localMealData._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ meal: localMealData.name, removeRecipe: recipeId })
      });
      const data = await response.json();
      console.log("Response from server: ", data);
      setRecipesNames(prevRecipes => {
        const newRecipes = prevRecipes.filter(recipe => recipe._id !== recipeId);
        console.log("New recipes: ", newRecipes);
        return newRecipes;
      });
      setLocalMealData(prevMealData => {
        console.log("Previous meal data: ", prevMealData);
        console.log("New meal data: ", data);
        console.log("updateTotalMacronutrientsForDayLog Llamando :" + diaFormato + mealName, " y macros: ", data.macronutrients);
        updateTotalMacronutrientsForDay(diaFormato, mealName, data.macronutrients);
        return data;
      });
    }, [localMealData, authToken]);

    const handleRemoveIngredient = useCallback(async (ingredientId) => {
      console.log("handleRemoveIngredientLog Removing ingredient with ID: ", ingredientId);
      console.log("handleRemoveIngredientLog Meal data: ", localMealData);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/menuschema/${localMealData._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ meal: localMealData.name, removeIngredient: ingredientId })
      });
      const data = await response.json();
      console.log("handleRemoveIngredientLog Response from server: ", data);
      setIngredientNames(prevIngredients => {
        const newIngredients = prevIngredients.filter(ingredient => ingredient._id !== ingredientId);
        console.log("handleRemoveIngredientLog New ingredients: ", newIngredients);
        return newIngredients;
      });
      setLocalMealData(prevMealData => {
        console.log("Previous meal data: ", prevMealData);
        console.log("New meal data: ", data);
        console.log("Llamando a updateMacrosForDay con Meal name: ", mealName, " y macros: ", data.macronutrients);
        updateTotalMacronutrientsForDay(diaFormato, mealName, data.macronutrients);
        return data;
      });
    }, [localMealData, authToken]);

    const handleAddRecipe = useCallback(async (mealData, mealName, recipeId, authToken, date, setMenuForMeal) => {
      console.log("onSelectRecipe", mealData, mealName, recipeId, authToken, date, setMenuForMeal)
      console.log("Actualizando " + localMealData)
      let body;
      let method;
      let url;

      // Check if mealData is empty
      if (!localMealData) {
        console.log("mealData is empty, creating a new menu");
        method = 'POST';
        url = `${process.env.REACT_APP_API_URL}/menuschema`;
        // Convertir la fecha a UTC
        let formattedDate = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
        body = JSON.stringify({
          date: formattedDate,
          meal: mealName,
          recipes: [recipeId],
          ingredients: []
        });
      } else {
        console.log("mealData is not empty, updating existing menu");
        method = 'PUT';
        url = `${process.env.REACT_APP_API_URL}/menuschema/${localMealData._id}`;
        body = JSON.stringify({ meal: mealName, addRecipe: recipeId });
      }

      console.log("Request method: ", method);
      console.log("Request URL: ", url);
      console.log("Request body: ", body);

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: body
      });
      if (response.ok) {
        console.log("Request was successful");
      } else {
        console.log("Request failed with status: ", response.status);
      }
      const data = await response.json();
      console.log("Response data: ", data);
      setLocalMealData(prevMealData => {
        console.log("Previous meal data: ", prevMealData);
        console.log("New meal data: ", data);
        updateTotalMacronutrientsForDay(diaFormato, mealName, data.macronutrients);
        return data;
      });
      if (data.recipes && Array.isArray(data.recipes)) {
        const foundRecipeId = data.recipes.find(id => id === recipeId);
        console.log("Added recipe ID: ", foundRecipeId);

        if (foundRecipeId) {
          // Fetch recipe details
          const recipeResponse = await fetch(`${process.env.REACT_APP_API_URL}/recipes/${foundRecipeId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json'
            }
          });

          const recipeData = await recipeResponse.json();
          console.log("Recipe data: ", recipeData);
          console.log("Recipe name: ", recipeData.name);
        }
      }
    }, [mealData, authToken, localMealData]);

    const handleAddIngredient = useCallback(async (mealData, mealName, ingredient, authToken, date, setMenuForMeal) => {
      let body;
      let method;
      let url;

      // Check if mealData is empty
      if (!localMealData) {
        //Crear Menú con ese ingrediente
        console.log("mealData is empty, creating a new menu");
        method = 'POST';
        url = `${process.env.REACT_APP_API_URL}/menuschema`;
        let formattedDate = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');

        body = JSON.stringify({
          date: formattedDate,
          meal: mealName,
          ingredients: [ingredient],
          recipes: []
        });
      } else {
        //Actualizar Menú con ese ingrediente
        console.log("mealData is not empty, updating existing menu");
        method = 'PUT';
        url = `${process.env.REACT_APP_API_URL}/menuschema/${localMealData._id}`;
        body = JSON.stringify({ meal: mealName, addIngredient: ingredient });
      }

      console.log("Request body: ", body, url);

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: body
      });
      const data = await response.json();
      console.log("Ingredient added: ", data);
      // Update localMealData
      setLocalMealData(prevMealData => {
        console.log("Previous meal data: ", prevMealData);
        console.log("New meal data: ", data);
        updateTotalMacronutrientsForDay(diaFormato, mealName, data.macronutrients);
        return data;
      });
      // Update localNutrients
      const addedIngredient = data.ingredients.find(ing => ing._id === ingredient._id);
      const ingredientResponse = await fetch(`${process.env.REACT_APP_API_URL}/ingredients/${ingredient._id}`, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      });
      const ingredientData = await ingredientResponse.json();
    }, [mealData, authToken, localMealData]);

    const handleSelectRecipe = useCallback(async (recipe) => {
      console.log("onSelectRecipe", recipe, localMealData, authToken);
      await handleAddRecipe(localMealData, mealName, recipe._id, authToken, diaFormato, setLocalMealData);
      setRecipesNames(prevRecipes => {
        console.log("Previous recipes: ", prevRecipes);
        const newRecipes = prevRecipes ? [...prevRecipes, recipe] : [recipe];
        console.log("New recipes: ", newRecipes);
        return newRecipes;
      });
      setPopupVisible(false);
    }, [localMealData, authToken, diaFormato]);

    const handleSelectIngredient = useCallback(async (ingredient) => {
      console.log("onSelectIngredient", ingredient, localMealData, authToken);
      await handleAddIngredient(localMealData, mealName, ingredient, authToken, diaFormato, setLocalMealData);

      // Create a new object with the "ingredient" key changed to "_id"
      const newIngredient = { ...ingredient, _id: ingredient.ingredient };
      delete newIngredient.ingredient;

      setIngredientNames(prevIngredients => {
        console.log("Previous ingredients: ", prevIngredients);
        const newIngredients = prevIngredients ? [...prevIngredients, newIngredient] : [newIngredient];
        console.log("New ingredients: ", newIngredients);
        return newIngredients;
      });
      setPopupVisible(false);
    }, [localMealData, authToken, diaFormato]);

    const onButtonClick = () => {
      console.log("onClickMealComponent meal", mealName);
      console.log("onClickMealComponent día", diaFormato);
      setPopupVisible(true);
    };

    const totalItems = recipesNames.length + ingredientNames.length;

    return (
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ fontWeight: 'bold', mb: 1, textAlign: 'left' }}>
              {mealName}
              {!expanded && (
                <Box
                  component="span"
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: totalItems === 0 ? '#ffcccc' : 'grey',
                    color: 'white',
                    borderRadius: '50%',
                    width: 24,
                    height: 24,
                    ml: 1,
                    fontSize: '0.8rem'
                  }}
                >
                  {totalItems}
                </Box>
              )}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '40%' }}>
              <Typography sx={{ whiteSpace: 'nowrap', fontSize: '0.8rem' }}>
                {Math.round(localMealData?.macronutrients?.kilocalories) || 0} Kcal
              </Typography>
              <Typography sx={{ mx: 0.5 }}>|</Typography>
              <Typography sx={{ whiteSpace: 'nowrap', fontSize: '0.8rem' }}>
                {Math.round(localMealData?.macronutrients?.carbs) || 0} C
              </Typography>
              <Typography sx={{ mx: 0.5 }}>|</Typography>
              <Typography sx={{ whiteSpace: 'nowrap', fontSize: '0.8rem' }}>
                {Math.round(localMealData?.macronutrients?.protein) || 0} P
              </Typography>
              <Typography sx={{ mx: 0.5 }}>|</Typography>
              <Typography sx={{ whiteSpace: 'nowrap', fontSize: '0.8rem' }}>
                {Math.round(localMealData?.macronutrients?.fat) || 0} G
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <List style={{ padding: 0, width: '100%' }}>
            {recipesNames.map((recipe, index) => {
              const labelId = `checkbox-list-label-${recipe._id}-${index}`;
              return (
                <ListItem style={{ padding: 10, width: '100%' }} key={labelId} divider>
                  <ListItemText primary={recipe.name} />
                  <IconButton edge="end" onClick={() => handleRemoveRecipe(recipe._id)}>
                    <RemoveCircleOutlineIcon color="action" />
                  </IconButton>
                </ListItem>
              );
            })}
            {ingredientNames.map((ingredient, index) => {
              const labelId = `checkbox-list-label-${ingredient._id}-${index}`;
              const formattedQuantity = Number(ingredient.quantity).toLocaleString('es', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).replace('.', ',');
              return (
                <ListItem key={labelId} divider>
                  <ListItemText primary={`${ingredient.name}: ${formattedQuantity} ${ingredient.standardUnit || ingredient.unit}`} />
                  <IconButton edge="end" onClick={() => handleRemoveIngredient(ingredient._id)}>
                    <RemoveCircleOutlineIcon color="action" />
                  </IconButton>
                </ListItem>
              );
            })}
            <ListItem style={{ padding: 0 }}>
              <ListItemButton dense style={{ justifyContent: 'center' }}>
                <IconButton edge="end" aria-label="add" onClick={onButtonClick}>
                  <AddCircleOutlineIcon sx={{ bgcolor: 'primary.main', color: '#fff' }} />
                </IconButton>
              </ListItemButton>
            </ListItem>
          </List>
        </AccordionDetails>
        {isPopupVisible && (
          <AddMealPopup
            isVisible={isPopupVisible}
            onClose={() => setPopupVisible(false)}
            onSelectRecipe={handleSelectRecipe}
            onSelectIngredient={handleSelectIngredient}
          />
        )}
      </Accordion>
    );
  };

  const NutrientesBarChart = ({ nutrientes, nutrientesNombres, circleSize, fontSize, userInfo }) => {
    if (!userInfo || !userInfo.macros || !nutrientes) {
      return null; // o puedes renderizar un componente de carga o algún otro fallback
    }

    return (
      <Grid container spacing={0}>
        {Object.entries(nutrientes).map(([nutrienteKey, value]) => {
          const valueAsNumber = parseFloat(value.toString().replace(',', '.'));
          const macroValue = userInfo.macros[nutrienteKey];
          const percentage = (valueAsNumber / macroValue) * 100;
          const data = [{ name: nutrienteKey, value: percentage }];

          let fillColor;
          if (percentage < 65 || percentage > 140) {
            fillColor = '#f04646';
          } else if ((percentage >= 65 && percentage < 85) || (percentage > 115 && percentage <= 140)) {
            fillColor = '#f5a638';
          } else if (percentage >= 85 && percentage <= 115) {
            fillColor = '#89db6e';
          }

          return (
            <Grid item xs={3} sm={3} key={nutrienteKey} style={{ padding: 0 }}>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" maxWidth="100%">
                <RadialBarChart
                  width={circleSize}
                  height={circleSize / 2}
                  cx={circleSize / 2}
                  cy={circleSize / 2}
                  innerRadius={20}
                  outerRadius={20}
                  barSize={6}
                  data={data}
                  startAngle={180}
                  endAngle={0}
                >
                  <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
                  <RadialBar background clockWise dataKey="value" cornerRadius={circleSize / 2} fill={fillColor} />
                </RadialBarChart>
                <Typography variant="body1" style={{ fontSize }}>{value}</Typography>
                <Typography variant="body1" style={{ fontSize }}>{nutrientesNombres[nutrienteKey]}</Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  // Define los estilos personalizados para LinearProgress
  const ProgressBar = styled(LinearProgress)(({ theme, color }) => ({
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette[color].light,
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette[color].main,
    },
  }));

  const MacronutrientProgress = ({ label, value, max, color }) => {
    const percentage = Math.min(Math.round((value / max) * 100), 100);
    const exceedsLimit = (value / max) > 1.1;

    return (
      <Box sx={{ width: '100%', mb: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 0.5 }}>
          <Typography sx={{ fontSize: '0.75rem', textAlign: 'center' }}>
            {label}: {Math.round(value)} / {Math.round(max)}
          </Typography>
          {exceedsLimit && (
            <Tooltip title={`La ingesta de ${label} supera en un 10% al objetivo`}>
              <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                <InfoIcon fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <ProgressBar
          variant="determinate"
          value={percentage}
          color={color}
          sx={{
            '& .MuiLinearProgress-bar': {
              backgroundColor: exceedsLimit ? '#E57373' : theme => theme.palette[color].main,
            },
          }}
        />
      </Box>
    );
  };

  const RenderDia = ({ dia, menus }) => {
    const { totalMacronutrientsForDay } = useContext(MacrosDailyContext);
    const [nutrientes, setNutrientes] = useState({
      kilocalories: 0,
      carbs: 0,
      protein: 0,
      fat: 0,
    });

    useEffect(() => {
      let newNutrientes = {
        kilocalories: 0,
        carbs: 0,
        protein: 0,
        fat: 0,
      };

      const dayName = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'][dia.getDay()];
      const contextNutrientes = totalMacronutrientsForDay[dayName]?.meals || {};

      for (let meal in contextNutrientes) {
        if (contextNutrientes[meal]) {
          newNutrientes.kilocalories += contextNutrientes[meal].kilocalories || 0;
          newNutrientes.carbs += contextNutrientes[meal].carbs || 0;
          newNutrientes.protein += contextNutrientes[meal].protein || 0;
          newNutrientes.fat += contextNutrientes[meal].fat || 0;
        }
      }

      setNutrientes(newNutrientes);
    }, [menus, totalMacronutrientsForDay, dia]);

    const hoy = new Date();
    const mañana = addDays(hoy, 1);

    // if (role === 'regular' && dia > mañana) {
    //   return (
    //     <Box
    //       display="flex"
    //       justifyContent="center"
    //       alignItems="center"
    //       height="800px"
    //       style={{
    //         backgroundImage: 'url(https://nutrizia.app/uploads/premium-day.png)',
    //         backgroundSize: 'cover',
    //         backgroundPosition: 'center',
    //         backgroundColor: 'black'  // Añadir un color de fondo para probar
    //       }}
    //     >
    //       <Typography variant="h6" style={{ color: '#fff' }}>Esta es una función premium de culo</Typography>
    //     </Box>
    //   );
    // }

    return (
      <Grid container justify="center">
        <Grid item xs={12} style={{ display: 'flex', height: '180px', width: '100%' }}>
          <Grid container direction="column" alignItems="center">
            {userInfo && (
              <>
                <MacronutrientProgress
                  label="Kcal"
                  value={nutrientes.kilocalories}
                  max={userInfo.macros?.kilocalories || 0}
                  color="kilocalories"
                />
                <MacronutrientProgress
                  label="Carbs"
                  value={nutrientes.carbs}
                  max={userInfo.macros?.carbs || 0}
                  color="carbs"
                />
                <MacronutrientProgress
                  label="Proteina"
                  value={nutrientes.protein}
                  max={userInfo.macros?.protein || 0}
                  color="protein"
                />
                <MacronutrientProgress
                  label="Grasa"
                  value={nutrientes.fat}
                  max={userInfo.macros?.fat || 0}
                  color="fat"
                />
              </>
            )}
          </Grid>
        </Grid>
        {meals.map((meal, index) => {
          const menuParaEstaComida = menus.filter(menu => menu.meal === meal);
          return (
            <Grid item xs={12} key={index} style={{ width: '100%' }}>
              <MealComponent
                mealName={meal}
                mealData={menuParaEstaComida[0]}
                diaFormato={dia}
                authToken={authToken}
              />
              <Divider variant="middle" style={{ marginTop: '20px' }} />
            </Grid>
          );
        })}
      </Grid>
    );
  };





  const RenderDiaSingle = ({ dia, menus }) => {
    const { updateTotalMacronutrientsForDay } = useContext(MacrosDailyContext);
    const [menusDelDia, setMenusDelDia] = useState([]);

    const diaFormato = useMemo(() => {
      const diaDate = new Date(dia);
      return new Date(diaDate.getFullYear(), diaDate.getMonth(), diaDate.getDate());
    }, [dia]);

    const fetchMenuData = async () => {
      let fecha = `${diaFormato.getFullYear()}-${diaFormato.getMonth() + 1}-${diaFormato.getDate()}`;
      try {
        const url = `${process.env.REACT_APP_API_URL}/menuschema?start=${fecha}&end=${fecha}`;
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const menuResponse = await fetch(url, { headers });

        if (!menuResponse.ok) {
          throw new Error('Server response was not ok');
        }

        if (!menuResponse.headers.get('content-type').includes('application/json')) {
          throw new Error('Received content is not valid JSON');
        }

        const menuData = await menuResponse.json();
        setMenusDelDia(menuData);

        // Actualizar el contexto de macronutrientes
        menuData.forEach(menu => {
          updateTotalMacronutrientsForDay(new Date(menu.date), menu.meal, menu.macronutrients);
        });

      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    useEffect(() => {
      if (authToken) {
        fetchMenuData();
      }
    }, [authToken, diaFormato]);

    return (
      <Box sx={{ mb: 3 }} width="100%">
        <Divider variant="middle" />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <ArrowCircleLeftRoundedIcon onClick={irAlDiaAnterior} fontSize="large" />
          <Box textAlign="center" sx={{ mt: 2 }}>
            <Typography variant="h3" style={{ textTransform: 'capitalize', fontFamily: "'Caveat', cursive", fontWeight: 600, textDecoration: 'underline' }}>
              {dia.toLocaleDateString('es-ES', { weekday: 'long' })}
            </Typography>
            <Typography variant="h2" style={{ textTransform: 'capitalize', fontFamily: "'Caveat', cursive", fontWeight: 600, textDecoration: 'underline', fontSize: fontSizeH2 }}>
              {dia.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}
            </Typography>
          </Box>
          <ArrowCircleRightRoundedIcon onClick={irAlDiaSiguiente} fontSize="large" />
        </Box>
        <RenderDia dia={dia} menus={menusDelDia} />
      </Box>
    );
  };


  const RenderSemana = ({ handleButtonClick }) => {
    const { updateTotalMacronutrientsForDay } = useContext(MacrosDailyContext);
    const fechaInicioSemanaAjustada = useMemo(() => {
      const fecha = new Date(fechaInicioSemanaMostrada);
      fecha.setHours(0, 0, 0, 0);
      return fecha;
    }, [fechaInicioSemanaMostrada]);

    const irASemanaAnterior = () => {
      const nuevaFechaInicio = subWeeks(fechaInicioSemana, 1);
      setFechaInicioSemana(nuevaFechaInicio);
    };

    const irASemanaSiguiente = () => {
      fechaInicioSemanaMostrada = addWeeks(fechaInicioSemana, 1);
      setFechaInicioSemana(fechaInicioSemanaMostrada);
    };

    const fechaInicioSemanaUTC = useMemo(() => {
      return new Date(Date.UTC(fechaInicioSemanaAjustada.getFullYear(), fechaInicioSemanaAjustada.getMonth(), fechaInicioSemanaAjustada.getDate()));
    }, [fechaInicioSemanaAjustada]);

    const fechaInicioSemanaLocal = useMemo(() => new Date(fechaInicioSemanaUTC), [fechaInicioSemanaUTC]);
    const fechaFinSemanaLocal = useMemo(() => new Date(addDays(fechaInicioSemanaAjustada, 6)), [fechaInicioSemanaAjustada]);

    const onAddClick = (dia, meal) => {
      handleButtonClick(dia, meal);
      setPopupVisible(true);
      console.log("onAddClick", dia, meal);
    };

    const dias = useMemo(() => {
      const todosLosDias = eachDayOfInterval({ start: fechaInicioSemanaLocal, end: fechaFinSemanaLocal });

      if (role === 'regular') {
        const hoy = new Date();
        const filteredDias = todosLosDias.map(dia => ({
          date: dia,
          isPremium: dia > addDays(hoy, 1)
        }));
        return filteredDias;
      }

      return todosLosDias.map(dia => ({
        date: dia,
        isPremium: false
      }));
    }, [fechaInicioSemanaLocal, fechaFinSemanaLocal, role]);

    const esDiaActual = (dia) => {
      const hoy = new Date();
      hoy.setHours(0, 0, 0, 0);
      return dia.getTime() === hoy.getTime();
    };

    return (
      <Box>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography variant="p">Semana anterior</Typography>
              <Button variant="contained" sx={{ bgcolor: 'primary.main', color: '#fff' }} onClick={irASemanaAnterior}>←</Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            {/* <ResumenSemana diasSemana={dias} menus={menus} /> */}
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography variant="p">Semana siguiente</Typography>
              <Button variant="contained" sx={{ bgcolor: 'primary.main', color: '#fff' }} onClick={irASemanaSiguiente}>→</Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Grid container spacing={0}>
              {dias.map((diaObj, index) => {
                const dia = diaObj.date;
                const isPremium = diaObj.isPremium;
                const diaFormato = dia.toLocaleDateString('es-ES');
                const menusDelDia = menus.filter(menu => {
                  const menuDateStr = new Date(menu.date).toLocaleDateString('es-ES');
                  return menuDateStr === diaFormato;
                });

                const estilosDia = esDiaActual(dia) ? { backgroundColor: '#9fe1eb' } : { backgroundColor: 'transparent' };

                return (
                  <Grid item xs={12} sm={1.714} key={index}>
                    <Box minHeight="100px" border={1} borderColor="#e9e8e8" style={estilosDia}>
                      <Box>
                        <Typography variant="p" style={{ textTransform: 'capitalize', fontFamily: "'Caveat', cursive", fontSize: '26px' }}>
                          {dia.toLocaleDateString('es-ES', { weekday: 'long' })}
                        </Typography>
                        <Typography variant="h5" style={{ fontFamily: "'Caveat', cursive", fontSize: '28px', fontWeight: 600, textDecoration: 'underline' }}>
                          {dia.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                        </Typography>
                      </Box>
                      {isPremium ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="800px"
                          style={{
                            backgroundImage: 'url(https://nutrizia.app/uploads/premium-day.png)',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundColor: '#000'  // Añadir un color de fondo para probar
                          }}
                          sx={{
                            backgroundImage: 'url(https://nutrizia.app/uploads/premium-day.png)',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                          }}
                        >
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: theme.palette.primary.main, p: 4 }}>
                            <LockIcon sx={{ color: '#fff', mr: 2 }} />
                            <Typography variant="h6" sx={{ color: '#fff' }}>Esta es una función premium</Typography>
                          </Box>
                        </Box>
                      ) : (
                        <RenderDia
                          dia={dia}
                          menus={menusDelDia}
                          handleButtonClick={(meal) => onAddClick(dia, meal)}
                        />
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const esDispositivoMovil = () => {
    return window.innerWidth <= 768; // Ajusta este valor según tus necesidades
  };

  const renderContenido = () => {
    if (esDispositivoMovil()) {
      console.log("Visualización Móvil");
      return <RenderDiaSingle dia={diaSeleccionado} menus={menus} />; // Mostrar la vista de día en móviles
    } else {
      console.log("Visualización Escritorio");
      return <RenderSemana handleButtonClick={handleButtonClick} />
    }
  }

  return (
    <MacrosDailyProvider>
      <Grid container>
        {/* <Grid item xs={12}>
        <ResumenSemana dia={diaSeleccionado} menus={menus} />
      </Grid> */}
        <Grid item xs={12}>
          {renderContenido()}
        </Grid>
      </Grid>
    </MacrosDailyProvider>
  );
}

export default MenuSemanal;
