import React, { useState, useEffect } from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    Container,
    Box,
    Snackbar,
    Alert,
    useMediaQuery,
    useTheme,
    Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import BasicInfo from './steps/BasicInfo';
import GoalsPreferences from './steps/GoalsPreferences';
import DietaryHabits from './steps/DietaryHabits';
import CulinaryExperience from './steps/CulinaryExperience';
import TrackingGoals from './steps/TrackingGoals';
import Summary from './steps/Summary';

const steps = ['Información Básica', 'Objetivos y Preferencias', 'Hábitos Alimenticios', 'Seguimiento y Objetivos', 'Resumen'];

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(4),
    },
}));

const Onboarding = () => {
    const { userRole, authToken, refreshToken } = useAuth();
    const [activeStep, setActiveStep] = useState(0);
    const [userData, setUserData] = useState({
        name: '',
        birthDate: '',
        age: '',
        sex: '',
        heightCM: '',
        weight: '',
        goal: '',
        activityLevel: '',
        dietaryPreferences: [],
        mealsPerDay: 3,
        allergies: [],
        dislikedFoods: [],
        cookingSkill: 1,
        cookingTime: '',
        kitchenEquipment: [],
        wantsTracking: false,
        trackedMetrics: [],
        customMetrics: [],
        allMetrics: [],
        authToken: authToken,
        newRecipesInterest: false,
        userId: '',
        macros: {
          kilocalories: 0,
          carbs: 0,
          protein: 0,
          fat: 0
        },
        comensalesCasa: 2,
    });
    const [error, setError] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const loadUserData = async () => {
            try {
                console.log('Iniciando carga de datos del usuario');
                const userId = await getUserId();
                console.log('ID de usuario obtenido:', userId);
                const url = `${process.env.REACT_APP_API_URL}/userinfo/user/${userId}`;
                console.log('URL de la solicitud:', url);
                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    }
                });
                if (!response.ok) {
                    throw new Error(`Error al cargar datos del usuario: ${response.status}`);
                }
                const data = await response.json();
                console.log('Datos del usuario cargados:', data);
                setUserData(prevData => ({
                    ...prevData,
                    ...data,
                    userId,
                }));
                setLoading(false);
            } catch (error) {
                console.error('Error detallado al cargar datos del usuario:', error);
                setError(`Error al cargar datos del usuario: ${error.message}`);
                setLoading(false);
            }
        };
        loadUserData();
    }, [authToken]);

    const handleSubmit = async () => {
        try {
            const userId = userData.userId;
            if (!userId) {
                throw new Error('ID de usuario no disponible');
            }

            // Preparar datos para UserInfo
            const userInfoData = {
                name: userData.name,
                birthDate: new Date(userData.birthDate).toISOString(),
                sex: userData.sex,
                heightCM: userData.heightCM,
                goal: userData.goal,
                activityLevel: userData.activityLevel,
                dietaryPreferences: userData.dietaryPreferences,
                mealsPerDay: userData.mealsPerDay,
                allergies: userData.allergies,
                dislikedFoods: userData.dislikedFoods,
                cookingSkillLevel: userData.cookingSkill,
                cookingTime: userData.cookingTime,
                kitchenEquipment: userData.kitchenEquipment,
                newRecipesInterest: userData.newRecipesInterest,
                wantsTracking: userData.wantsTracking,
                macros: userData.macros,
                comensalesCasa: userData.comensalesCasa,
            };
            console.log('Datos a enviar a la API:', userInfoData); // DEBUGGING
            // Actualizar UserInfo
            await updateUserInfo(userId, userInfoData);

            // Actualizar peso en la ruta de tracking
            if (userData.weight) {
                await updateWeight(userData.weight);
            }

            if (userData.wantsTracking) {
                // Obtener todas las métricas del usuario
                const userMetricsResponse = await fetch(`${process.env.REACT_APP_API_URL}/metrics/user-metrics`, {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
                const currentUserMetrics = await userMetricsResponse.json();

                // Actualizar métricas predeterminadas y personalizadas
                for (const metricId of userData.trackedMetrics) {
                    await updateUserMetric(metricId, true);
                }

                // Crear o actualizar métricas personalizadas
                for (const customMetric of userData.customMetrics) {
                    if (customMetric && customMetric.name && customMetric.unit) {
                        if (customMetric.id.startsWith('temp_')) {
                            // Crear nueva métrica personalizada
                            const createdMetric = await createCustomMetric(customMetric);
                            if (createdMetric && createdMetric._id) {
                                await updateUserMetric(createdMetric._id, true);
                            }
                        } else {
                            // Actualizar métrica personalizada existente
                            await updateUserMetric(customMetric.id, true);
                        }
                    }
                }

                // Eliminar métricas que ya no están en seguimiento
                const metricsToDelete = currentUserMetrics.filter(metric =>
                    !userData.trackedMetrics.includes(metric.metricType._id) &&
                    !userData.customMetrics.some(cm => cm.id === metric.metricType._id)
                );
                for (const metric of metricsToDelete) {
                    await deleteUserMetric(metric.metricType._id);
                }
            } else {
                // Si el usuario no quiere seguimiento, eliminar todas las métricas
                const userMetricsResponse = await fetch(`${process.env.REACT_APP_API_URL}/metrics/user-metrics`, {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
                const currentUserMetrics = await userMetricsResponse.json();
                for (const metric of currentUserMetrics) {
                    await deleteUserMetric(metric.metricType._id);
                }
            }

            // Marcar onboarding como completado
            await completeOnboarding();
            navigate('/dashboard');
        } catch (error) {
            console.error('Error al guardar datos del usuario:', error);
            setError('Error al guardar datos del usuario');
            throw error;
        }
    };

    const deleteUserMetric = async (metricId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/metrics/user-metrics/${metricId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Error al eliminar la métrica del usuario');
            }

            const data = await response.json();
            console.log('Métrica de usuario eliminada:', data);
        } catch (error) {
            console.error('Error al eliminar la métrica del usuario:', error);
            setError('Error al eliminar la métrica del usuario');
        }
    };

    const updateUserInfo = async (userId, data) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/userinfo/user/${userId}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error response:', errorData); // DEBUGGING
            throw new Error('Error al actualizar UserInfo');
        }
        const responseData = await response.json();
        console.log('Respuesta de la API:', responseData); // DEBUGGING
    };

    const updateWeight = async (weight) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/tracking/weight`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ weight: parseFloat(weight) })
        });

        if (!response.ok) {
            throw new Error('Error al actualizar el peso');
        }
    };

    const createCustomMetric = async (customMetric) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/metrics/custom-metric`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: customMetric.name, unit: customMetric.unit })
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Métrica personalizada creada:', data);
                return data;
            } else {
                const errorData = await response.json().catch(() => ({}));
                console.warn('Advertencia al crear métrica personalizada:', errorData);
                if (errorData && errorData._id) {
                    return errorData;
                }
                throw new Error(errorData.message || 'Error al crear métrica personalizada');
            }
        } catch (error) {
            console.error('Error al crear métrica personalizada:', error);
            return null;
        }
    };

    const updateUserMetric = async (metricId, isTracking) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/metrics/user-metrics/${metricId}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ isTracking })
            });

            if (!response.ok) {
                throw new Error('Error al actualizar la métrica del usuario');
            }

            const data = await response.json();
            console.log('Métrica de usuario actualizada:', data);
        } catch (error) {
            console.error('Error al actualizar la métrica del usuario:', error);
            setError('Error al actualizar la métrica del usuario');
        }
    };

    const completeOnboarding = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/onboarding-status`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ hasCompletedOnboarding: true })
        });

        if (!response.ok) {
            throw new Error('Error al completar el onboarding del usuario');
        }
    };

    const getUserId = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    await refreshToken();
                    return getUserId();
                }
                throw new Error('Error al obtener ID del usuario');
            }

            const data = await response.json();
            return data.id;
        } catch (error) {
            console.error('Error al obtener ID del usuario:', error);
            throw error;
        }
    };

    const handleNext = () => {
        if (isStepComplete(activeStep)) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setError('Por favor, complete todos los campos obligatorios antes de continuar.');
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleInputChange = (field, value) => {
        setUserData(prevData => {
            const newData = { ...prevData, [field]: value };
            if (field === 'trackedMetrics' || field === 'customMetrics' || field === 'wantsTracking') {
                // Actualizar allMetrics cuando cambian las métricas trackeadas o personalizadas
                newData.allMetrics = [
                    ...(newData.trackedMetrics || []).map(id => ({ metricType: { _id: id }, isTracking: true })),
                    ...(newData.customMetrics || []).map(metric => ({ ...metric, isTracking: true })),
                    ...(prevData.allMetrics || []).filter(metric =>
                        metric && metric.metricType &&
                        !(newData.trackedMetrics || []).includes(metric.metricType._id) &&
                        !(newData.customMetrics || []).some(cm => cm && cm.id === metric.metricType._id)
                    )
                ];
            }
            console.log('userData actualizado:', newData); // DEBUGGING
            return newData;
        });
    };

    const isStepComplete = (step) => {
        switch (step) {
            case 0:
                return userData.name && userData.birthDate && userData.sex && userData.heightCM && userData.weight;
            case 1:
                return userData.goal && userData.activityLevel;
            default:
                return true;
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <BasicInfo userData={userData} handleInputChange={handleInputChange} />;
            case 1:
                return <GoalsPreferences userData={userData} handleInputChange={handleInputChange} />;
            case 2:
                return <DietaryHabits userData={userData} handleInputChange={handleInputChange} />;
            case 3:
                return <TrackingGoals
                    userData={{ ...userData, wantsTracking: userData.wantsTracking }}
                    handleInputChange={handleInputChange}
                    allMetrics={userData.allMetrics || []}
                />;
            case 4:
                return <Summary userData={userData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Container maxWidth="md">
            <StyledPaper elevation={3}>
                <Typography variant="h4" align="center" gutterBottom>
                    Personaliza tu experiencia
                </Typography>
                <Stepper activeStep={activeStep} alternativeLabel={!isMobile}>
                    {isMobile ? (
                        <Step key={steps[activeStep]}>
                            <StepLabel>{steps[activeStep]}</StepLabel>
                        </Step>
                    ) : (
                        steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))
                    )}
                </Stepper>
                <Box sx={{ mt: 4 }}>
                    {activeStep < steps.length ? (
                        <React.Fragment>
                            <Box sx={{ mt: 2, mb: 4 }}>
                                {getStepContent(activeStep)}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                >
                                    Anterior
                                </Button>
                                {activeStep < steps.length - 1 && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                    >
                                        Siguiente
                                    </Button>
                                )}
                            </Box>
                        </React.Fragment>
                    ) : null}
                </Box>
            </StyledPaper>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Onboarding;
