import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TablePagination, Box, Button, Autocomplete, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Grid, TextField, Accordion, AccordionSummary, AccordionDetails, Typography, MenuItem, TableSortLabel } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAuth } from '../context/AuthContext';

function fetchRecipes() {
    return fetch(`${process.env.REACT_APP_API_URL}/recipes`).then(response => response.json());
}

function fetchTypes() {
    return fetch(`${process.env.REACT_APP_API_URL}/tipoDeCocina`).then(response => response.json());
}

function fetchRestrictions() {
    return fetch(`${process.env.REACT_APP_API_URL}/restricciones`).then(response => response.json());
}

function fetchIngredients() {
    return fetch(`${process.env.REACT_APP_API_URL}/ingredients`).then(response => response.json());
}

function fetchTypeById(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/tipoDeCocina/${id}`).then(response => response.json());
}
function fetchRecipeCategories() {
    return fetch(`${process.env.REACT_APP_API_URL}/categoriaRecetas`).then(response => response.json());
}

function GestionRecetas() {
    const { userRole } = useAuth();
    console.log("El rol actual es " + userRole);
    const isAdmin = userRole === 'admin';
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchTerm, setSearchTerm] = useState('');
    const [recipes, setRecipes] = useState([]);
    const [tiposDeCocina, settiposDeCocina] = useState([]);
    const [restrictions, setRestrictions] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [selectedRestriction, setSelectedRestriction] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [selectedRecipeCategory, setSelectedRecipeCategory] = useState('');
    const [recipeCategories, setRecipeCategories] = useState([]);
    const [selectedTipoPlato, setSelectedTipoPlato] = useState('');
    const [selectedReviewed, setSelectedReviewed] = useState(null);
    const [hasIngredients, setHasIngredients] = useState('');
    const [hasSteps, setHasSteps] = useState('');
    const theme = useTheme();
    const [selectedDifficulty, setSelectedDifficulty] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [recipeToDelete, setRecipeToDelete] = useState(null);
    const [orderDirection, setOrderDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    useEffect(() => {
        setPage(0);
    }, [searchTerm, selectedType, selectedRestriction, selectedIngredients, selectedDifficulty, hasIngredients, hasSteps]);

    const ingredientOptions = ingredients.map(ingredient => ({
        value: ingredient._id,
        label: ingredient.name
    }));

    useEffect(() => {
        fetchRecipes().then(data => {
            setRecipes(data);
        });
        fetchTypes().then(data => settiposDeCocina(data));
        fetchRestrictions().then(data => setRestrictions(data));
        fetchIngredients().then(data => setIngredients(data));
        fetchRecipeCategories().then(data => setRecipeCategories(data));
    }, []);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortRecipes = (array) => {
        return array.sort((a, b) => {
            if (orderBy === '') return 0;
            if (a[orderBy] < b[orderBy]) {
                return orderDirection === 'asc' ? -1 : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return orderDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    const filteredRecipes = sortRecipes(recipes.filter(recipe => {
        const matchesType = selectedType === '' || (Array.isArray(recipe.tipoDeCocina) && recipe.tipoDeCocina.some(r => r === selectedType));
        const matchesRestriction = selectedRestriction === '' || (recipe.restricciones && recipe.restricciones.some(r => r === selectedRestriction));
        const matchesDifficulty = selectedDifficulty === '' || recipe.difficulty === selectedDifficulty;
        const matchesSearchTerm = recipe.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesIngredients = selectedIngredients.length === 0 || (recipe.ingredients && selectedIngredients.every(selectedIngredient =>
            recipe.ingredients.some(ingredient => ingredient.ingredientId === selectedIngredient)
        ));
        const matchesRecipeCategory = selectedRecipeCategory === '' || (recipe.categoriaRecetas && recipe.categoriaRecetas.includes(selectedRecipeCategory));
        const matchesTipoPlato = selectedTipoPlato === '' || (recipe.tipoPlato && recipe.tipoPlato.includes(selectedTipoPlato));
        const matchesReviewed = selectedReviewed === null || recipe.revisado === selectedReviewed;
        const matchesHasIngredients = hasIngredients === '' || (hasIngredients === 'true' && recipe.ingredients && recipe.ingredients.length > 0) || (hasIngredients === 'false' && (!recipe.ingredients || recipe.ingredients.length === 0));
        const matchesHasSteps = hasSteps === '' || (hasSteps === 'true' && recipe.steps && recipe.steps.length > 0) || (hasSteps === 'false' && (!recipe.steps || recipe.steps.length === 0));

        return matchesType && matchesRestriction && matchesDifficulty && matchesSearchTerm && matchesIngredients && matchesRecipeCategory && matchesTipoPlato && matchesReviewed && matchesHasIngredients && matchesHasSteps;
    }));

    const handleOpenDialog = (recipe) => {
        setRecipeToDelete(recipe);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const deleteRecipe = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/recipes/${recipeToDelete._id}`, {
            method: 'DELETE',
        });
        setRecipes(recipes.filter(recipe => recipe._id !== recipeToDelete._id));
        handleCloseDialog();
    };

    return (
        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '80%', margin: '0 auto' }}>
            <Box display="flex" justifyContent="center" textAlign="center" width="100%" alignItems="center" height="100px">
                <Button component={Link} to="/recetas/crear" variant="contained" sx={{ bgcolor: 'primary.main', color: '#fff' }} size='large'>
                    Nueva Receta
                </Button>
            </Box>
            <Grid item xs={12}>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Filtrar recetas</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    fullWidth
                                    label="Buscar recetas..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    select
                                    label="Tipo de Cocina"
                                    value={selectedType}
                                    onChange={(e) => setSelectedType(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                                    {tiposDeCocina.map((type) => (
                                        <MenuItem key={type._id} value={type._id}>
                                            {type.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    select
                                    label="Preferencias"
                                    value={selectedRestriction}
                                    onChange={(e) => setSelectedRestriction(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                                    {restrictions.map((restriction) => (
                                        <MenuItem key={restriction._id} value={restriction._id}>
                                            {restriction.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={ingredientOptions}
                                    getOptionLabel={(option) => option.label}
                                    filterSelectedOptions
                                    onChange={(event, newValue) => {
                                        setSelectedIngredients(newValue.map((option) => option.value));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Selecciona ingredientes"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    select
                                    label="Dificultad"
                                    value={selectedDifficulty}
                                    onChange={(e) => setSelectedDifficulty(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                                    <MenuItem value="Baja">Fácil</MenuItem>
                                    <MenuItem value="Media">Media</MenuItem>
                                    <MenuItem value="Alta">Difícil</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    select
                                    label="Categorías de recetas"
                                    value={selectedRecipeCategory}
                                    onChange={(e) => setSelectedRecipeCategory(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                                    {recipeCategories.map((category) => (
                                        <MenuItem key={category._id} value={category._id}>
                                            {category.nombre}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    select
                                    label="Tipo de plato"
                                    value={selectedTipoPlato}
                                    onChange={(e) => setSelectedTipoPlato(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                                    <MenuItem value="Desayuno">Desayuno</MenuItem>
                                    <MenuItem value="Snack">Snack</MenuItem>
                                    <MenuItem value="Entrante">Entrante</MenuItem>
                                    <MenuItem value="Principal">Principal</MenuItem>
                                    <MenuItem value="Postre">Postre</MenuItem>
                                    <MenuItem value="Guarnición">Guarnición</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    select
                                    label="Revisado"
                                    value={selectedReviewed === null ? '' : String(selectedReviewed)}
                                    onChange={(e) => {
                                        setSelectedReviewed(e.target.value === '' ? null : e.target.value === 'true');
                                        console.log("Filtro aplicado: ", e.target.value === '' ? null : e.target.value === 'true');
                                    }}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                                    <MenuItem value="true">Si</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    select
                                    label="Tiene ingredientes"
                                    value={hasIngredients}
                                    onChange={(e) => setHasIngredients(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                                    <MenuItem value="true">Sí</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    select
                                    label="Tiene pasos"
                                    value={hasSteps}
                                    onChange={(e) => setHasSteps(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                                    <MenuItem value="true">Sí</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'tipoDeCocina'}
                                        direction={orderBy === 'tipoDeCocina' ? orderDirection : 'asc'}
                                        onClick={() => handleRequestSort('tipoDeCocina')}
                                    >
                                        Tipo de Cocina
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'restricciones'}
                                        direction={orderBy === 'restricciones' ? orderDirection : 'asc'}
                                        onClick={() => handleRequestSort('restricciones')}
                                    >
                                        Preferencias
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'difficulty'}
                                        direction={orderBy === 'difficulty' ? orderDirection : 'asc'}
                                        onClick={() => handleRequestSort('difficulty')}
                                    >
                                        Dificultad
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Link a la receta</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'ingredients'}
                                        direction={orderBy === 'ingredients' ? orderDirection : 'asc'}
                                        onClick={() => handleRequestSort('ingredients')}
                                    >
                                        Tiene ingredientes
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'steps'}
                                        direction={orderBy === 'steps' ? orderDirection : 'asc'}
                                        onClick={() => handleRequestSort('steps')}
                                    >
                                        Tiene pasos
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'revisado'}
                                        direction={orderBy === 'revisado' ? orderDirection : 'asc'}
                                        onClick={() => handleRequestSort('revisado')}
                                    >
                                        Revisado
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Eliminar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRecipes
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((recipe) => (
                                    <TableRow key={recipe.id}>
                                        <TableCell>{recipe.name}</TableCell>
                                        <TableCell>
                                            {Array.isArray(recipe.tipoDeCocina)
                                                ? recipe.tipoDeCocina
                                                    .map((tipoDeCocinaId) => {
                                                        const tipoDeCocina = tiposDeCocina.find((t) => t._id === tipoDeCocinaId);
                                                        return tipoDeCocina ? tipoDeCocina.name : '';
                                                    })
                                                    .join(', ')
                                                : ''}
                                        </TableCell>
                                        <TableCell>
                                            {recipe.restricciones
                                                ? recipe.restricciones
                                                    .map((restrictionId) => {
                                                        const restriction = restrictions.find((r) => r._id === restrictionId);
                                                        return restriction ? restriction.name : '';
                                                    })
                                                    .join(', ')
                                                : ''}
                                        </TableCell>
                                        <TableCell>{recipe.difficulty}</TableCell>
                                        <TableCell>{recipe._id && <Link to={`/editar-receta/${recipe._id}`}>Editar receta</Link>}</TableCell>
                                        <TableCell>{recipe.ingredients && recipe.ingredients.length > 0 ? 'Si' : 'No'}</TableCell>
                                        <TableCell>{recipe.steps && recipe.steps.length > 0 ? 'Si' : 'No'}</TableCell>
                                        <TableCell>{recipe.revisado ? 'Si' : 'No'}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleOpenDialog(recipe)}>Eliminar</Button>
                                        </TableCell>
                                        <Dialog
                                            open={openDialog}
                                            onClose={handleCloseDialog}
                                        >
                                            <DialogTitle>Eliminar Receta</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    ¿Estás seguro de que quieres eliminar esta receta?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseDialog}>Cancelar</Button>
                                                <Button onClick={deleteRecipe}>Eliminar</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={filteredRecipes.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                    />
                </TableContainer>
            </Grid>
        </Grid>
    );
}

export default GestionRecetas;
