import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function EditarCategoriaIngrediente() {
  const [categoriaIngrediente, setCategoriaIngrediente] = useState({ name: '', description: '', parent: '' });
  const [categorias, setCategorias] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/ingredient-categories/${id}`)
      .then(response => response.json())
      .then(data => setCategoriaIngrediente(data))
      .catch(error => console.error('Error:', error));

    fetch(`${process.env.REACT_APP_API_URL}/ingredient-categories`)
      .then(response => response.json())
      .then(data => setCategorias(data))
      .catch(error => console.error('Error:', error));
  }, [id]);

  const handleChange = (event) => {
    setCategoriaIngrediente({ ...categoriaIngrediente, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/ingredient-categories/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(categoriaIngrediente),
    })
      .then(response => response.json())
      .then(() => {
        navigate('/gestion-taxonomias');
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Editar Categoría de Ingrediente</Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Nombre"
            name="name"
            value={categoriaIngrediente.name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Descripción"
            name="description"
            value={categoriaIngrediente.description}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Categoría Padre</InputLabel>
            <Select
              name="parent"
              value={categoriaIngrediente.parent}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>Ninguna</em>
              </MenuItem>
              {categorias.filter(cat => cat._id !== id).map((categoria) => (
                <MenuItem key={categoria._id} value={categoria._id}>
                  {categoria.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button type="submit" variant="contained" color="primary">
            Guardar Cambios
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}

export default EditarCategoriaIngrediente;