// import React, { useState, useEffect } from 'react';
// import { Box, Typography, Paper, Divider, Grid, Card, CardContent, CircularProgress, List, ListItem, ListItemText, Chip, Link } from '@mui/material';
// import { Restaurant, CalendarToday, Warning } from '@mui/icons-material';

// const WeeklyMenuSummary = ({ menuData = [] }) => {
//   const [recipeNames, setRecipeNames] = useState({});
//   const [ingredientNames, setIngredientNames] = useState({});
//   const [loading, setLoading] = useState(true);

//   const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
//   const meals = ['Desayuno', 'Media mañana', 'Almuerzo', 'Comida', 'Merienda', 'Cena'];

//   const today = new Date();
//   const todayMeals = menuData.filter(item => {
//     const itemDate = new Date(item.date);
//     return itemDate.getDate() === today.getDate() &&
//            itemDate.getMonth() === today.getMonth() &&
//            itemDate.getFullYear() === today.getFullYear();
//   });

//   const incompleteDays = new Set();
//   const completeDays = new Set();

//   daysOfWeek.forEach((day, index) => {
//     const dayMeals = menuData.filter(item => new Date(item.date).getDay() === index);
//     let isComplete = meals.every(meal => dayMeals.some(item => item.meal === meal));

//     if (isComplete) {
//       completeDays.add(day);
//     } else {
//       incompleteDays.add(day);
//     }
//   });

//   useEffect(() => {
//     const fetchNames = async () => {
//       try {
//         const recipeIds = new Set(todayMeals.flatMap(meal => meal.recipes));
//         const ingredientIds = new Set(todayMeals.flatMap(meal => meal.ingredients.map(ing => ing.ingredient)));

//         const fetchRecipeNames = async () => {
//           const namePromises = Array.from(recipeIds).map(async id => {
//             try {
//               const response = await fetch(`${process.env.REACT_APP_API_URL}/recipes/${id}`);
//               if (!response.ok) throw new Error('Network response was not ok');
//               const data = await response.json();
//               return [id, data.name];
//             } catch (error) {
//               console.error('Error fetching recipe:', error);
//               return [id, 'Unknown Recipe'];
//             }
//           });
//           const names = await Promise.all(namePromises);
//           setRecipeNames(prev => ({ ...prev, ...Object.fromEntries(names) }));
//         };

//         const fetchIngredientNames = async () => {
//           const namePromises = Array.from(ingredientIds).map(async id => {
//             try {
//               const response = await fetch(`${process.env.REACT_APP_API_URL}/ingredients/${id}`);
//               if (!response.ok) throw new Error('Network response was not ok');
//               const data = await response.json();
//               return [id, data.name];
//             } catch (error) {
//               console.error('Error fetching ingredient:', error);
//               return [id, 'Unknown Ingredient'];
//             }
//           });
//           const names = await Promise.all(namePromises);
//           setIngredientNames(prev => ({ ...prev, ...Object.fromEntries(names) }));
//         };

//         await Promise.all([fetchRecipeNames(), fetchIngredientNames()]);
//       } catch (error) {
//         console.error('Error fetching names:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (menuData.length > 0) {
//       fetchNames();
//     } else {
//       setLoading(false);
//     }
//   }, [menuData]);

//   const calculateTodayMacros = () => {
//     const totalMacros = {
//       kilocalories: 0,
//       carbs: 0,
//       protein: 0,
//       fat: 0
//     };
//     todayMeals.forEach(meal => {
//       totalMacros.kilocalories += Math.round(meal.macronutrients.kilocalories);
//       totalMacros.carbs += Math.round(meal.macronutrients.carbs);
//       totalMacros.protein += Math.round(meal.macronutrients.protein);
//       totalMacros.fat += Math.round(meal.macronutrients.fat);
//     });
//     return totalMacros;
//   };

//   const todayMacros = calculateTodayMacros();

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="200px">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box component={Paper} p={3} elevation={3}>
//       <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>
//         <Restaurant sx={{ mr: 1, verticalAlign: 'bottom' }} />
//         Resumen del Menú Semanal
//       </Typography>

//       <Box mb={3}>
//         <Typography variant="h6" gutterBottom sx={{ color: '#2196f3' }}>
//           <CalendarToday sx={{ mr: 1, verticalAlign: 'bottom' }} />
//           Comidas planificadas para hoy:
//         </Typography>
//         <Grid container spacing={2}>
//           {todayMeals.length > 0 ? todayMeals.map(meal => (
//             <Grid item xs={12} sm={6} md={4} key={meal._id}>
//               <Card raised>
//                 <CardContent>
//                   <Typography variant="h6" color="primary">{meal.meal}</Typography>
//                   <Typography variant="body2" color="text.secondary">
//                     Macros: Kcal: {Math.round(meal.macronutrients.kilocalories)}, 
//                     Carbs: {Math.round(meal.macronutrients.carbs)}, 
//                     Proteínas: {Math.round(meal.macronutrients.protein)}, 
//                     Grasas: {Math.round(meal.macronutrients.fat)}
//                   </Typography>
//                   {meal.recipes && meal.recipes.length > 0 && (
//                     <Box mt={1}>
//                       <Typography variant="body2" fontWeight="bold">Recetas:</Typography>
//                       <List dense>
//                         {meal.recipes.map(id => (
//                           <ListItem key={id}>
//                             <ListItemText>
//                               <Link href={`/receta/${id}`} underline="hover">
//                                 {recipeNames[id] || 'Cargando...'}
//                               </Link>
//                             </ListItemText>
//                           </ListItem>
//                         ))}
//                       </List>
//                     </Box>
//                   )}
//                   {meal.ingredients && meal.ingredients.length > 0 && (
//                     <Box mt={1}>
//                       <Typography variant="body2" fontWeight="bold">Ingredientes:</Typography>
//                       <List dense>
//                         {meal.ingredients.map(ing => (
//                           <ListItem key={ing.ingredient}>
//                             <ListItemText 
//                               primary={`${ingredientNames[ing.ingredient] || 'Cargando...'}`}
//                               secondary={`${ing.quantity} ${ing.unit}`}
//                             />
//                           </ListItem>
//                         ))}
//                       </List>
//                     </Box>
//                   )}
//                 </CardContent>
//               </Card>
//             </Grid>
//           )) : (
//             <Grid item xs={12}>
//               <Typography variant="body1" color="text.secondary">No hay comidas planificadas para hoy.</Typography>
//             </Grid>
//           )}
//         </Grid>
//       </Box>

//       <Box my={3}>
//         <Typography variant="h6" gutterBottom sx={{ color: '#4caf50' }}>
//           Resumen de macronutrientes del día:
//         </Typography>
//         <Typography variant="body1">
//           Kcal: {todayMacros.kilocalories} | Carbs: {todayMacros.carbs}g | Proteínas: {todayMacros.protein}g | Grasas: {todayMacros.fat}g
//         </Typography>
//       </Box>

//       <Divider sx={{ my: 3 }} />

//       <Box mb={3}>
//         <Typography variant="h6" gutterBottom sx={{ color: '#f44336' }}>
//           <Warning sx={{ mr: 1, verticalAlign: 'bottom' }} />
//           Días incompletos:
//         </Typography>
//         <Grid container spacing={1}>
//           {[...incompleteDays].length > 0 ? [...incompleteDays].map(day => (
//             <Grid item key={day}>
//               <Chip label={day} color="warning" />
//             </Grid>
//           )) : (
//             <Grid item xs={12}>
//               <Typography variant="body1" color="text.secondary">Todos los días están completamente planificados.</Typography>
//             </Grid>
//           )}
//         </Grid>
//       </Box>

//       <Divider sx={{ my: 3 }} />

//       <Box>
//         <Typography variant="h6" gutterBottom sx={{ color: '#4caf50' }}>
//           Resumen semanal:
//         </Typography>
//         <Typography variant="body1">
//           Días completamente planificados: <strong>{completeDays.size}</strong>
//         </Typography>
//         <Typography variant="body1">
//           Días con comidas faltantes: <strong>{incompleteDays.size}</strong>
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

// export default WeeklyMenuSummary;
import React, { useState, useMemo } from 'react';
import {
  Box, Typography, List, ListItem, ListItemAvatar, ListItemText,
  Avatar, Collapse, Grid, Link, LinearProgress, useTheme
} from '@mui/material';
import { Restaurant, CalendarToday, ExpandMore, ExpandLess } from '@mui/icons-material';

const WeeklyMenuSummary = ({ menuData = [], userInfo, recipeDetails, ingredientDetails }) => {
  const [expandedMeal, setExpandedMeal] = useState(null);
  const theme = useTheme();
  const today = new Date();
  const todayMeals = menuData.filter(item => {
    const itemDate = new Date(item.date);
    return itemDate.getDate() === today.getDate() &&
      itemDate.getMonth() === today.getMonth() &&
      itemDate.getFullYear() === today.getFullYear();
  });

  // Función para ordenar las comidas
  const sortMeals = (meals) => {
    const mealOrder = {
      'desayuno': 1,
      'media mañana': 2,
      'comida': 3,
      'media tarde': 4,
      'cena': 5
    };

    return meals.sort((a, b) => {
      const orderA = mealOrder[a.meal.toLowerCase()] || 99;
      const orderB = mealOrder[b.meal.toLowerCase()] || 99;
      return orderA - orderB;
    });
  };

  const sortedTodayMeals = useMemo(() => sortMeals([...todayMeals]), [todayMeals]);

  const handleExpandMeal = (mealId) => {
    setExpandedMeal(expandedMeal === mealId ? null : mealId);
  };

  const calculateTodayMacros = () => {
    return todayMeals.reduce((total, meal) => ({
      kilocalories: total.kilocalories + Math.round(meal.macronutrients.kilocalories),
      carbs: total.carbs + Math.round(meal.macronutrients.carbs),
      protein: total.protein + Math.round(meal.macronutrients.protein),
      fat: total.fat + Math.round(meal.macronutrients.fat)
    }), { kilocalories: 0, carbs: 0, protein: 0, fat: 0 });
  };

  const todayMacros = calculateTodayMacros();

  const MacroProgress = ({ value, goal, label, color }) => (
    <Box mb={1} display="flex" alignItems="center" width="100%">
      <Typography variant="body2" sx={{ width: '25%', marginRight: '8px' }}>
        {`${label}:`}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ width: '25%', marginRight: '8px' }}>
        {`${value}/${goal}`}
      </Typography>
      <Box width="50%" mr={1}>
        <LinearProgress
          variant="determinate"
          value={Math.min((value / goal) * 100, 100)}
          sx={{ height: 10, borderRadius: 5, backgroundColor: color ? `${color}33` : undefined, '& .MuiLinearProgress-bar': { backgroundColor: color || undefined } }}
        />
      </Box>
    </Box>
  );

  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
        <img src="https://nutrizia.app/uploads/icono-calendario-100.png" alt="Calendario" style={{ marginRight: '8px', width: '48px', height: '48px' }} />        Tu resumen de Hoy
      </Typography>
      {userInfo?.macros && (
        <Box mt={2} mb={2}>
          <MacroProgress
            value={todayMacros.kilocalories}
            goal={userInfo?.macros?.kilocalories}
            label="Calorías (kcal)"
            color={theme.palette.kilocalories.main}
          />
          <MacroProgress
            value={todayMacros.carbs}
            goal={userInfo?.macros?.carbs}
            label="Carbs (g)"
            color={theme.palette.carbs.main}
          />
          <MacroProgress
            value={todayMacros.protein}
            goal={userInfo?.macros?.protein}
            label="Proteínas (g)"
            color={theme.palette.protein.main}
          />
          <MacroProgress
            value={todayMacros.fat}
            goal={userInfo?.macros?.fat}
            label="Grasas (g)"
            color={theme.palette.fat.main}
          />
        </Box>
      )}
      <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
        Comidas planificadas
      </Typography>
      <List>
        {sortedTodayMeals.map((meal, index) => (
          <React.Fragment key={index}>
            <ListItem button onClick={() => handleExpandMeal(meal._id)}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'white', width: 56, height: 56, mr: 2 }}>
                  <img
                    src={
                      meal.meal === "Comida" ? "https://nutrizia.app/uploads/3-comida.png" :
                        meal.meal === "Desayuno" ? "https://nutrizia.app/uploads/1-desayuno.png" :
                          meal.meal === "Media mañana" ? "https://nutrizia.app/uploads/2-media-manana.png" :
                            meal.meal === "Media tarde" ? "https://nutrizia.app/uploads/4-media-tarde.png" :
                              meal.meal === "Cena" ? "https://nutrizia.app/uploads/5-cena.png" :
                                "URL_POR_DEFECTO" // Asegúrate de reemplazar esto con una URL de imagen por defecto
                    }
                    alt={meal.meal}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={meal.meal}
                secondary={`${Math.round(meal.macronutrients.kilocalories)} kcal`}
              />
              {expandedMeal === meal._id ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={expandedMeal === meal._id} timeout="auto" unmountOnExit>
              <Box sx={{ pl: 4, pr: 2, py: 2 }}>
                {meal.recipes && meal.recipes.length > 0 && (
                  <Box mb={2}>
                    <Typography variant="subtitle2">Recetas:</Typography>
                    <List dense>
                      {meal.recipes.map(recipeId => (
                        <ListItem key={recipeId}>
                          <Link href={`/recetas/${recipeId}`} underline="hover">
                            {recipeDetails[recipeId]?.name || 'Cargando...'}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
                {meal.ingredients && meal.ingredients.length > 0 && (
                  <Box>
                    <Typography variant="subtitle2">Ingredientes:</Typography>
                    <List dense>
                      {meal.ingredients.map(ing => (
                        <ListItem key={ing.ingredient}>
                          <ListItemText
                            primary={ingredientDetails[ing.ingredient]?.name || 'Cargando...'}
                            secondary={`${ing.quantity} ${ing.unit}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
              </Box>
            </Collapse>
          </React.Fragment>
        ))}
        {sortedTodayMeals.length === 0 && (
          <Box textAlign="center" mt={2}>
            <Typography variant="body1">
              Añade comidas a tu menú de hoy para ver algo aquí.
            </Typography>
            <Link href="/menu-semanal" variant="body1" color="primary">
              Ir a menú semanal
            </Link>
          </Box>
        )}
      </List>
    </Box>
  );
};

export default WeeklyMenuSummary;