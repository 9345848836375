import React, { useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';
import EditarUsuario from './EditarUsuario';
import { Link } from 'react-router-dom';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users`); // Asegúrate de que esta ruta sea correcta en tu backend
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
  };

  const handleUserUpdate = (updatedUser) => {
    setUsers(users.map(user => user._id === updatedUser._id ? updatedUser : user));
  };

  return (
    <Container>
      <h1>Usuarios Registrados</h1>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Rol</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user._id}>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>
                <Button onClick={() => handleEditClick(user)}>Editar</Button>
                <Button component={Link} to={`/gestion-weights/${user._id}`}>Peso</Button>
                <Button component={Link} to={`/gestion-bmi/${user._id}`}>IMC</Button>
                <Button component={Link} to={`/gestion-bodyfat/${user._id}`}>Grasa Corporal</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selectedUser && 
        <EditarUsuario 
          user={selectedUser} 
          onClose={() => setSelectedUser(null)} 
          onUpdate={handleUserUpdate}
        />
      }
    </Container>
  );
};

export default UserList;
