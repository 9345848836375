import React, { useEffect, useState } from 'react';
import { useAuth } from "../context/AuthContext";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Grid, Typography, Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTheme } from '@mui/material/styles';

const UserShoppingLists = () => {
  const [lists, setLists] = useState([]);
  const { authToken } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    fetchUserLists();
  }, []);

  const fetchUserLists = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/listascompra`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching user');
      }
      const userLists = await response.json();
      // Ordenamos las listas por estado de archivo y fecha de creación
      const sortedLists = userLists.sort((a, b) => a.archived - b.archived || new Date(b.createdAt) - new Date(a.createdAt));
      setLists(sortedLists);
    } catch (error) {
      console.error(error);
    }
  };

  const handleArchive = async (id, archived) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/listascompra/archive/${id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ archived: !archived }),
      });
      if (!response.ok) {
        throw new Error('Error archiving list');
      }
      // Refetch the lists after archiving
      fetchUserLists();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="h4" component="h1" gutterBottom>Mis listas</Typography>
        <Link to="/listas/crear-lista" style={{ textDecoration: 'none' }}> {/* Agrega el componente Link */}
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="contained"
            color="primary"
            sx={{
              mt: 1,
              mb: 1,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              }
            }}
          >
            Crear nueva lista
          </Button>
        </Link>
      </Box>
      <Box display="flex" justifyContent="center">
        <Grid container item xs={12} md={8} justifyContent="center">
          <Paper>
          <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre de la lista</TableCell>
              <TableCell>Ingredientes</TableCell>
              <TableCell>Fecha de creación</TableCell>
              <TableCell>Archivada</TableCell> {/* Nueva celda para "Archivada" */}
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lists.map((list) => {
              const totalIngredientes = list.ingredientes.length;
              const ingredientesComprados = list.ingredientes.filter(ingrediente => ingrediente.estado === 'comprado').length;
              const porcentajeComprado = totalIngredientes > 0 ? (ingredientesComprados / totalIngredientes) * 100 : 0;

              return (
                <TableRow key={list._id}>
                  <TableCell>{list.nombre}</TableCell>
                  <TableCell>{porcentajeComprado.toFixed(2)}% comprado</TableCell>
                  <TableCell>{new Date(list.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={list.archived}
                      onChange={() => handleArchive(list._id, list.archived)}
                    />
                  </TableCell> {/* Nueva celda para el checkbox "Archivada" */}
                  <TableCell>
                    <Link to={`/listas/${list._id}`}>
                      <Button>Ver Lista</Button>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
          </Paper>
        </Grid>
      </Box>
    </Box>
  );
};

export default UserShoppingLists;