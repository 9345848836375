import React, { useEffect, useState } from 'react';
import {
  Container, Box, Typography, Button, Grid, Card, CardMedia, CardContent, CardHeader,
  AppBar, Toolbar, IconButton, useMediaQuery, Drawer, List, ListItem, ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(to right, #001f3f, #003366)',
}));

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundImage: 'url(https://nutrizia.app/uploads/nutrizia-plan-comidas-nutricional-aplicaci%C3%B3n.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'white',
  padding: theme.spacing(15, 2),
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

const Feature = ({ icon, title, description }) => (
  <FeatureCard elevation={3}>
    <CardHeader
      avatar={icon}
      title={<Typography variant="h6">{title}</Typography>}
    />
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography variant="body2">{description}</Typography>
    </CardContent>
  </FeatureCard>
);

function fetchRecipes() {
  return fetch(`${process.env.REACT_APP_API_URL}/recipes/recent`).then(response => response.json());
}

// Componente para la Tarjeta de la Receta
const RecipeCard = ({ image, name }) => (
  <Card>
    <CardMedia
      component="img"
      height="140"
      image={image || 'https://via.placeholder.com/140'} // URL de imagen por defecto o 'image'
      alt={name}
    />
    <CardContent>
      <Typography gutterBottom variant="h6" component="div">
        {name}
      </Typography>
    </CardContent>
  </Card>
);

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    fetchRecipes().then(data => {
      // Asumiendo que las recetas vienen ordenadas por fecha de creación, de más nueva a más antigua
      setRecipes(data.slice(0, 8)); // Tomar solo las 8 últimas recetas
    });
  }, []);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
      <HeroSection>
        <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1 }}>
          <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
            Mejora tu salud a través de la nutrición
          </Typography>
          <Typography variant="h5" component="p" gutterBottom align="center">
            Nutrizia te ayuda a crear hábitos alimenticios saludables de forma fácil y divertida.
          </Typography>
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Button
              component={Link}
              to="/registro"
              variant="contained"
              size="large"
              sx={{
                bgcolor: theme.palette.secondary.main,
                color: 'white',
                '&:hover': {
                  bgcolor: theme.palette.secondary.dark,
                },
              }}
            >
              EMPIEZA A CUIDARTE
            </Button>
          </Box>
        </Container>
      </HeroSection>

      <Container maxWidth="lg">
        <Box id="features" sx={{ my: 8 }}>
          <Typography variant="h4" component="h2" gutterBottom textAlign="center" sx={{ mb: 6 }}>
            La aplicación que mejora tu salud a través de la Nutrición
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Feature
                icon={<RestaurantMenuIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />}
                title="Creador y gestor de recetas"
                description="Navega entre una amplia variedad de recetas saludables o crea las tuyas propias."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Feature
                icon={<CalendarTodayIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />}
                title="Creador de menús semanales"
                description="Configura tu menú semanal y controla tus macronutrientes diarios para una dieta equilibrada."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Feature
                icon={<ShoppingCartIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />}
                title="Lista de la compra inteligente"
                description="Genera tu lista de la compra basada en tu menú semanal y reduce el desperdicio de alimentos."
              />
            </Grid>
          </Grid>
        </Box>
        <Box id="recipes" sx={{ my: 8, textAlign: 'center' }}>
          <Container maxWidth="lg" sx={{ my: 8 }}>
            <Typography variant="h4" component="h2" gutterBottom textAlign="center" sx={{ mb: 6 }}>
              Últimas Recetas
            </Typography>
            <Grid container spacing={4}>
              {recipes.map((recipe, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <RecipeCard name={recipe.name} image={recipe.mainImage} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Box id="about" sx={{ my: 8, textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Acerca de Nutrizia
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: '800px', mx: 'auto' }}>
            Nutrizia nació con la misión de hacer que la alimentación saludable sea accesible y fácil para todos.
            Nuestra app te guía en cada paso del proceso, desde la planificación de tus comidas hasta la compra de ingredientes.
          </Typography>
        </Box>

        <Box id="contact" sx={{ my: 8, textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Contáctanos
          </Typography>
          <Typography variant="body1" gutterBottom>
            ¿Tienes preguntas? Estamos aquí para ayudarte.
          </Typography>
          <Button
            variant="contained"
            sx={{
              mt: 2,
              bgcolor: theme.palette.primary.main,
              '&:hover': {
                bgcolor: theme.palette.primary.dark,
              },
            }}
          >
            ENVIAR MENSAJE
          </Button>
        </Box>
      </Container>

      <Box component="footer" sx={{ bgcolor: theme.palette.primary.main, color: 'white', py: 3, textAlign: 'center' }}>
        <Typography variant="body2">
          © 2024 Nutrizia. Todos los derechos reservados.
        </Typography>
      </Box>
    </Box>
  );
};

export default HomePage;