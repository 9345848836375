import React from 'react';
import { ListItemIcon, ListItemText, Checkbox } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green, grey } from '@mui/material/colors';

const IngredientItem = ({ ingredient, isAdmin, showName = true, showCheckbox = true }) => {
  return (
    <>
      {isAdmin && showCheckbox && (
        <ListItemIcon>
          <CheckCircleIcon
            sx={{
              color: ingredient.revisado ? green[500] : grey[500],
            }}
          />
        </ListItemIcon>
      )}
      {showName && <ListItemText primary={ingredient.name} />}
    </>
  );
};

export default IngredientItem;