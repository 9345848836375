import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, TextField, Grid, TablePagination, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, TableSortLabel } from '@mui/material';
import { useAuth } from '../context/AuthContext';

function fetchIngredients() {
    return fetch(`${process.env.REACT_APP_API_URL}/ingredients`).then(response => response.json());
}

function GestionIngredientes() {
    const { userRole } = useAuth();
    console.log("El rol actual es " + userRole);
    const isAdmin = userRole === 'admin';
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [filterReviewed, setFilterReviewed] = useState('all');
    const [ingredients, setIngredients] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderDirection, setOrderDirection] = useState('asc'); // Nuevo estado para la dirección del ordenamiento
    const [orderBy, setOrderBy] = useState(''); // Nuevo estado para la columna por la que se ordena

    React.useEffect(() => {
        if (!isAdmin) {
            navigate('/dashboard');
        }
    }, [isAdmin, navigate]);

    useEffect(() => {
        fetchIngredients().then(setIngredients);
    }, []);

    // Filtrar ingredientes basado en la búsqueda
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortIngredients = (array) => {
        return array.sort((a, b) => {
            if (orderBy === '') return 0;
            if (a[orderBy] < b[orderBy]) {
                return orderDirection === 'asc' ? -1 : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return orderDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    const filteredIngredients = sortIngredients(ingredients
        .filter(ingredient => ingredient.name.toLowerCase().includes(search.toLowerCase()))
        .filter(ingredient => filterReviewed === 'all' || (filterReviewed === 'yes' ? ingredient.revisado : !ingredient.revisado)))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleClickOpen = (ingredient) => {
        console.log('Opening dialog for ingredient:', ingredient); // Agrega este log para depuración
        setSelectedIngredient(ingredient);
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        fetch(`${process.env.REACT_APP_API_URL}/ingredients/${selectedIngredient._id}`, {
            method: 'DELETE',
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === "Ingrediente eliminado") {
                    // Actualizar la lista de ingredientes después de eliminar
                    setIngredients(ingredients.filter(ingredient => ingredient._id !== selectedIngredient._id));
                } else {
                    console.error('Error:', data);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        setOpen(false);
    };

    return (
        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '80%', margin: '0 auto' }}>
            <Grid item xs={12}>
                <TextField
                    label="Buscar ingrediente"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        setPage(0); // Lleva al usuario a la primera página
                    }}
                />
                <Select
                    value={filterReviewed}
                    onChange={(e) => setFilterReviewed(e.target.value)}
                >
                    <MenuItem value="all">Todos</MenuItem>
                    <MenuItem value="yes">Revisado</MenuItem>
                    <MenuItem value="no">No revisado</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" sx={{ bgcolor: 'primary.main', color: '#fff' }} component={Link} to="/crear-ingrediente">
                    Crear Ingrediente
                </Button>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'kilocalories'}
                                        direction={orderBy === 'kilocalories' ? orderDirection : 'asc'}
                                        onClick={() => handleRequestSort('kilocalories')}
                                    >
                                        Calorías
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'carbs'}
                                        direction={orderBy === 'carbs' ? orderDirection : 'asc'}
                                        onClick={() => handleRequestSort('carbs')}
                                    >
                                        Carbohidratos
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'protein'}
                                        direction={orderBy === 'protein' ? orderDirection : 'asc'}
                                        onClick={() => handleRequestSort('protein')}
                                    >
                                        Proteínas
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'fat'}
                                        direction={orderBy === 'fat' ? orderDirection : 'asc'}
                                        onClick={() => handleRequestSort('fat')}
                                    >
                                        Grasas
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'gramsPerUnit'}
                                        direction={orderBy === 'gramsPerUnit' ? orderDirection : 'asc'}
                                        onClick={() => handleRequestSort('gramsPerUnit')}
                                    >
                                        Gramos por Unidad
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Revisado</TableCell>
                                <TableCell>Editar</TableCell>
                                <TableCell>Eliminar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredIngredients.map((ingredient) => (
                                <TableRow key={ingredient._id}>
                                    <TableCell>{ingredient.name}</TableCell>
                                    <TableCell>{parseFloat(ingredient.kilocalories).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                    <TableCell>{parseFloat(ingredient.carbs).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                    <TableCell>{parseFloat(ingredient.protein).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                    <TableCell>{parseFloat(ingredient.fat).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                    <TableCell>{ingredient.gramsPerUnit}</TableCell> {/* Asumiendo que gramsPerUnit es un valor entero o no necesita formato decimal */}
                                    <TableCell>{ingredient.revisado ? 'Si' : 'No'}</TableCell>
                                    <TableCell>
                                        <Button variant="contained" sx={{ bgcolor: 'primary.main', color: '#fff' }} component={Link} to={`/editar-ingrediente/${ingredient._id}`}>
                                            Editar
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" color="secondary" onClick={() => handleClickOpen(ingredient)}>
                                            Eliminar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={ingredients.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                    />
                </TableContainer>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Eliminar Ingrediente"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de que deseas eliminar este ingrediente?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

        </Grid>

    );
}

export default GestionIngredientes;