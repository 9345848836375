import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { fontSize } from '@mui/system';
import { light } from '@mui/material/styles/createPalette';

//https://coolors.co/palette/0081a7-00afb9-fdfcdc-fed9b7-f07167
//https://coolors.co/palette/8ea604-f5bb00-ec9f05-d76a03-bf3100
//https://coolors.co/palette/8ed081-b4d2ba-dce2aa-b57f50-4b543b
//https://coolors.co/palette/22577a-38a3a5-57cc99-80ed99-c7f9cc

const theme = createTheme({
  palette: { // Mi paleta https://coolors.co/d4b99b-479079-3f6e5f-59dfb5-011936
    primary: {
      main: '#22BDD5',
      dark: '#011936',
    },
    lightGray: {
      main: '#8f8f8f',
    },
    kilocalories: {
      main: '#E57C7C',
      light: 'd9a0a0'
    },
    protein: {
      main: '#ECD180',
      light: 'e8dab0'
    },
    fat: {
      main: '#ADE2EA',
      light:'d1e5e8'
    },
    carbs: {
      main: '#B9E8B3',
      light:'daf0d8'
    },
    other: {
      whatsapp: '#25D366',
    }
  },
  typography: {
    fontFamily: 'Quicksand, sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: '500',
    },
    h3: {
      fontSize: '2rem', 
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.3rem',
    },
    h6: {
      fontSize: '1.1rem', 
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
