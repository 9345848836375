import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, useMediaQuery, Box, Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';

const pages = [
  { name: 'Inicio', url: '/dashboard', requiresAuth: false },
  { name: 'Recetas', url: '/recetas', requiresAuth: false },
  { name: 'Menú Semanal', url: '/menu-semanal', requiresAuth: true },
  { name: 'Lista de la compra', url: '/listas', requiresAuth: true },
]

const managementPages = [
  { name: 'Gestionar Recetas', url: '/gestion-recetas' },
  { name: 'Gestionar Ingredientes', url: '/gestion-ingredientes' },
  { name: 'Gestionar Taxonomías', url: '/gestion-taxonomias' },
  { name: 'Gestionar Usuarios', url: '/gestion-usuarios' }
];

function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElManagement, setAnchorElManagement] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileManagementOpen, setMobileManagementOpen] = useState(false);
  const { isAuthenticated, logout, userRole, authToken } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  console.log("authToken", authToken);  
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const handleOpenManagementMenu = (event) => setAnchorElManagement(event.currentTarget);
  const handleCloseManagementMenu = () => setAnchorElManagement(null);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleMenuItemClick = (path) => {
    handleCloseUserMenu();
    navigate(path);
  };

  const toggleMobileManagement = () => {
    setMobileManagementOpen(!mobileManagementOpen);
  };

  const renderDesktopMenu = () => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {pages.map((page) => (
        (page.requiresAuth === isAuthenticated || !page.requiresAuth) && (
          <Button
            key={page.name}
            component={Link}
            to={page.url}
            sx={{
              color: theme.palette.text.primary,
              mx: 1,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '4px',
              },
            }}
          >
            {page.name}
          </Button>
        )
      ))}
      {isAuthenticated && userRole === 'admin' && (
        <Box sx={{ position: 'relative', display: 'inline-block' }}>
          <Button
            onClick={handleOpenManagementMenu}
            sx={{
              color: theme.palette.text.primary,
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '4px',
              },
            }}
            endIcon={<ExpandMoreIcon />}
          >
            Gestión
          </Button>
          <Menu
            anchorEl={anchorElManagement}
            open={Boolean(anchorElManagement)}
            onClose={handleCloseManagementMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {managementPages.map((page) => (
              <MenuItem
                key={page.name}
                component={Link}
                to={page.url}
                onClick={handleCloseManagementMenu}
              >
                {page.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </Box>
  );

  const renderMobileMenu = () => (
    <Menu
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
    >
      {pages.map((page) => (
        (page.requiresAuth === isAuthenticated || !page.requiresAuth) && (
          <MenuItem key={page.name} onClick={handleCloseNavMenu} component={Link} to={page.url}>
            <Typography textAlign="center">{page.name}</Typography>
          </MenuItem>
        )
      ))}
      {isAuthenticated && userRole === 'admin' && (
        <>
          <MenuItem onClick={toggleMobileManagement}>
            <Typography textAlign="center">Gestión</Typography>
            <ExpandMoreIcon />
          </MenuItem>
          <Collapse in={mobileManagementOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {managementPages.map((page) => (
                <ListItemButton
                  key={page.name}
                  sx={{ pl: 4 }}
                  component={Link}
                  to={page.url}
                  onClick={handleCloseNavMenu}
                >
                  <ListItemText primary={page.name} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </>
      )}
    </Menu>
  );

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.5)',
        boxShadow: isScrolled ? '0px 2px 4px -1px rgba(0,0,0,0.2)' : 'none',
        transition: 'background-color 0.3s, box-shadow 0.3s',
        backdropFilter: 'blur(10px)',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between', py: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src="https://nutrizia.app/uploads/nutrizia-logo.png"
            alt="Nutrizia Logo"
            style={{ height: '80px', marginRight: '10px' }}
          />
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{
              fontFamily: 'Arial, sans-serif',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
          >
            NUTRIZIA
          </Typography>
        </Box>

        {isMobile ? (
          <>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            {renderMobileMenu()}
          </>
        ) : (
          renderDesktopMenu()
        )}

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            sx={{
              borderRadius: '30px',
              px: 2,
              py: 0.5,
              backgroundColor: 'white', // Fondo blanco
              color: 'black', // Tipografía negra
              border: '1px solid black', // Borde negro
              '&:hover': {
                backgroundColor: '#f0f0f0', // Un ligero cambio para el hover, ajusta según necesites
              },
            }}
            onClick={handleOpenUserMenu}
            startIcon={<Avatar src="https://nutrizia.app/uploads/icono-user-100.png" />}
          >
            {isAuthenticated ? 'Mi Cuenta' : 'Iniciar Sesión'}
          </Button>
          <Menu
            id="menu-appbar-user"
            anchorEl={anchorElUser}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {isAuthenticated ? (
              [
                <MenuItem key="profile" onClick={() => handleMenuItemClick('/perfil')}>Cuenta</MenuItem>,
                <MenuItem key="nutritional-profile" onClick={() => handleMenuItemClick('/perfil/mis-datos')}>Perfil Nutricional</MenuItem>,
                <MenuItem key="logout" onClick={handleLogout}>Cerrar Sesión</MenuItem>
              ]
            ) : (
              [
                <MenuItem key="login" onClick={() => handleMenuItemClick('/login')}>Iniciar Sesión</MenuItem>,
                <MenuItem key="register" onClick={() => handleMenuItemClick('/registro')}>Registrarse</MenuItem>
              ]
            )}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;