import React, { useState, useEffect, useRef } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { List, ListItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '@mui/material/styles'; // Importar useTheme
import { styled } from '@mui/material/styles';
import { Snackbar, Alert, IconButton, DialogActions, Dialog, DialogTitle, DialogContent, Link, Paper, Box, Button, Stepper, Step, StepLabel, Typography, Grid, Container, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CloseIcon from '@mui/icons-material/Close';
import KitchenIcon from '@mui/icons-material/Kitchen';
import ChecklistIcon from '@mui/icons-material/Checklist';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { useNavigate } from 'react-router-dom';

const steps = ['Fechas', 'Tu Nevera', 'Lista de la compra'];
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <EditCalendarIcon />,
    2: <KitchenIcon />,
    3: <ChecklistIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}



function ShoppingListCreator() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [recipes, setRecipes] = useState([]);
  const [displayStep, setDisplayStep] = useState(0);
  const [dinnersPerRecipe, setDinnersPerRecipe] = useState({});
  const [ingredients, setIngredients] = useState({});
  const [fridgeAmounts, setFridgeAmounts] = useState({});
  const [shoppingList, setShoppingList] = useState({});
  const { authToken } = useAuth();
  const theme = useTheme(); // Usar el tema
  const [recipeData, setRecipeData] = useState({});
  const [ingredientData, setIngredientData] = useState({});
  const [userID, setUserId] = useState(null);
  const hasFetchedUser = useRef(false);
  const [userInfo, setUserInfo] = useState({});
  const [consolidatedIngredients, setConsolidatedIngredients] = useState({});
  const [openAlertGuardarLista, setOpenAlertGuardarLista] = React.useState(false);
  const navigate = useNavigate();


  const handleCloseGuardarListaCompra = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertGuardarLista(false);
  };
  const fetchIngredientData = async () => {
    const ingredientIds = Object.keys(ingredients);
    const ingredientResponses = await Promise.all(ingredientIds.map(id => fetch(`${process.env.REACT_APP_API_URL}/ingredients/${id}`)));
    const ingredientData = await Promise.all(ingredientResponses.map(response => response.json()));
    setIngredientData(ingredientData.reduce((obj, ingredient) => ({ ...obj, [ingredient._id]: ingredient }), {}));
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchIngredientData();
  }, [ingredients]);

  useEffect(() => {
    const consolidatedDinnersPerRecipe = Object.entries(dinnersPerRecipe).reduce((acc, [key, value]) => {
      const recipeId = key.split('-')[5];
      acc[recipeId] = (acc[recipeId] || 0) + value;
      return acc;
    }, {});

    const newConsolidatedIngredients = Object.entries(consolidatedDinnersPerRecipe).reduce((acc, [recipeId, dinners]) => {
      const recipe = recipeData[recipeId];
      if (recipe) {
        recipe.ingredients.forEach(({ ingredientId, quantity }) => {
          const requiredAmount = (quantity / recipe.servings) * dinners;
          acc[ingredientId] = (acc[ingredientId] || 0) + requiredAmount;
        });
      }
      return acc;
    }, {});
    console.log("newConsolidatedIngredients", newConsolidatedIngredients);

    setConsolidatedIngredients(newConsolidatedIngredients);
  }, [dinnersPerRecipe, recipeData]);

  const fetchData = async () => {
    const formattedStartDate = startDate.toISOString().slice(0, 10);
    const formattedEndDate = endDate.toISOString().slice(0, 10);
    if (displayStep === 1) {
      try {


        // Cargar datos de recetas cuando se seleccionan las fechas
        const response = await fetch(`${process.env.REACT_APP_API_URL}/menuschema?start=${formattedStartDate}&end=${formattedEndDate}`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        const data = await response.json();
        setRecipes(data);
        console.log("Recetas para las fechas " , formattedStartDate, " a ", formattedEndDate, ": ",  data);
        const recipeIds = data.reduce((ids, menu) => [...ids, ...menu.recipes], []);
        const recipeResponses = await Promise.all(recipeIds.map(id => fetch(`${process.env.REACT_APP_API_URL}/recipes/${id}`)));
        const recipeData = await Promise.all(recipeResponses.map(response => response.json()));
        setRecipeData(recipeData.reduce((obj, recipe) => ({ ...obj, [recipe._id]: recipe }), {}));
      } catch (error) {
        console.error(error);
      }
    } else if (displayStep === 2) {
      try {
        // Imprimir las fechas y el token en la consola
        console.log('startDate:', startDate);
        console.log('endDate:', endDate);
        console.log('authToken:', authToken);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/menuschema?start=${formattedStartDate}&end=${formattedEndDate}`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        // Imprimir el estado de la respuesta en la consola
        console.log('Response status:', response.status);
        const data = await response.json();
        setRecipes(data);
        console.log(data);

        // Recupera los datos de las recetas
        const recipeIds = data.reduce((ids, menu) => [...ids, ...menu.recipes], []);
        const recipeResponses = await Promise.all(recipeIds.map(id => fetch(`${process.env.REACT_APP_API_URL}/recipes/${id}`)));
        const recipeData = await Promise.all(recipeResponses.map(response => response.json()));
        setRecipeData(recipeData.reduce((obj, recipe) => ({ ...obj, [recipe._id]: recipe }), {}));
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        //console.log("response: ",response)
        if (!response.ok) {
          throw new Error('Error fetching user');
        }
        const userData = await response.json();
        // Obtener la información del usuario de la API cuando el componente se monta
        const userInfoResponse = await fetch(`${process.env.REACT_APP_API_URL}/userinfo/user/${userData.id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (userInfoResponse.ok) {
          const userInfoData = await userInfoResponse.json();
          //console.log("userInfoData: ",userInfoData)

          // Actualiza el estado una sola vez con toda la información del usuario
          setUserInfo(userInfoData);
        } else if (userInfoResponse.status === 404) {
          console.log('User info not found');
        } else {
          throw new Error('Error fetching user info');
        }
        setUserId(userData.id);
      } catch (error) {
        console.error('Error:', error);
        console.log("USER ID: " + error);
      }
    };

    if (authToken && !hasFetchedUser.current) {
      fetchUser();
      hasFetchedUser.current = true;
    } else {
      //console.log("no auth token")
    }
  }, [authToken]); // Dependencia en authToken

  useEffect(() => {
    fetchData();
  }, [displayStep, startDate, endDate]);

  useEffect(() => {
    //console.log(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (displayStep === 1 && recipes.length > 0) {
      const initialDinnersPerRecipe = {};
      recipes.forEach((menu) => {
        menu.recipes.forEach((recipeId, recipeIndex) => {
          const recipeKey = `${menu.date}-${menu.meal}-${recipeIndex}-${menu.recipes[recipeIndex]}`;
          initialDinnersPerRecipe[recipeKey] = userInfo.comensalesCasa;
        });
      });
      setDinnersPerRecipe(initialDinnersPerRecipe);
      console.log("initialDinnersPerRecipe", initialDinnersPerRecipe);
    }
  }, [displayStep, recipes, userInfo]);

  const handleDinnerChange = (recipeKey, value, defaultServings) => {
    setDinnersPerRecipe(prevState => ({
      ...prevState,
      [recipeKey]: value !== '' ? Number(value) : defaultServings
    }));
  };

  useEffect(() => {
    if (displayStep === 1) {
      // Recargar las recetas cada vez que el usuario regresa al paso 2
      fetchData();
    }
  }, [displayStep, startDate, endDate]);

  // Recalcular ingredientes cada vez que el usuario regresa al paso 3
  useEffect(() => {
    if (displayStep === 1) {
      loadIngredients(dinnersPerRecipe);
    }
  }, [displayStep, dinnersPerRecipe]);

  // Recalcular la lista de la compra cada vez que el usuario regresa al paso 4
  useEffect(() => {
    if (displayStep === 2) {

      generateShoppingList();
    }
  }, [displayStep, fridgeAmounts]);

  const handleNextStep = () => {
    setDisplayStep(prevStep => prevStep < steps.length - 1 ? prevStep + 1 : prevStep);
  };

  const handlePreviousStep = () => {
    setDisplayStep(prevStep => prevStep > 0 ? prevStep - 1 : prevStep);
  };

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return renderDateSelection();
      case 1:
        return renderIngredientSelection();
      case 2:
        return renderShoppingList();
      default:
        return 'Unknown Step';
    }
  };

  const loadIngredients = async (dinnersPerRecipe) => {
    const allIngredients = {};
    console.log("loadIngredientsmenus", recipes);
    if (recipes) { // Comprobar si recipes es undefined
      for (const [dateIndex, menu] of recipes.entries()) {
        console.log("loadIngredientsmenu", menu);
        for (const [recipeIndex, recipeId] of menu.recipes.entries()) { // Añadido un bucle adicional para las recetas
          const recipeKey = `${menu.date}-${dateIndex}-${menu.meal}-${recipeIndex}`; // Cambiado a recipeIndex
          if (recipeId) { // Asegurarse de que la receta tiene un id
            const recipeData = await fetch(`${process.env.REACT_APP_API_URL}/recipes/${recipeId}`)
              .then(response => response.json());
            console.log("loadIngredientsrecipeData", recipeData);
            recipeData.ingredients.forEach(ingredient => {
              // Si dinnersPerRecipe[recipeKey] no está definido, usar recipeData.servings
              console.log("loadIngredientsdinnersperrecipe", dinnersPerRecipe);
              console.log("loadIngredientsrecipekey", recipeKey);
              console.log("loadIngredientsdinnersPerRecipe[recipeKey]", dinnersPerRecipe[recipeKey]);
              const dinners = dinnersPerRecipe[recipeKey] !== undefined ? dinnersPerRecipe[recipeKey] : recipeData.servings;
              console.log('loadIngredientsValue for dinners:', dinners);
              const requiredAmount = ingredient.quantity * ((Number.isFinite(dinners) ? dinners : 0) / (Number.isFinite(recipeData.servings) ? recipeData.servings : 1));
              if (allIngredients[ingredient.ingredientId]) {
                allIngredients[ingredient.ingredientId] += requiredAmount;
              } else {
                allIngredients[ingredient.ingredientId] = requiredAmount;
              }
            });
          } else {
            console.error(`La receta con id ${recipeId} no tiene un id válido.`);
          }
        }
      }
    }
    setIngredients(allIngredients);
  };

  const generateShoppingList = () => {
    console.log("generateShoppingList", consolidatedIngredients, fridgeAmounts);
    const finalList = {};
    Object.entries(consolidatedIngredients).forEach(([ingredientId, amountRequired]) => {
      const amountInFridge = fridgeAmounts[ingredientId] ? Number(fridgeAmounts[ingredientId]) : 0;
      if (amountRequired > amountInFridge) {
        finalList[ingredientId] = amountRequired - amountInFridge;
      }
    });
    console.log("generateShoppingListfinalList", finalList);
    setShoppingList(finalList);
  };

  const renderDateSelection = () => {
    return (
      <Grid container justifyContent="center" sx={{ mt: 4 }}>
        <Typography variant="h6" align="center" gutterBottom>
          ¿Para qué días quieres hacer la lista?
        </Typography>
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Grid item xs={12} md={8}><LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Desde el"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="Hasta el"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderRecipeSelection = () => {
    const groupedByDate = recipes.reduce((acc, curr) => {
      (acc[curr.date] = acc[curr.date] || []).push(curr);
      return acc;
    }, {});

    const mealOrder = ["Desayuno", "Almuerzo", "Comida", "Merienda", "Cena"];

    let trackedDate = null;

    return (
      <DialogContent>
        <TableContainer component={Paper} style={{ maxHeight: '60vh', overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Día</TableCell>
                <TableCell>Comida del día</TableCell>
                <TableCell>Receta</TableCell>
                <TableCell>Número de comensales</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedByDate).map(([date, meals], dateIndex) => {
                meals.sort((a, b) => mealOrder.indexOf(a.meal) - mealOrder.indexOf(b.meal));
                const totalRecipesForDate = meals.reduce((acc, curr) => acc + curr.recipes.length, 0);
                return meals.map((meal, mealIndex) => (
                  meal.recipes.sort().map((recipe, recipeIndex) => {
                    const recipeKey = `${date}-${meal.meal}-${recipeIndex}-${meal.recipes[recipeIndex]}`;
                    const defaultServings = recipeData[recipe]?.servings || 1;
                    const showDate = trackedDate !== date;
                    if (showDate) {
                      trackedDate = date;
                    }
                    return (
                      <TableRow key={recipeKey}>
                        {showDate &&
                          <TableCell rowSpan={totalRecipesForDate}>
                            {new Date(date).toLocaleDateString()}
                          </TableCell>
                        }
                        {recipeIndex === 0 &&
                          <TableCell rowSpan={meal.recipes.length}>
                            {meal.meal}
                          </TableCell>
                        }
                        <TableCell>
                          {recipeData[recipe]?.name || 'Receta no encontrada'}
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={dinnersPerRecipe[recipeKey] !== undefined ? dinnersPerRecipe[recipeKey] : defaultServings}
                            onChange={e => {
                              const newValue = Number(e.target.value);
                              if (newValue >= 0) {
                                handleDinnerChange(recipeKey, newValue, defaultServings);
                              } else {
                                handleDinnerChange(recipeKey, 0, defaultServings);
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ));
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    );
  };

  const renderIngredientSelection = () => {
    return (
      <Grid container justifyContent="center">
        <Typography variant="h4" align="center" gutterBottom>
          ¿Tienes algo en la nevera?
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Hemos calculado las cantidades contando con {userInfo.comensalesCasa} comensales. Si quieres modificar la cantidad de alguna de las comidas, <Link href="#" onClick={handleClickOpen}>haz click aquí</Link>.
        </Typography>
        <Dialog 
          open={open} 
          onClose={handleClose}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            Modificar comensales por comida
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {renderRecipeSelection()}
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item xs={12} md={8}>
          <Paper>
            <TableContainer style={{ maxHeight: '60vh', overflow: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Ingrediente</TableCell>
                    <TableCell>Necesitas</TableCell>
                    <TableCell>Tienes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(consolidatedIngredients).map(([ingredientId, amount], index) => {
                    if (amount === 0) {
                      return null;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell>{ingredientData[ingredientId]?.name || ingredientId}</TableCell>
                        <TableCell>{`${amount} ${ingredientData[ingredientId]?.standardUnit || ''}`}</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={fridgeAmounts[ingredientId] || ''}
                            onChange={e => setFridgeAmounts({ ...fridgeAmounts, [ingredientId]: e.target.value })}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Button sx={{ mt: 2 }} variant="contained" onClick={handleNextStep}>Crear lista de la compra</Button>
        </Grid>
      </Grid>
    );
  };

  const renderShoppingList = () => (
    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
      <Grid item xs={12} md={8} >
        <Typography variant="h4" align="center" gutterBottom>Aquí tienes tu lista de la compra del</Typography>
        <Typography variant="p" align="center" gutterBottom>Guárdala para poder usarla más adelante en el súper 😉</Typography>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ingrediente</TableCell>
                  <TableCell>Cantidad</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(shoppingList).map(([ingredientId, amount], index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{ingredientData[ingredientId]?.name || ingredientId}</TableCell>
                      <TableCell>{amount}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Button
          variant="contained"
          color="primary"
          onClick={saveShoppingList}
          sx={{ mt: 2 }}
        >
          Guardar lista de la compra
        </Button>
        <Snackbar 
        open={openAlertGuardarLista} 
        autoHideDuration={2000} 
        onClose={handleCloseGuardarListaCompra}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseGuardarListaCompra} severity="success">
          Lista de la compra guardada con éxito. Redirigiendo...
        </Alert>
      </Snackbar>
      </Grid>
    </Grid>
  );
  const saveShoppingList = async () => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    const formattedStartDate = `${startDateObj.getDate().toString().padStart(2, '0')}/${(startDateObj.getMonth() + 1).toString().padStart(2, '0')}/${startDateObj.getFullYear()}`;
    const formattedEndDate = `${endDateObj.getDate().toString().padStart(2, '0')}/${(endDateObj.getMonth() + 1).toString().padStart(2, '0')}/${endDateObj.getFullYear()}`;

    const userId = userID;
    const nombre = `Compra del ${formattedStartDate} al ${formattedEndDate}`;
    const ingredientes = Object.entries(shoppingList).map(([ingredientId, amount]) => ({
      idIngrediente: ingredientId,
      cantidad: amount,
      estado: "pendiente"
    }));

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/listascompra`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          userId,
          nombre,
          ingredientes
        }),
      });

      if (!response.ok) {
        throw new Error('Error saving shopping list');
      }

      setOpenAlertGuardarLista(true);
      
      // Esperar un breve momento antes de redirigir para que el usuario pueda ver la alerta
      setTimeout(() => {
        navigate('/listas');
      }, 2000); // Redirige después de 2 segundos
    } catch (error) {
      console.error('Error saving shopping list:', error);
      // Aquí podrías mostrar una alerta de error al usuario
    }
  };

  return (
    <Box>
      <Grid container justify="center" sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} sx={{ mb: 5, margin: 'auto' }}>
          <Typography variant="h4" align="center" gutterBottom>Crea tu lista de la compra</Typography>
          <Stepper alternativeLabel activeStep={displayStep} connector={<ColorlibConnector />}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {renderStepContent(displayStep)}
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
              {displayStep > 0 && (
                <Button onClick={handlePreviousStep}>Volver</Button>
              )}
              {displayStep < steps.length - 2 && (
                <Button variant="contained" onClick={handleNextStep}>Siguiente</Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ShoppingListCreator;