import React, { useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { Box, Typography, Switch, FormControlLabel, useTheme, Paper, Grid } from '@mui/material';
import { Grain, EggAlt, LocalPizza } from '@mui/icons-material';

const MacronutrientsChart = ({ totalMacronutrients }) => {
  const [showKcal, setShowKcal] = useState(false);
  const theme = useTheme();

  const calculateKcal = (carbs, protein, fat) => ({
    carbs: carbs * 4,
    protein: protein * 4,
    fat: fat * 9
  });

  const kcal = calculateKcal(
    totalMacronutrients.carbs,
    totalMacronutrients.protein,
    totalMacronutrients.fat
  );

  const totalKcal = kcal.carbs + kcal.protein + kcal.fat;

  const dataGrams = [
    { name: 'Carbohidratos', value: totalMacronutrients.carbs, color: theme.palette.carbs.main, icon: <Grain /> },
    { name: 'Proteínas', value: totalMacronutrients.protein, color: theme.palette.protein.main, icon: <EggAlt /> },
    { name: 'Grasas', value: totalMacronutrients.fat, color: theme.palette.fat.main, icon: <LocalPizza /> }
  ];

  const dataKcal = [
    { name: 'Carbohidratos', value: kcal.carbs, color: theme.palette.carbs.main, icon: <Grain /> },
    { name: 'Proteínas', value: kcal.protein, color: theme.palette.protein.main, icon: <EggAlt /> },
    { name: 'Grasas', value: kcal.fat, color: theme.palette.fat.main, icon: <LocalPizza /> }
  ];

  const data = showKcal ? dataKcal : dataGrams;

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography variant="h6" gutterBottom display="inline-block" mr={2}>
          Distribución de Macronutrientes
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={showKcal}
              onChange={() => setShowKcal(!showKcal)}
              color="primary"
              size="small"
            />
          }
          label={showKcal ? "Kcal" : "Gramos"}
        />
      </Box>
      
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
          <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
            {/* {totalKcal.toFixed(0)} */}
            {totalMacronutrients.kilocalories}
          </Typography>
          <Typography variant="subtitle1" component="div">
            kcal totales
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                innerRadius={60}
                paddingAngle={5}
                label={false}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value, name, props) => {
                  const percent = ((value / props.payload.value) * 100).toFixed(1);
                  const kcalValue = showKcal ? value : (name === 'Grasas' ? value * 9 : value * 4);
                  return [
                    `${showKcal ? value.toFixed(0) : value.toFixed(1)}${showKcal ? ' kcal' : 'g'} (${kcalValue.toFixed(0)} kcal)`,
                    `${name} - ${percent}%`
                  ];
                }}
              />
              <Legend layout="horizontal" verticalAlign="bottom" align="center" />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MacronutrientsChart;