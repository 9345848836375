import React, { useState, useEffect } from 'react';
import { Grid, Button, ToggleButton, ToggleButtonGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import EditarCategoriaReceta from './EditarCategoriaReceta';
import NuevaCategoriaReceta from './Crear/NuevaCategoriaReceta';
import EditarRestriccion from './EditarRestriccion';
import NuevaRestriccion from './Crear/NuevaRestriccion';
import EditarTipoDeCocina from './EditarTipoDeCocina';
import NuevoTipoDeCocina from './Crear/NuevoTipoDeCocina';
import EditarAlergeno from './Editar/EditarAlergeno';
import NuevoAlergeno from './Crear/NuevoAlergeno';
import EditarCategoriaIngrediente from './Editar/EditarCategoriaIngrediente';
import NuevaCategoriaIngrediente from './Crear/NuevoCategoriaIngrediente';
import { Link } from 'react-router-dom';

function GestionarTaxonomias() {
  const [categorias, setCategorias] = useState([]);
  const [restricciones, setRestricciones] = useState([]);
  const [tiposDeCocina, setTiposDeCocina] = useState([]);
  const [alergenos, setAlergenos] = useState([]);
  const [categoriasIngredientes, setCategoriasIngredientes] = useState([]);
  const [selectedValue, setSelectedValue] = useState('Categoría Receta');
  const [openDelete, setOpenDelete] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetchCategorias();
    fetchRestricciones();
    fetchTiposDeCocina();
    fetchAlergenos();
    fetchCategoriasIngredientes();
  };

  const handleValueChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  const fetchCategorias = () => {
    fetch(`${process.env.REACT_APP_API_URL}/categoriaRecetas`)
      .then(response => response.json())
      .then(data => setCategorias(data))
      .catch(error => console.error(error));
  };

  const fetchRestricciones = () => {
    fetch(`${process.env.REACT_APP_API_URL}/restricciones`)
      .then(response => response.json())
      .then(data => setRestricciones(data))
      .catch(error => console.error(error));
  };

  const fetchTiposDeCocina = () => {
    fetch(`${process.env.REACT_APP_API_URL}/tipoDeCocina`)
      .then(response => response.json())
      .then(data => setTiposDeCocina(data))
      .catch(error => console.error(error));
  };

  const fetchAlergenos = () => {
    fetch(`${process.env.REACT_APP_API_URL}/allergens`)
      .then(response => response.json())
      .then(data => setAlergenos(data))
      .catch(error => console.error(error));
  };

  const fetchCategoriasIngredientes = () => {
    fetch(`${process.env.REACT_APP_API_URL}/ingredient-categories`)
      .then(response => response.json())
      .then(data => setCategoriasIngredientes(data))
      .catch(error => console.error(error));
  };

  const handleClickOpenDelete = (id) => {
    setToDelete(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const endpointMap = {
    'Categoría Receta': 'categoriaRecetas',
    'Restricciones': 'restricciones',
    'Tipo Cocina': 'tipoDeCocina',
    'Alérgenos': 'allergens',
    'Categoría Ingrediente': 'ingredient-categories',
  };

  const handleDelete = () => {
    const endpoint = endpointMap[selectedValue];
    fetch(`${process.env.REACT_APP_API_URL}/${endpoint}/${toDelete}`, {
      method: 'DELETE',
    })
      .then(() => {
        setOpenDelete(false);
        fetchData();
      })
      .catch(error => console.error(error));
  };

  const renderTable = (items, editPath) => (
    <Box width="80%" mx="auto">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              {selectedValue === 'Categoría Ingrediente' && <TableCell>Categoría Padre</TableCell>}
              <TableCell>Editar</TableCell>
              <TableCell>Eliminar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item._id}>
                <TableCell>{item.name || item.nombre}</TableCell>
                {selectedValue === 'Categoría Ingrediente' &&
                  <TableCell>{item.parent ? item.parent.name : 'Ninguna'}</TableCell>
                }
                <TableCell>
                  <Button variant="contained" component={Link} to={`${editPath}/${item._id}`}>Editar</Button>
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="secondary" onClick={() => handleClickOpenDelete(item._id)}>Eliminar</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderCreateComponent = () => {
    switch(selectedValue) {
      case 'Categoría Receta':
        return <NuevaCategoriaReceta onCategoriaCreated={() => { fetchCategorias(); handleCloseCreate(); }} />;
      case 'Restricciones':
        return <NuevaRestriccion onRestriccionCreated={() => { fetchRestricciones(); handleCloseCreate(); }} />;
      case 'Tipo Cocina':
        return <NuevoTipoDeCocina onTipoDeCocinaCreated={() => { fetchTiposDeCocina(); handleCloseCreate(); }} />;
      case 'Alérgenos':
        return <NuevoAlergeno onAlergenoCreated={() => { fetchAlergenos(); handleCloseCreate(); }} />;
      case 'Categoría Ingrediente':
        return <NuevaCategoriaIngrediente onCategoriaIngredienteCreated={() => { fetchCategoriasIngredientes(); handleCloseCreate(); }} />;
      default:
        return null;
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <ToggleButtonGroup
          value={selectedValue}
          exclusive
          onChange={handleValueChange}
        >
          <ToggleButton value="Categoría Receta">Categoría Receta</ToggleButton>
          <ToggleButton value="Tipo Cocina">Tipo Cocina</ToggleButton>
          <ToggleButton value="Restricciones">Restricciones</ToggleButton>
          <ToggleButton value="Alérgenos">Alérgenos</ToggleButton>
          <ToggleButton value="Categoría Ingrediente">Categoría Ingrediente</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={handleClickOpenCreate}>
          Crear {selectedValue}
        </Button>
      </Grid>
      <Grid item>
        {selectedValue === 'Categoría Receta' && renderTable(categorias, '/editar-categoria-receta')}
        {selectedValue === 'Restricciones' && renderTable(restricciones, '/editar-restriccion')}
        {selectedValue === 'Tipo Cocina' && renderTable(tiposDeCocina, '/editar-tipo-de-cocina')}
        {selectedValue === 'Alérgenos' && renderTable(alergenos, '/editar-alergeno')}
        {selectedValue === 'Categoría Ingrediente' && renderTable(categoriasIngredientes, '/editar-categoria-ingrediente')}
      </Grid>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que quieres eliminar esta taxonomía?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCreate}
        onClose={handleCloseCreate}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Crear nuevo {selectedValue}</DialogTitle>
        <DialogContent>
          {renderCreateComponent()}
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export default GestionarTaxonomias;