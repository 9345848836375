import React, { useState } from 'react';
import { Grid, TextField, Button } from '@mui/material';

function NuevaCategoriaReceta({ onCategoriaCreated }) {
  const [nombre, setNombre] = useState('');

  const handleSave = () => {
    fetch(`${process.env.REACT_APP_API_URL}/categoriaRecetas`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ nombre })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setNombre(''); // Reset the name field
      onCategoriaCreated(); // Call the function passed as prop
    })
    .catch(error => console.error(error));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField label="Nombre" value={nombre} onChange={e => setNombre(e.target.value)} />
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={handleSave}>Guardar</Button>
      </Grid>
    </Grid>
  );
}

export default NuevaCategoriaReceta;