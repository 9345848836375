import React, { useEffect, useState, useCallback, useRef, useMemo  } from 'react';
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  Box,
  Tooltip,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress,
  useTheme,
  useMediaQuery
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';

const Summary = ({ userData, handleInputChange, handleSubmit: onboardingHandleSubmit }) => {
  const [error, setError] = useState('');
  const [calculatedMacros, setCalculatedMacros] = useState(null);
  const [editableMacros, setEditableMacros] = useState(null);
  const [hasEdited, setHasEdited] = useState(false);
  const [allergens, setAllergens] = useState([]);
  const [ingredientCategories, setIngredientCategories] = useState([]);
  const [restrictions, setRestrictions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const navigate = useNavigate();

  const caloriasRef = useRef(null);
  const carbsRef = useRef(null);
  const proteinRef = useRef(null);
  const fatRef = useRef(null);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onboardingHandleSubmit();
      setIsSubmitting(false);
      setShowSuccessDialog(true);
      setTimeout(() => {
        setShowSuccessDialog(false);
        navigate('/dashboard');
      }, 1000);
    } catch (error) {
      console.error('Error al guardar el perfil:', error);
      setIsSubmitting(false);
      setError('Error al guardar el perfil. Por favor, inténtalo de nuevo.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [allergensRes, categoriesRes, restrictionsRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/allergens`),
          fetch(`${process.env.REACT_APP_API_URL}/ingredient-categories`),
          fetch(`${process.env.REACT_APP_API_URL}/restricciones`)
        ]);

        const allergensData = await allergensRes.json();
        const categoriesData = await categoriesRes.json();
        const restrictionsData = await restrictionsRes.json();

        setAllergens(allergensData);
        setIngredientCategories(categoriesData);
        setRestrictions(restrictionsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const calcularMacros = useCallback(() => {
    console.log("Calculando macros...");
    const calcularTMB = () => {
      const { sex, age, heightCM, weight } = userData;
      if (sex === 'Masculino') {
        return 10 * weight + 6.25 * heightCM - 5 * age + 5;
      } else {
        return 10 * weight + 6.25 * heightCM - 5 * age - 161;
      }
    };

    const calcularFactorActividad = (nivelActividad) => {
      const factores = {
        'Sedentario': 1.2,
        'Ligeramente activo': 1.375,
        'Moderadamente activo': 1.55,
        'Muy activo': 1.725,
        'Extremadamente activo': 1.9
      };
      return factores[nivelActividad] || 1.2;
    };

    const calcularCaloriasDiarias = (tmb, factorActividad, objetivo) => {
      let calorias = tmb * factorActividad;
      switch (objetivo) {
        case 'Perder peso':
          return calorias * 0.85;
        case 'Ganar masa muscular':
          return calorias * 1.15;
        default:
          return calorias;
      }
    };

    const calcularMacronutrientes = (calorias, objetivo) => {
      let proteinas, carbohidratos, grasas;
      switch (objetivo) {
        case 'Ganar masa muscular':
          proteinas = 0.3;
          grasas = 0.2;
          carbohidratos = 0.5;
          break;
        case 'Perder peso':
          proteinas = 0.4;
          grasas = 0.3;
          carbohidratos = 0.3;
          break;
        default:
          proteinas = 0.3;
          grasas = 0.3;
          carbohidratos = 0.4;
      }
      return {
        proteinas: Math.round((calorias * proteinas) / 4),
        carbohidratos: Math.round((calorias * carbohidratos) / 4),
        grasas: Math.round((calorias * grasas) / 9),
      };
    };

    const tmb = calcularTMB();
    const factorActividad = calcularFactorActividad(userData.activityLevel);
    const caloriasDiarias = calcularCaloriasDiarias(tmb, factorActividad, userData.goal);
    const macros = calcularMacronutrientes(caloriasDiarias, userData.goal);

    return {
      tmb: Math.round(tmb * 100) / 100,
      factorActividad,
      caloriasDiarias: Math.round(caloriasDiarias * 100) / 100,
      ...macros,
    };
  }, [userData]);

  useEffect(() => {
    if (userData.sex && userData.age && userData.heightCM && userData.weight && userData.activityLevel && userData.goal) {
      const macros = calcularMacros();
      setCalculatedMacros(macros);
      setEditableMacros(macros);
      handleInputChange('macros', {
        kilocalories: macros.caloriasDiarias,
        carbs: macros.carbohidratos,
        protein: macros.proteinas,
        fat: macros.grasas
      });
    }
  }, []);

  const handleMacroChange = useCallback((campo, valor) => {
    console.log(`handleMacroChange: Changing ${campo} to ${valor}`);
    const newValue = parseFloat(valor) || 0;
    setEditableMacros(prev => {
      const updated = {
        ...prev,
        [campo]: newValue,
      };
      // Actualizar el estado del componente padre
      handleInputChange('macros', {
        kilocalories: updated.caloriasDiarias,
        carbs: updated.carbohidratos,
        protein: updated.proteinas,
        fat: updated.grasas
      });
      return updated;
    });
    setHasEdited(true);
  }, [handleInputChange]);

  const handleBlur = useCallback(() => {
    if (hasEdited) {
      handleInputChange('macros', {
        kilocalories: editableMacros.caloriasDiarias,
        carbs: editableMacros.carbohidratos,
        protein: editableMacros.proteinas,
        fat: editableMacros.grasas
      });
      console.log("Updated user macros on blur", editableMacros);
      setHasEdited(false);
    }
  }, [editableMacros, hasEdited, handleInputChange]);

  const resetToOriginal = () => {
    console.log("Resetting to original macros");
    setEditableMacros(calculatedMacros);
    handleInputChange('macros', {
      kilocalories: calculatedMacros.caloriasDiarias,
      carbs: calculatedMacros.carbohidratos,
      protein: calculatedMacros.proteinas,
      fat: calculatedMacros.grasas
    });
    setHasEdited(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const InfoItem = ({ label, value }) => (
    <Box mb={1} display="flex" justifyContent="space-between">
      <Typography variant="body2" color="textSecondary">{label}:</Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );

  const MacroInput = useMemo(() => ({ label, value, onChange, inputRef }) => (
    <TextField
      label={label}
      type="number"
      value={value}
      onChange={onChange}
      fullWidth
      margin="normal"
      variant="outlined"
      inputRef={inputRef}
    />
  ), []);

  const PaperStyle = {
    p: 3,
    height: '100%',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  };

  const GridItemStyle = {
    display: 'flex',
    flexDirection: 'column',
  };

  const getNameById = (id, array) => {
    const item = array.find(item => item._id === id);
    return item ? item.name : 'Desconocido';
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!editableMacros) {
    return <Typography>Cargando resultados... Por favor, asegúrate de haber completado todos los campos necesarios.</Typography>;
  }
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        Resumen de tu perfil
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} sx={GridItemStyle}>
          <Paper elevation={3} sx={PaperStyle}>
            <Typography variant="h6" gutterBottom>Información Personal</Typography>
            <InfoItem label="Nombre" value={userData.name} />
            <InfoItem label="Edad" value={userData.age} />
            <InfoItem label="Género" value={userData.sex} />
            <InfoItem label="Altura" value={`${userData.heightCM} cm`} />
            <InfoItem label="Peso actual" value={`${userData.weight} kg`} />
            <InfoItem label="Fecha de nacimiento" value={formatDate(userData.birthDate)} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={GridItemStyle}>
          <Paper elevation={3} sx={PaperStyle}>
            <Typography variant="h6" gutterBottom>Objetivos y Actividad</Typography>
            <InfoItem label="Objetivo" value={userData.goal} />
            <InfoItem label="Nivel de actividad" value={userData.activityLevel} />
            <InfoItem label="Peso objetivo" value={`${userData.targetWeight} kg`} />
            <InfoItem label="IMC objetivo" value={userData.targetBMI} />
            <InfoItem label="% Grasa corporal objetivo" value={`${userData.targetBodyFat}%`} />
            <InfoItem label="Fecha objetivo" value={userData.targetDateString} />
            <InfoItem label="Frecuencia de pesaje" value={userData.weightingFrequency} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={GridItemStyle}>
          <Paper elevation={3} sx={PaperStyle}>
            <Typography variant="h6" gutterBottom>Preferencias Dietéticas</Typography>
            <InfoItem
              label="Restricciones dietéticas"
              value={userData.dietaryPreferences.map(id => getNameById(id, restrictions)).join(', ') || 'Ninguna'}
            />
            <InfoItem
              label="Alergias"
              value={userData.allergies.map(id => getNameById(id, allergens)).join(', ') || 'Ninguna'}
            />
            <InfoItem
              label="Alimentos no deseados"
              value={userData.dislikedFoods.map(id => getNameById(id, ingredientCategories)).join(', ') || 'Ninguno'}
            />
            <InfoItem label="Comidas por día" value={userData.mealsPerDay} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={GridItemStyle}>
          <Paper elevation={3} sx={PaperStyle}>
            <Typography variant="h6" gutterBottom>Experiencia Culinaria</Typography>
            <InfoItem label="Habilidad en la cocina" value={`${userData.cookingSkill}/5`} />
            <InfoItem label="Tiempo disponible para cocinar" value={userData.cookingTime} />
            <InfoItem label="Equipamiento de cocina" value={userData.kitchenEquipment.join(', ')} />
            <InfoItem label="Interés en nuevas recetas" value={userData.newRecipesInterest ? 'Sí' : 'No'} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ ...PaperStyle, mt: 3 }}>
            <Typography variant="h6" gutterBottom>Cálculos Nutricionales</Typography>
            <InfoItem label="Tu Tasa Metabólica Basal (TMB) es de" value={`${editableMacros.tmb} kcal/día`} />
            <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 2 }}>
              <Typography variant="body2">
                En base a tus objetivos, hemos calculado tus Macronutrientes Objetivo Diarios
              </Typography>
              <Tooltip title="Calculado según la fórmula de Harris-Benedict revisada por Mifflin y St Jeor">
                <InfoIcon sx={{ ml: 1, fontSize: 18 }} />
              </Tooltip>
            </Box>
            <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>Si quieres editarlos, puedes hacerlo</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <MacroInput
                  label="Calorías"
                  value={editableMacros.caloriasDiarias}
                  onChange={(e) => handleMacroChange('caloriasDiarias', e.target.value)}
                  inputRef={caloriasRef}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MacroInput
                  label="Carbohidratos (g)"
                  value={editableMacros.carbohidratos}
                  onChange={(e) => handleMacroChange('carbohidratos', e.target.value)}
                  inputRef={carbsRef}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MacroInput
                  label="Proteínas (g)"
                  value={editableMacros.proteinas}
                  onChange={(e) => handleMacroChange('proteinas', e.target.value)}
                  inputRef={proteinRef}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MacroInput
                  label="Grasas (g)"
                  value={editableMacros.grasas}
                  onChange={(e) => handleMacroChange('grasas', e.target.value)}
                  inputRef={fatRef}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={resetToOriginal}
                disabled={!hasEdited}
                fullWidth
              >
                Restablecer cálculos
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box mt={4} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || showSuccessDialog}
        >
          {isSubmitting ? 'Guardando...' : 'Finalizar y Guardar Perfil'}
        </Button>
      </Box>
      <Dialog 
        open={isSubmitting || showSuccessDialog} 
        onClose={() => {}}
      >
        <DialogContent>
          <DialogContentText>
            {isSubmitting ? 'Guardando...' : 'Perfil guardado con éxito. Redirigiendo...'}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Summary;
