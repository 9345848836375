import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import WeeklyMenuSummary from './Dashboard/WeeklyMenuSummary';
import NutritionChartWeek from './Dashboard/NutritionChartWeek';
import TrackingChart from './Charts/TrackingChart';
import TrackingForm from './Dashboard/TrackingForm';
import {
  useTheme, Container, Grid, Box, Typography, Paper, Button, Avatar, CircularProgress, Card, CardContent
} from '@mui/material';
import { RestaurantMenu, EventNote, ShoppingCart, ArrowDropDown, Info } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';



const Dashboard = () => {
  const { userRole, authToken } = useAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [menuData, setMenuData] = useState([]);
  const [recipeDetails, setRecipeDetails] = useState({});
  const [ingredientDetails, setIngredientDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(true);
  const theme = useTheme();

  const fetchDetails = useCallback(async (type, id) => {
    if ((type === 'recipes' && recipeDetails[id]) || (type === 'ingredients' && ingredientDetails[id])) {
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${type}/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (!response.ok) throw new Error(`Error fetching ${type}`);
      const data = await response.json();
      if (type === 'recipes') {
        setRecipeDetails(prev => ({ ...prev, [id]: data }));
      } else {
        setIngredientDetails(prev => ({ ...prev, [id]: data }));
      }
    } catch (error) {
      console.error(`Error fetching ${type}:`, error);
    }
  }, [authToken, recipeDetails, ingredientDetails]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (!response.ok) throw new Error('Error fetching user');
        const userData = await response.json();
        setUserId(userData.id);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (authToken) {
      fetchUser();
    }
  }, [authToken]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/profile`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (!response.ok) throw new Error('Error fetching user data');
        const userData = await response.json();
        setUserName(userData.name);
        console.log(userData.name);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (authToken) {
      fetchUser();
    }
  }, [authToken]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/userinfo/user/${userId}`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (!response.ok) throw new Error('Error fetching user info');
        const userInfoData = await response.json();
        setUserInfo(userInfoData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (userId) {
      fetchUserInfo();
    }
  }, [userId, authToken]);

  useEffect(() => {
    const fetchMenuData = async () => {
      const { start, end } = calculateWeekRange();
      const url = `${process.env.REACT_APP_API_URL}/menuschema?start=${start}&end=${end}`;
      try {
        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (!response.ok) throw new Error('Error fetching menu data');
        const menuData = await response.json();
        setMenuData(menuData);

        // Fetch recipe and ingredient details
        const recipeIds = new Set(menuData.flatMap(meal => meal.recipes));
        const ingredientIds = new Set(menuData.flatMap(meal => meal.ingredients.map(ing => ing.ingredient)));

        const recipePromises = Array.from(recipeIds).map(id => fetchDetails('recipes', id));
        const ingredientPromises = Array.from(ingredientIds).map(id => fetchDetails('ingredients', id));

        await Promise.all([...recipePromises, ...ingredientPromises]);
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    };

    if (authToken) {
      fetchMenuData();
    }
  }, [authToken, fetchDetails]);

  const calculateWeekRange = () => {
    const now = new Date();
    const firstDayOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 1)).toISOString().split('T')[0];
    const lastDayOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 7)).toISOString().split('T')[0];
    return { start: firstDayOfWeek, end: lastDayOfWeek };
  };

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/onboarding-status`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (!response.ok) throw new Error('Error fetching onboarding status');
        const data = await response.json();
        setHasCompletedOnboarding(data.hasCompletedOnboarding);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (authToken) {
      fetchOnboardingStatus();
    }
  }, [authToken]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Box>
          <Typography variant="h4" gutterBottom>
            ¡Hola {userName || ''}! <span role="img" aria-label="waving">👋</span>
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Vamos a mejorar tu salud a través de la nutrición.
          </Typography>
        </Box>
      </Box>
      {!hasCompletedOnboarding && (
        <Card sx={{ mb: 4, borderRadius: '15px', bgcolor: theme.palette.info.light }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <Info sx={{ mr: 2, color: theme.palette.info.main }} />
              <Typography variant="h6" color="white">
                Completa tu perfil
              </Typography>
            </Box>
            <Typography variant="body1" mt={1}>
              Para poder ofrecerte una experiencia personalizada y ayudarte mejor con tu nutrición,
              necesitamos algunos datos adicionales.
            </Typography>
            <Button
              component={RouterLink}
              to="/onboarding"
              variant="contained"
              color="info"
              sx={{ mt: 2, borderRadius: '50px', textTransform: 'none' }}
            >
              Completar perfil
            </Button>
          </CardContent>
        </Card>
      )}
      <Grid container spacing={2} justifyContent="center" sx={{ mb: 5 }}>
        <Grid item xs={12} sm="auto">
          <Button
            component={RouterLink}
            to="/recetas"
            variant="contained"
            sx={{
              backgroundColor: 'white', 
              color: 'black', 
              border: '1px solid black',
              width: 240,
              borderRadius: '50px',
              textTransform: 'none',
              fontSize: '18px',
              '&:hover': {
                backgroundColor: '#f0f0f0', 
                border: '1px solid black',
              },
            }}
          >
            <img src="https://nutrizia.app/uploads/icono-raciones-100.png" alt="Calendario" style={{ marginRight: '8px', width: '30px', height: '30px' }} />
            Explorar Recetas
          </Button>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            component={RouterLink}
            to="/menu-semanal"
            variant="contained"
            sx={{
              backgroundColor: 'white', 
              color: 'black', 
              border: '1px solid black',
              width: 240,
              borderRadius: '50px',
              textTransform: 'none',
              fontSize: '18px',
              '&:hover': {
                backgroundColor: '#f0f0f0', 
                border: '1px solid black',
              },
            }}
          >
            <img src="https://nutrizia.app/uploads/icono-calendario-100.png" alt="Calendario" style={{ marginRight: '8px', width: '30px', height: '30px' }} />
            Mi menú semanal
          </Button>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            component={RouterLink}
            to="/listas"
            variant="contained"
            sx={{
              backgroundColor: 'white', 
              color: 'black', 
              border: '1px solid black',
              width: 240,
              borderRadius: '50px',
              textTransform: 'none',
              fontSize: '18px',
              '&:hover': {
                backgroundColor: '#f0f0f0', 
                border: '1px solid black',
              },
            }}
          >
            <img src="https://nutrizia.app/uploads/icono-carrito-100.png" alt="Calendario" style={{ marginRight: '8px', width: '30px', height: '30px' }} />
            Listas de la compra
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: '30px' }}>
            <WeeklyMenuSummary
              menuData={menuData}
              userInfo={userInfo}
              recipeDetails={recipeDetails}
              ingredientDetails={ingredientDetails}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: '30px' }}>
            <NutritionChartWeek authToken={authToken} menuData={menuData} userInfo={userInfo} />
          </Paper>
        </Grid>
        {userInfo && userInfo.wantsTracking && (
        <>
          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: '30px' }}>
              <TrackingChart authToken={authToken} userInfo={userInfo} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: '30px' }}>
              <TrackingForm authToken={authToken} />
            </Paper>
          </Grid>
        </>
      )}
      </Grid>



      {userRole === 'admin' && (
        <Box my={2} p={2} component={Paper} elevation={3}>
          <Typography variant="h6" gutterBottom>Contenido exclusivo para administradores</Typography>
          <Typography variant="body1">Este contenido solo es visible para los administradores.</Typography>
        </Box>
      )}
    </Container>
  );
};

export default Dashboard;