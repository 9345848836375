import React, { useState, createContext, useContext, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken') || null);
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole') || '');
  const [isAuthenticated, setIsAuthenticated] = useState(!!authToken);

  // Función para iniciar sesión
  const login = (token, role) => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('userRole', role);
    setAuthToken(token);
    setUserRole(role);
    setIsAuthenticated(true); // Mover aquí
    console.log("AuthContext.js token: ", token);
  };

  // Función para cerrar sesión
  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRole');
    setAuthToken(null);
    setUserRole(''); // Mover aquí
    setIsAuthenticated(false); // Mover aquí
  };

  return (
    <AuthContext.Provider value={{ authToken, isAuthenticated, userRole, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
