import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  IconButton, 
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  CircularProgress,
  Button
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const MEALS = ['Desayuno', 'Media mañana', 'Comida', 'Media tarde', 'Cena'];

const AddToMenuPopup = ({ open, onClose, recipeId, recipeName, authToken }) => {
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [menuData, setMenuData] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (open) {
      fetchMenuData(currentWeek);
    }
  }, [currentWeek, open, authToken]);

  const fetchMenuData = async (week) => {
    setLoading(true);
    const startDate = new Date(week);
    startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    const startDateString = startDate.toISOString().split('T')[0];
    const endDateString = endDate.toISOString().split('T')[0];

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/menuschema?start=${startDateString}&end=${endDateString}`, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const formattedData = {};
      data.forEach(item => {
        const date = new Date(item.date).toISOString().split('T')[0];
        if (!formattedData[date]) {
          formattedData[date] = {};
        }
        formattedData[date][item.meal] = {
          recipes: item.recipes,
          ingredients: item.ingredients
        };
      });
      setMenuData(formattedData);
    } catch (error) {
      console.error('Error fetching menu data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRecipe = async (date, meal) => {
    setSelectedDate(date);
    setSelectedMeal(meal);
    setConfirmDialogOpen(true);
  };

  const confirmAddRecipe = async () => {
    try {
      const existingMeal = menuData[selectedDate] && menuData[selectedDate][selectedMeal];
      const updatedRecipes = existingMeal ? [...existingMeal.recipes, recipeId] : [recipeId];
      const updatedIngredients = existingMeal ? existingMeal.ingredients : [];

      const response = await fetch(`${process.env.REACT_APP_API_URL}/menuschema`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          date: selectedDate,
          meal: selectedMeal,
          recipes: updatedRecipes,
          ingredients: updatedIngredients
        }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Network response was not ok: ${JSON.stringify(errorData)}`);
      }
      fetchMenuData(currentWeek);
      setConfirmDialogOpen(false);
      onClose(); // Cerrar el popup principal
    } catch (error) {
      console.error('Error adding recipe to menu:', error);
    }
  };


  const renderWeekView = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(currentWeek);
      day.setDate(day.getDate() - day.getDay() + i + 1);
      days.push(day);
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Comida</TableCell>
              {days.map((day) => (
                <TableCell key={day.toISOString()}>
                  {day.toLocaleDateString('es-ES', { weekday: 'short' })}
                  <br />
                  {day.toLocaleDateString('es-ES', { day: 'numeric', month: 'numeric' })}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {MEALS.map((meal) => (
              <TableRow key={meal}>
                <TableCell>{meal}</TableCell>
                {days.map((day) => {
                  const dateStr = day.toISOString().split('T')[0];
                  const hasContent = menuData[dateStr] && menuData[dateStr][meal] && 
                    (menuData[dateStr][meal].recipes.length > 0 || menuData[dateStr][meal].ingredients.length > 0);
                  return (
                    <TableCell 
                      key={`${dateStr}-${meal}`} 
                      style={{ backgroundColor: hasContent ? theme.palette.action.hover : 'inherit' }}
                    >
                      <IconButton onClick={() => handleAddRecipe(dateStr, meal)}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderDayView = () => {
    const currentDay = currentWeek.toISOString().split('T')[0];
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Comida</TableCell>
              <TableCell>{currentWeek.toLocaleDateString('es-ES', { weekday: 'long', day: 'numeric', month: 'long' })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {MEALS.map((meal) => {
              const hasContent = menuData[currentDay] && menuData[currentDay][meal] && 
                (menuData[currentDay][meal].recipes.length > 0 || menuData[currentDay][meal].ingredients.length > 0);
              return (
                <TableRow key={meal}>
                  <TableCell>{meal}</TableCell>
                  <TableCell style={{ backgroundColor: hasContent ? theme.palette.action.hover : 'inherit' }}>
                    <IconButton onClick={() => handleAddRecipe(currentDay, meal)}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handlePreviousWeek = () => {
    const newWeek = new Date(currentWeek);
    newWeek.setDate(newWeek.getDate() - (isMobile ? 1 : 7));
    setCurrentWeek(newWeek);
  };

  const handleNextWeek = () => {
    const newWeek = new Date(currentWeek);
    newWeek.setDate(newWeek.getDate() + (isMobile ? 1 : 7));
    setCurrentWeek(newWeek);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Añadir "{recipeName}" al menú
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <IconButton onClick={handlePreviousWeek}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <Typography variant="h6">
              {isMobile ? 'Seleccionar día' : 'Seleccionar semana'}
            </Typography>
            <IconButton onClick={handleNextWeek}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2">
              Las celdas sombreadas indican que ya hay contenido en esa comida.
            </Typography>
          </Box>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <CircularProgress />
            </Box>
          ) : (
            isMobile ? renderDayView() : renderWeekView()
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Confirmar añadir receta</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Quieres añadir esta receta al {formatDate(selectedDate)} para {selectedMeal}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancelar</Button>
          <Button onClick={confirmAddRecipe} variant="contained" color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddToMenuPopup;