import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControlLabel,
  Switch,
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const TrackingGoals = ({ userData, handleInputChange, allMetrics = [] }) => {
  const [wantsTracking, setWantsTracking] = useState(userData.wantsTracking);
  const [metrics, setMetrics] = useState([]);
  const [newMetricName, setNewMetricName] = useState('');
  const [newMetricUnit, setNewMetricUnit] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMetrics();
  }, []);

  const fetchMetrics = async () => {
    try {
      setLoading(true);
      const [userMetricsResponse, defaultMetricsResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_URL}/metrics/user-metrics`, {
          headers: { Authorization: `Bearer ${userData.authToken}` },
        }),
        fetch(`${process.env.REACT_APP_API_URL}/metrics/metric-types`, {
          headers: { Authorization: `Bearer ${userData.authToken}` },
        })
      ]);
  
      const userMetrics = await userMetricsResponse.json();
      const defaultMetrics = await defaultMetricsResponse.json();

      console.log('userMetrics:', userMetrics);
      console.log('defaultMetrics:', defaultMetrics);

      // Filtrar métricas predeterminadas y creadas por el usuario actual
      const filteredMetrics = defaultMetrics.filter(metric => 
        metric.isDefault || metric.createdBy === userData.userId
      ).map(metric => ({
        ...metric,
        isTracking: false
      }));

      console.log('filteredMetrics:', filteredMetrics);

      // Comparar con user-metrics y actualizar isTracking
      const combinedMetrics = filteredMetrics.map(metric => {
        const userMetric = userMetrics.find(um => um.metricType._id === metric._id);
        return {
          ...metric,
          isTracking: userMetric ? userMetric.isTracking : false
        };
      });

      // Añadir métricas personalizadas que están en userMetrics
      userMetrics.forEach(userMetric => {
        if (!filteredMetrics.find(metric => metric._id === userMetric.metricType._id) && userMetric.isTracking) {
          combinedMetrics.push({
            ...userMetric.metricType,
            isTracking: userMetric.isTracking
          });
        }
      });

      console.log('combinedMetrics:', combinedMetrics);

      setMetrics(combinedMetrics);
      setWantsTracking(userData.wantsTracking);
      handleInputChange('wantsTracking', userData.wantsTracking);
      handleInputChange('allMetrics', combinedMetrics);
      updateGlobalMetrics(combinedMetrics);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateGlobalMetrics = (currentMetrics) => {
    const trackedMetrics = currentMetrics
      .filter(metric => metric.isTracking)
      .map(metric => metric._id);

    const customMetrics = currentMetrics
      .filter(metric => !metric.isDefault && metric.createdBy === userData.userId && metric.isTracking)
      .map(metric => ({
        id: metric._id,
        name: metric.name,
        unit: metric.unit,
        isTracking: metric.isTracking
      }));

    console.log('trackedMetrics:', trackedMetrics);
    console.log('customMetrics:', customMetrics);

    handleInputChange('trackedMetrics', trackedMetrics);
    handleInputChange('customMetrics', customMetrics);
  };

  const handleWantsTrackingChange = (event) => {
    setWantsTracking(event.target.checked);
    handleInputChange('wantsTracking', event.target.checked);
    if (!event.target.checked) {
      // If tracking is disabled, remove all metrics
      const updatedMetrics = metrics.map(metric => ({ ...metric, isTracking: false }));
      setMetrics(updatedMetrics);
      updateGlobalMetrics(updatedMetrics);
    }
  };

  const handleMetricToggle = async (metricId) => {
    const updatedMetrics = metrics.map(metric => 
      metric._id === metricId 
        ? { ...metric, isTracking: !metric.isTracking } 
        : metric
    );
    console.log('updatedMetrics:', updatedMetrics);
    setMetrics(updatedMetrics);
    updateGlobalMetrics(updatedMetrics);
  };

  const handleAddCustomMetric = () => {
    if (newMetricName && newMetricUnit) {
      const newCustomMetric = { 
        _id: `temp_${Date.now()}`,
        name: newMetricName, 
        unit: newMetricUnit,
        isDefault: false,
        isTracking: true,
        createdBy: userData.userId
      };
      const updatedMetrics = [...metrics, newCustomMetric];
      console.log('newCustomMetric:', newCustomMetric);
      console.log('updatedMetrics:', updatedMetrics);
      setMetrics(updatedMetrics);
      updateGlobalMetrics(updatedMetrics);
      setNewMetricName('');
      setNewMetricUnit('');
    }
  };

  const handleRemoveCustomMetric = async (metricToRemove) => {
    const updatedMetrics = metrics.filter(metric => 
      metric._id !== metricToRemove._id
    );
    console.log('updatedMetrics:', updatedMetrics);
    setMetrics(updatedMetrics);
    updateGlobalMetrics(updatedMetrics);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={wantsTracking}
            onChange={handleWantsTrackingChange}
          />
        }
        label="¿Quieres hacer seguimiento de tus métricas?"
      />

      {wantsTracking && (
        <>
          <Typography variant="h6" gutterBottom>
            Métricas disponibles
          </Typography>
          <List>
            {metrics.map((metric, index) => {
              if (!metric) {
                console.error(`Metric at index ${index} is invalid:`, metric);
                return null;
              }
              return (
                <ListItem key={metric._id}>
                  <ListItemText 
                    primary={metric.name} 
                    secondary={`Unidad: ${metric.unit}`} 
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={() => handleMetricToggle(metric._id)}
                      checked={metric.isTracking}
                    />
                    {!metric.isDefault && (
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveCustomMetric(metric)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>

          <Typography variant="h6" gutterBottom>
            Añadir métrica personalizada
          </Typography>
          <TextField
            label="Nombre de la métrica"
            value={newMetricName}
            onChange={(e) => setNewMetricName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Unidad de medida"
            value={newMetricUnit}
            onChange={(e) => setNewMetricUnit(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddCustomMetric}
            startIcon={<AddIcon />}
          >
            Añadir métrica personalizada
          </Button>
        </>
      )}
    </Box>
  );
};

export default TrackingGoals;