// NuevotipoDeCocina.js
import React, { useState } from 'react';
import { Grid, TextField, Button } from '@mui/material';

function NuevotipoDeCocina({ onTipoDeCocinaCreated }) {
  const [name, setName] = useState('');

  const handleSave = () => {
    fetch(`${process.env.REACT_APP_API_URL}/tipoDeCocina`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setName(''); 
      onTipoDeCocinaCreated(); // Call the function passed as prop
    })
    .catch(error => console.error(error));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField label="Nombre" value={name} onChange={e => setName(e.target.value)} />
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={handleSave}>Guardar</Button>
      </Grid>
    </Grid>
  );
}

export default NuevotipoDeCocina;