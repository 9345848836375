import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom'; // Importa useHistory
import { useAuth } from '../context/AuthContext';

function EditarCategoriaReceta() {
  const { id } = useParams();
  const [categoria, setCategoria] = useState(null);
  const [nombre, setNombre] = useState('');
  const { token } = useAuth();
  const navigate = useNavigate(); // Crea una instancia de useNavigate

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/categoriaRecetas/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setCategoria(data);
        setNombre(data.nombre);
      })
      .catch(error => console.error(error));
  }, [id, token]);

  const handleSave = () => {
    if (categoria) {
      fetch(`${process.env.REACT_APP_API_URL}/categoriaRecetas/${categoria._id}`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ nombre })
      })
      .then(response => {
        if (response.ok) {
          navigate('/gestion-taxonomias'); // Redirige al usuario
        } else {
          return response.json();
        }
      })
      .then(data => console.log(data))
      .catch(error => console.error(error));
    }
  };

  if (!categoria) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField label="Nombre" value={nombre} onChange={e => setNombre(e.target.value)} />
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={handleSave}>Guardar</Button>
      </Grid>
    </Grid>
  );
}

export default EditarCategoriaReceta;