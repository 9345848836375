import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import RecipeCard from './RecipeCard';
import './ExplorarRecetas.css';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import {
  Box, Button, Autocomplete, Grid, TextField, Typography, MenuItem, TableContainer, Table, Paper,
  TableHead, TableRow, TableCell, TableBody, ToggleButtonGroup, ToggleButton, Container, Chip,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Fab
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function fetchRecipes() {
  return fetch(`${process.env.REACT_APP_API_URL}/recipes`).then(response => response.json());
}

function fetchTypes() {
  return fetch(`${process.env.REACT_APP_API_URL}/tipoDeCocina`).then(response => response.json());
}

function fetchRestrictions() {
  return fetch(`${process.env.REACT_APP_API_URL}/restricciones`).then(response => response.json());
}

function fetchIngredients() {
  return fetch(`${process.env.REACT_APP_API_URL}/ingredients`).then(response => response.json());
}

function fetchRecipeCategories() {
  return fetch(`${process.env.REACT_APP_API_URL}/categoriaRecetas`).then(response => response.json());
}

function ExplorarRecetas() {
  const [searchTerm, setSearchTerm] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [types, setTypes] = useState([]);
  const [restrictions, setRestrictions] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [selectedRestriction, setSelectedRestriction] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const theme = useTheme(); 
  const [selectedDifficulty, setSelectedDifficulty] = useState('');
  const [viewMode, setViewMode] = useState('card');
  const [recipeCategories, setRecipeCategories] = useState([]);
  const [selectedRecipeCategory, setSelectedRecipeCategory] = useState('');
  const [selectedTipoPlato, setSelectedTipoPlato] = useState('');
  const [filtersOpen, setFiltersOpen] = useState(false); 

  useEffect(() => {
    console.log("selectedcategory: ", selectedRecipeCategory);
  }, [selectedRecipeCategory]);

  const customFilterOption = (option, searchText) => {
    return option.data.label.toLowerCase().includes(searchText.toLowerCase());
  };

  useEffect(() => {
    fetchRecipes().then(data => setRecipes(data));
    fetchTypes().then(data => setTypes(data));
    fetchRestrictions().then(data => setRestrictions(data));
    fetchIngredients().then(data => setIngredients(data));
    fetchRecipeCategories().then(data => setRecipeCategories(data));
  }, []);

  const ingredientOptions = ingredients.map(ingredient => ({
    value: ingredient._id,
    label: ingredient.name
  }));

  const filteredRecipes = recipes.filter(recipe => {
    const matchesType = selectedType === '' || (Array.isArray(recipe.tipoDeCocina) && recipe.tipoDeCocina.some(r => r === selectedType));
    const matchesRestriction = selectedRestriction === '' || (recipe.restricciones && recipe.restricciones.some(r => r === selectedRestriction));
    const matchesDifficulty = selectedDifficulty === '' || recipe.difficulty === selectedDifficulty;
    const matchesSearchTerm = recipe.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesIngredients = selectedIngredients.length === 0 || (recipe.ingredients && selectedIngredients.every(selectedIngredient =>
      recipe.ingredients.some(ingredient => ingredient.ingredientId === selectedIngredient)
    ));
    const matchesRecipeCategory = selectedRecipeCategory === '' || (recipe.categoriaRecetas && recipe.categoriaRecetas.includes(selectedRecipeCategory));
    const matchesTipoPlato = selectedTipoPlato === '' || (recipe.tipoPlato && recipe.tipoPlato.includes(selectedTipoPlato)); 
    return matchesType && matchesRestriction && matchesDifficulty && matchesSearchTerm && matchesIngredients && matchesRecipeCategory && matchesTipoPlato;
  });

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 4, mb: 2 }}>
        Explorar Recetas
      </Typography>

      <Box display="flex" justifyContent="space-between" alignItems="center" my={3}>
        <TextField
          fullWidth
          label="Buscar recetas..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mr: 2 }}
        />
        <Button
          variant="contained"
          startIcon={<FilterListIcon />}
          onClick={() => setFiltersOpen(true)}
          sx={{ bgcolor: 'primary.main', color: '#fff' }}
        >
          Filtros
        </Button>
      </Box>

      <Box display="flex" justifyContent="flex-end" my={3}>
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={(event, newViewMode) => {
            if (newViewMode !== null) {
              setViewMode(newViewMode);
            }
          }}
          aria-label="view mode"
        >
          <ToggleButton value="card" aria-label="card">
            <ViewModuleIcon />
          </ToggleButton>
          <ToggleButton value="list" aria-label="list">
            <ViewListIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Dialog open={filtersOpen} onClose={() => setFiltersOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          Filtrar recetas
          <IconButton
            aria-label="close"
            onClick={() => setFiltersOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                label="Categorías"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                variant="outlined"
                fullWidth
              >
                <MenuItem value=""><em>Ninguno</em></MenuItem>
                {types.map((type) => (
                  <MenuItem key={type._id} value={type._id}>
                    {type.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                label="Preferencias"
                value={selectedRestriction}
                onChange={(e) => setSelectedRestriction(e.target.value)}
                variant="outlined"
                fullWidth
              >
                <MenuItem value=""><em>Ninguno</em></MenuItem>
                {restrictions.map((restriction) => (
                  <MenuItem key={restriction._id} value={restriction._id}>
                    {restriction.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={ingredientOptions}
                getOptionLabel={(option) => option.label}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  setSelectedIngredients(newValue.map((option) => option.value));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Selecciona ingredientes"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                label="Dificultad"
                value={selectedDifficulty}
                onChange={(e) => setSelectedDifficulty(e.target.value)}
                variant="outlined"
                fullWidth
              >
                <MenuItem value=""><em>Ninguno</em></MenuItem>
                <MenuItem value="Baja">Fácil</MenuItem>
                <MenuItem value="Media">Media</MenuItem>
                <MenuItem value="Alta">Difícil</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                multiple
                id="recipe-categories-outlined"
                options={recipeCategories}
                getOptionLabel={(option) => option.nombre}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  setSelectedRecipeCategory(newValue.map((option) => option._id));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Categorías de recetas"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                label="Tipo de plato"
                value={selectedTipoPlato}
                onChange={(e) => setSelectedTipoPlato(e.target.value)}
                variant="outlined"
                fullWidth
              >
                <MenuItem value=""><em>Ninguno</em></MenuItem>
                <MenuItem value="Desayuno">Desayuno</MenuItem>
                <MenuItem value="Snack">Snack</MenuItem>
                <MenuItem value="Entrante">Entrante</MenuItem>
                <MenuItem value="Principal">Principal</MenuItem>
                <MenuItem value="Postre">Postre</MenuItem>
                <MenuItem value="Guarnición">Guarnición</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFiltersOpen(false)} color="primary">
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>

      <Fab
        component={Link}
        to="/recetas/crear"
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <AddIcon />
      </Fab>

      {filteredRecipes.length > 0 ? (
        viewMode === 'card' ? (
          <Grid container spacing={3}>
            {filteredRecipes.map((recipe) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={recipe.id}>
                <RecipeCard
                  recipe={recipe}
                  types={types}
                  restrictions={restrictions}
                  recipeCategories={recipeCategories}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Dificultad</TableCell>
                  <TableCell>Tiempo</TableCell>
                  <TableCell>Categorías</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRecipes.map((recipe) => (
                  <TableRow key={recipe.id}>
                    <TableCell>{recipe.name}</TableCell>
                    <TableCell>{recipe.difficulty}</TableCell>
                    <TableCell>
                      {recipe.prepTime ? (
                        `${recipe.prepTime.hours ? `${recipe.prepTime.hours}h ` : ''}${recipe.prepTime.minutes ? `${recipe.prepTime.minutes}min` : ''}`
                      ) : 'N/A'}
                    </TableCell>                    
                    <TableCell>
                      {recipe.categoriaRecetas && recipe.categoriaRecetas.map((categoryId, index) => {
                        const category = recipeCategories.find(category => category._id === categoryId);
                        return <Chip key={index} label={category ? category.nombre : 'Desconocido'} size="small" sx={{ mr: 0.5, mb: 0.5 }} />;
                      })}
                      {recipe.tipoDeCocina && recipe.tipoDeCocina.map((tipoId, index) => {
                        const tipo = types.find(tipo => tipo._id === tipoId);
                        return <Chip key={index} label={tipo ? tipo.name : 'Desconocido'} size="small" sx={{ mr: 0.5, mb: 0.5 }} />;
                      })}
                      {recipe.restricciones && recipe.restricciones.map((restriccionId, index) => {
                        const restriccion = restrictions.find(restriccion => restriccion._id === restriccionId);
                        return <Chip key={index} label={restriccion ? restriccion.name : 'Desconocido'} size="small" sx={{ mr: 0.5, mb: 0.5 }} />;
                      })}
                      {recipe.tipoPlato && recipe.tipoPlato.map((tipo, index) => (
                        <Chip key={index} label={tipo} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
                      ))}
                    </TableCell>
                    <TableCell>
                      <Button component={Link} to={`/recetas/${recipe._id}`} variant="outlined" size="small" sx={{ mr: 1 }}>
                        Ver
                      </Button>
                      <Button component={Link} to={`/editar-receta/${recipe._id}`} variant="outlined" size="small">
                        Editar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      ) : (
        <Typography variant="body1" sx={{ mt: 3, textAlign: 'center' }}>
          No se encontraron recetas que coincidan con los filtros seleccionados.
        </Typography>
      )}
    </Container>
  );
}

export default ExplorarRecetas;
