import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, Grid, Box, InputLabel, FormControl, Typography, Autocomplete, Checkbox, FormControlLabel, Dialog, DialogContent, DialogContentText } from '@mui/material';import { useAuth } from '../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

function NuevoIngrediente() {
    const { isAuthenticated, login, logout, authToken, userRole } = useAuth();
    const [ingredient, setIngredient] = useState({
        name: '',
        kilocalories: '',
        carbs: '',
        protein: '',
        fat: '',
        categoria: '',
        subcategoria: '',
        standardUnit: '',
        gramsPerUnit: '',
        ingredientCategories: [],
        allergens: [],
        revisado: false,
    });
    const [user, setUser] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    const [userId, setUserId] = useState(null);
    const [categorias, setCategorias] = useState([]);
    const [ingredientCategories, setIngredientCategories] = useState([]);
    const [allergens, setAllergens] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);

    useEffect(() => {
        if (authToken) {
            fetch(`${process.env.REACT_APP_API_URL}/user/profile`, {
                headers: { Authorization: `Bearer ${authToken}` },
            })
                .then(response => response.json())
                .then(setUser)
                .catch(console.error);

            fetch(`${process.env.REACT_APP_API_URL}/user`, {
                headers: { Authorization: `Bearer ${authToken}` },
            })
                .then(response => response.json())
                .then(data => setUserId(data.id))
                .catch(console.error);
        }
    }, [authToken]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/categorias`)
            .then(response => response.json())
            .then(setCategorias);

        fetch(`${process.env.REACT_APP_API_URL}/ingredient-categories`)
            .then(response => response.json())
            .then(setIngredientCategories);

        fetch(`${process.env.REACT_APP_API_URL}/allergens`)
            .then(response => response.json())
            .then(setAllergens);
    }, []);

    const createIngredient = (ingredient) => {
        const categoriaId = categorias.find(categoria => categoria.nombre === ingredient.categoria)?._id;
        const body = JSON.stringify({ ...ingredient, createdBy: userId, categoria: categoriaId });
        return fetch(`${process.env.REACT_APP_API_URL}/ingredients`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body,
        }).then(response => response.json());
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        createIngredient(ingredient).then(newIngredient => {
            setIngredient(newIngredient);
            setOpenPopup(true);
            setTimeout(() => {
                setOpenPopup(false);
                navigate(location.state?.from || '/gestion-ingredientes');
            }, 2000);
        });
    };

    const handleIngredientCategoriesChange = (event, values) => {
        setIngredient({ ...ingredient, ingredientCategories: values.map(v => v._id) });
    };

    const handleAllergensChange = (event, values) => {
        setIngredient({ ...ingredient, allergens: values.map(v => v._id) });
    };

    const isFormValid = Object.values(ingredient).every((value) => value !== '');

    return (
        <Box display="flex" justifyContent="center">
            <form onSubmit={handleSubmit}>
                <Typography variant="h1" align="center" gutterBottom>
                    Crea un nuevo ingrediente
                </Typography>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h6">Información básica</Typography>
                        <TextField
                            label="Nombre"
                            value={ingredient.name}
                            onChange={(event) => setIngredient({ ...ingredient, name: event.target.value })}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Información nutricional</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Kilocalorías"
                                value={ingredient.kilocalories}
                                onChange={(event) => setIngredient({ ...ingredient, kilocalories: event.target.value.replace(',', '.') })}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Carbohidratos"
                                value={ingredient.carbs}
                                onChange={(event) => setIngredient({ ...ingredient, carbs: event.target.value.replace(',', '.') })}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Proteínas"
                                value={ingredient.protein}
                                onChange={(event) => setIngredient({ ...ingredient, protein: event.target.value.replace(',', '.') })}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Grasas"
                                value={ingredient.fat}
                                onChange={(event) => setIngredient({ ...ingredient, fat: event.target.value.replace(',', '.') })}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Información de medidas</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="standardUnit-label">Unidad estándar</InputLabel>
                                <Select
                                    labelId="standardUnit-label"
                                    value={ingredient.standardUnit}
                                    onChange={(event) => setIngredient({ ...ingredient, standardUnit: event.target.value })}
                                >
                                    <MenuItem value="gramos">Gramos</MenuItem>
                                    <MenuItem value="uds">Unidades</MenuItem>
                                    <MenuItem value="cucharadas">Cucharadas</MenuItem>
                                    <MenuItem value="cucharaditas">Cucharaditas</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Gramos por unidad"
                                value={ingredient.gramsPerUnit}
                                onChange={(event) => setIngredient({ ...ingredient, gramsPerUnit: event.target.value.replace(',', '.') })}
                                fullWidth
                                helperText={`¿Cuánto pesa aproximadamente una unidad de ${ingredient.name}?`}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Categorías de supermercado</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="categoria-label">Categoría de supermercado</InputLabel>
                                <Select
                                    labelId="categoria-label"
                                    value={ingredient.categoria}
                                    onChange={(event) => setIngredient({ ...ingredient, categoria: event.target.value })}
                                >
                                    {categorias.map((categoria) => (
                                        <MenuItem key={categoria._id} value={categoria.nombre}>{categoria.nombre}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="subcategoria-label">Subcategoría de supermercado</InputLabel>
                                <Select
                                    labelId="subcategoria-label"
                                    value={ingredient.subcategoria}
                                    onChange={(event) => setIngredient({ ...ingredient, subcategoria: event.target.value })}
                                >
                                    {categorias.find(categoria => categoria.nombre === ingredient.categoria)?.subcategorias.map((subcategoria) => (
                                        <MenuItem key={subcategoria} value={subcategoria}>{subcategoria}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            multiple
                            options={ingredientCategories}
                            getOptionLabel={(option) => option.name}
                            onChange={handleIngredientCategoriesChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Categorías de Ingredientes" variant="outlined" fullWidth />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            multiple
                            options={allergens}
                            getOptionLabel={(option) => option.name}
                            onChange={handleAllergensChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Alérgenos" variant="outlined" fullWidth />
                            )}
                        />
                    </Grid>
                    {userRole === 'admin' && (
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={ingredient.revisado}
                                        onChange={(event) => setIngredient({ ...ingredient, revisado: event.target.checked })}
                                    />
                                }
                                label="Revisado"
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <Button type="submit" sx={{ bgcolor: 'primary.main', color: '#fff' }} disabled={!isFormValid}>Crear</Button>
                    </Grid>
                </Grid>
            </form>
            <Dialog open={openPopup} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ingrediente creado con éxito, redirigiendo...
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default NuevoIngrediente;
