import React, { useState, useEffect } from 'react';
import { Container, Button, List, ListItem, ListItemText, TextField, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../context/AuthContext';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const WeightTrackingComponent = ({ userId }) => {
  const [trackings, setTrackings] = useState([]);
  const [newTracking, setNewTracking] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { authToken } = useAuth();

  useEffect(() => {
    fetchTrackings();
  }, []);

  const fetchTrackings = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tracking/weight`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const data = await response.json();
      console.log(data); // Log the data to check its structure
      setTrackings(Array.isArray(data) ? data : []); // Ensure data is an array
    } catch (error) {
      console.error('Error fetching trackings:', error);
      setTrackings([]); // Set to empty array on error
    }
  };

  const addTracking = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tracking/weight`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ user: userId, weight: newTracking, date: selectedDate.toISOString() }),
      });
      const newEntry = await response.json();
      setTrackings([...trackings, newEntry]);
      setNewTracking('');
    } catch (error) {
      console.error('Error adding tracking:', error);
    }
  };

  const deleteTracking = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/tracking/weight/${id}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setTrackings(trackings.filter((tracking) => tracking._id !== id));
    } catch (error) {
      console.error('Error deleting tracking:', error);
    }
  };

  return (
    <Container>
      <h2>Weight Tracking</h2>
      <List>
        {trackings.map((tracking) => (
          <ListItem key={tracking._id}>
            <ListItemText primary={`Weight: ${tracking.weight.$numberDecimal} kg on ${new Date(tracking.date).toLocaleDateString()}`} />
            <IconButton edge="end" onClick={() => deleteTracking(tracking._id)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <TextField
        label="New Weight"
        value={newTracking}
        onChange={(e) => setNewTracking(e.target.value)}
        type="number"
        inputProps={{ step: "0.1" }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Date"
          value={selectedDate}
          onChange={(newDate) => setSelectedDate(newDate)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Button onClick={addTracking}>Add</Button>
    </Container>
  );
};

export default WeightTrackingComponent;
