import logo from './logo.svg';
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';

import Header from './components/Header/Header';
import { AuthProvider } from './context/AuthContext';

import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute'; // Asegúrate de importar PrivateRoute
import HomePage from './components/HomePage'; // Ejemplo de página de inicio
import Dashboard from './components/Dashboard'; // Ejemplo de página protegida
import ExplorarRecetas from './components/ExplorarRecetas'; // Importa el nuevo componente
import RecetaIndividual from './components/RecetaIndividual';
import MenuSemanal from './components/MenuSemanal';
import ListaCompra from './components/ListaCompra';
import Register from './components/Register';
import UserInfo from './components/UserInfo';
import UserProfile from './components/UserProfile';
import NuevaReceta from './components/NuevaReceta';
import MisListas from './components/MisListas';
import MisListasSingle from './components/MisListasSingle';
import GestionRecetas from './components/GestionRecetas';
import EditarReceta from './components/EditarReceta';
import GestionIngredientes from './components/GestionIngredientes';
import NuevoIngrediente from './components/NuevoIngrediente';
import EditarIngrediente from './components/EditarIngrediente';
import GestionarTaxonomias from './components/GestionarTaxonomias';
import EditarCategoriaReceta from './components/EditarCategoriaReceta';
import NuevaCategoriaReceta from './components/Crear/NuevaCategoriaReceta';
import NuevoTipoDeCocina from './components/Crear/NuevoTipoDeCocina';
import EditarTipoDeCocina from './components/EditarTipoDeCocina';
import EditarRestriccion from './components/EditarRestriccion';
import NuevaRestriccion from './components/Crear/NuevaRestriccion';
import GestionUsuarios from './components/GestionUsuarios';
import EditarUsuario from './components/EditarUsuario';
import Onboarding from './components/Onboarding/Onboarding'; 
import EditarAlergeno from './components/Editar/EditarAlergeno';
import NuevoAlergeno from './components/Crear/NuevoAlergeno';
import EditarCategoriaIngrediente from './components/Editar/EditarCategoriaIngrediente';
import NuevaCategoriaIngrediente from './components/Crear/NuevoCategoriaIngrediente';
import GestionWeights from './components/Gestion/GestionWeights';
import GestionBMI from './components/Gestion/GestionBMI';
import GestionBodyFat from './components/Gestion/GestionBodyFat';
import ConfirmEmail from './components/Auth/ConfirmEmail'; // Importa el componente ConfirmEmail
import AdminRoute from './components/AdminRoute'; // Importa el componente AdminRoute
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<HomePage />} exact />
            <Route path="/dashboard" element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } />
            <Route path="/recetas" element={<ExplorarRecetas />} />
            <Route path="/recetas/:id" element={<RecetaIndividual />} />
            <Route path="/editar-receta/:id" element={<EditarReceta />} />
            <Route path="/menu-semanal" element={<MenuSemanal />} exact />
            <Route path="/listas/crear-lista" element={<ListaCompra />} exact />
            <Route path="/registro" element={<Register />} exact />
            <Route path="/perfil/mis-datos" element={<UserInfo />} exact />
            <Route path="/perfil/" element={<UserProfile />} exact />
            <Route path="/recetas/crear" element={<NuevaReceta />} exact />
            <Route path="/listas" element={<MisListas />} exact />
            <Route path="/listas/:id" element={<MisListasSingle />} exact />
            <Route path="/gestion-recetas" element={<GestionRecetas />} />
            <Route path="/gestion-ingredientes" element={<GestionIngredientes />} />
            <Route path="/crear-ingrediente" element={<NuevoIngrediente />} />
            <Route path="/editar-ingrediente/:id" element={<EditarIngrediente />} />
            <Route path="/gestion-taxonomias" element={<GestionarTaxonomias />} />
            <Route path="/editar-categoria-receta/:id" element={<EditarCategoriaReceta />} />
            <Route path="/nueva-categoria-receta" element={<NuevaCategoriaReceta />} />
            <Route path="/nuevo-tipo-cocina" element={<NuevoTipoDeCocina />} />
            <Route path="/editar-tipo-de-cocina/:id" element={<EditarTipoDeCocina />} />
            <Route path="/editar-restriccion/:id" element={<EditarRestriccion />} />
            <Route path="/nueva-restriccion" element={<NuevaRestriccion />} />
            <Route path="/gestion-ingredientes" element={<GestionIngredientes />} />
            <Route path="/gestion-usuarios" element={<GestionUsuarios />} />
            <Route path="/editar-usuario/:id" element={<EditarUsuario />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/editar-alergeno/:id" element={<EditarAlergeno />} />
            <Route path="/nuevo-alergeo" element={<NuevoAlergeno />} />
            <Route path="/editar-categoria-ingrediente/:id" element={<EditarCategoriaIngrediente />} />
            <Route path="/nueva-categoria-ingrediente" element={<NuevaCategoriaIngrediente />} />
            <Route path="/gestion-weights/:id" element={<GestionWeights />} />
            <Route path="/gestion-bmi/:id" element={<GestionBMI />} />
            <Route path="/gestion-bodyfat/:id" element={<GestionBodyFat />} />
            <Route path="/confirmar/:token" element={<ConfirmEmail />} />
            <Route path="/cambiar-contrasena" element={<ForgotPassword />} />
            <Route path="/restablecer-contrasena/:token" element={<ResetPassword />} />

          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
