import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Legend } from 'recharts';
import { Box, Typography, useTheme, useMediaQuery, Tabs, Tab } from '@mui/material';

const NutritionChartWeek = ({ menuData, userInfo }) => {
  const [data, setData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [yAxisDomain, setYAxisDomain] = useState([0, 'auto']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const processChartData = (menuData) => {
    const nutrientsByDay = {};
    const daysOfWeek = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
    const today = new Date();
    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));

    daysOfWeek.forEach((day, index) => {
      const currentDay = new Date(firstDayOfWeek);
      currentDay.setDate(currentDay.getDate() + index);
      const formattedDate = currentDay.toISOString().split('T')[0];
      nutrientsByDay[formattedDate] = {
        day,
        kilocalories: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
      };
    });

    menuData.forEach(item => {
      const day = item.date.split('T')[0];
      if (nutrientsByDay[day]) {
        nutrientsByDay[day].kilocalories += Math.round(item.macronutrients.kilocalories);
        nutrientsByDay[day].protein += Math.round(item.macronutrients.protein);
        nutrientsByDay[day].carbs += Math.round(item.macronutrients.carbs);
        nutrientsByDay[day].fat += Math.round(item.macronutrients.fat);
      }
    });

    const processedData = Object.values(nutrientsByDay);
    setData(processedData);

    const maxDataValue = Math.max(
      ...processedData.flatMap(day => [day.carbs, day.protein, day.fat]),
      userInfo?.macros?.carbs || 0,
      userInfo?.macros?.protein || 0,
      userInfo?.macros?.fat || 0
    );

    setYAxisDomain([0, Math.ceil(maxDataValue * 1.1)]);
  };

  useEffect(() => {
    if (menuData.length > 0 && userInfo?.macros) {
      processChartData(menuData);
    }
  }, [menuData, userInfo]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{ bgcolor: 'background.paper', p: 2, border: '1px solid #ccc', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
          <Typography variant="body2" fontWeight="bold">{`Día: ${label}`}</Typography>
          {payload.map((entry, index) => (
            <Typography key={`item-${index}`} variant="body2" color={entry.color}>
              {`${entry.name}: ${entry.value.toFixed(0)}${entry.name === 'Calorías' ? ' kcal' : 'g'}`}
            </Typography>
          ))}
        </Box>
      );
    }
    return null;
  };

  const CaloriesChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#f0f0f0" />
        <XAxis dataKey="day" axisLine={false} tickLine={false} />
        <YAxis hide domain={[0, 'auto']} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <ReferenceLine y={userInfo?.macros?.kilocalories} stroke={theme.palette.error.light} strokeDasharray="3 3" label={{ value: "Objetivo", position: "insideTopRight", fill: theme.palette.error.light }} />
        <Bar dataKey="kilocalories" fill={theme.palette.kilocalories.main} name="Calorías" radius={[4, 4, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );

  const MacrosChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#f0f0f0" />
        <XAxis dataKey="day" axisLine={false} tickLine={false} />
        <YAxis domain={yAxisDomain} hide />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <ReferenceLine y={userInfo?.macros?.carbs} stroke={theme.palette.carbs.main} strokeDasharray="3 3" label={{ value: "Obj. Carbos", position: "insideTopRight", fill: theme.palette.carbs.main }} />
        <ReferenceLine y={userInfo?.macros?.protein} stroke={theme.palette.protein.main} strokeDasharray="3 3" label={{ value: "Obj. Proteínas", position: "insideTopRight", fill: theme.palette.protein.main }} />
        <ReferenceLine y={userInfo?.macros?.fat} stroke={theme.palette.fat.main} strokeDasharray="3 3" label={{ value: "Obj. Grasas", position: "insideTopRight", fill: theme.palette.fat.main }} />
        <Bar dataKey="carbs" fill={theme.palette.carbs.main} name="Carbohidratos" radius={[4, 4, 0, 0]} />
        <Bar dataKey="protein" fill={theme.palette.protein.main} name="Proteínas" radius={[4, 4, 0, 0]} />
        <Bar dataKey="fat" fill={theme.palette.fat.main} name="Grasas" radius={[4, 4, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange} 
        centered 
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main,
          },
          '& .MuiTab-root': {
            color: theme.palette.text.secondary,
            '&.Mui-selected': {
              color: theme.palette.primary.main,
            },
          },
        }}
      >
        <Tab label="Kilocalorías" />
        <Tab label="Macros" />
      </Tabs>
      <Box mt={2}>
        {tabValue === 0 ? <CaloriesChart /> : <MacrosChart />}
      </Box>
    </Box>
  );
};

export default NutritionChartWeek;
