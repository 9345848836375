import React, { useEffect, useCallback } from 'react';
import { 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Box,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';


const BasicInfo = ({ userData, handleInputChange }) => {
  const calculateAge = useCallback((birthDate) => {
    if (!birthDate) return '';
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const m = today.getMonth() - birthDateObj.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    return age;
  }, []);

  const handleDateChange = (e) => {
    const birthDate = e.target.value;
    handleInputChange('birthDate', birthDate);
    const age = calculateAge(birthDate);
    handleInputChange('age', age);
  };

  useEffect(() => {
    if (userData.birthDate && userData.age === '') {
      const age = calculateAge(userData.birthDate);
      handleInputChange('age', age);
    }
  }, [userData.birthDate, userData.age, calculateAge, handleInputChange]);

  return (
    <Box>
      <TextField
        fullWidth
        label="Nombre"
        value={userData.name || ''}
        onChange={(e) => handleInputChange('name', e.target.value)}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Fecha de nacimiento"
        type="date"
        value={userData.birthDate ? userData.birthDate.split('T')[0] : ''}
        onChange={handleDateChange}
        margin="normal"
        required
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          max: new Date().toISOString().split('T')[0]
        }}
      />
      <FormControl fullWidth margin="normal" required>
        <InputLabel>Género</InputLabel>
        <Select
          value={userData.sex || ''}
          onChange={(e) => handleInputChange('sex', e.target.value)}
        >
          <MenuItem value="Masculino">Masculino</MenuItem>
          <MenuItem value="Femenino">Femenino</MenuItem>
          <MenuItem value="Otro">Otro</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Altura (cm)"
        type="number"
        value={userData.heightCM || ''}
        onChange={(e) => handleInputChange('heightCM', e.target.value)}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Peso (kg)"
        type="number"
        value={userData.weight || ''}
        onChange={(e) => handleInputChange('weight', e.target.value)}
        margin="normal"
        required
      />
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <TextField
          fullWidth
          label="Número de comensales por defecto"
          type="number"
          value={userData.comensalesCasa || ''}
          onChange={(e) => {
            const value = parseInt(e.target.value, 10);
            if (value >= 1 && value <= 10) {
              handleInputChange('comensalesCasa', value);
            }
          }}
          margin="normal"
          required
          inputProps={{
            min: 1,
            max: 10,
            step: 1
          }}
        />
        <Tooltip title="Este valor se utilizará para calcular la cantidad de ingredientes en la lista de la compra">
          <IconButton size="small" sx={{ ml: 1 }}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography variant="caption" color="textSecondary">
        * Este valor se utilizará para calcular la cantidad de ingredientes en la lista de la compra
      </Typography>
    </Box>
  );
};

export default BasicInfo;
