import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useAuth } from "../context/AuthContext";
import { Box } from '@mui/system';

function UserProfile() {
  const [user, setUser] = useState({});
  const { authToken } = useAuth(); // Obtén authToken desde el AuthContext

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/profile`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
        const data = await response.json();
        setUser(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh', maxWidth: '50%', margin: '0 auto' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Name:</Typography>
          <Typography variant="body1">{user.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Email:</Typography>
          <Typography variant="body1">{user.email}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserProfile;