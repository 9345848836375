import React, { useState } from 'react';
import { TextField, Button, Grid, Typography } from '@mui/material';

function NuevoAlergeno({ onAlergenoCreated }) {
  const [alergeno, setAlergeno] = useState({ name: '', description: '' });

  const handleChange = (event) => {
    setAlergeno({ ...alergeno, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/allergens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(alergeno),
    })
      .then(response => response.json())
      .then(() => {
        onAlergenoCreated();
        setAlergeno({ name: '', description: '' });
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Nuevo Alérgeno</Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Nombre"
            name="name"
            value={alergeno.name}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Descripción"
            name="description"
            value={alergeno.description}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
          />
          <Button type="submit" variant="contained" color="primary">
            Crear Alérgeno
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}

export default NuevoAlergeno;