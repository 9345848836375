import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { List, ListItem, ListItemText, Checkbox, ListItemSecondaryAction, Grid, Button, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const MisListasSingle = () => {
  const { id } = useParams();
  const [lista, setLista] = useState(null);
  const [allIngredients, setAllIngredients] = useState([]);

  useEffect(() => {
    const fetchLista = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/listascompra/${id}`);
        if (!response.ok) {
          throw new Error('Error fetching list');
        }
        const data = await response.json();
        setLista(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLista();
  }, [id]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/ingredients`)
      .then((response) => response.json())
      .then((data) => setAllIngredients(data));
  }, []);

  const handleToggle = (index, idIngrediente) => {
    console.log(index, idIngrediente);
    const newLista = { ...lista };
    const ingrediente = newLista.ingredientes[index];
    const nuevoEstado = ingrediente.estado === 'comprado' ? 'pendiente' : 'comprado';
    ingrediente.estado = nuevoEstado;
    setLista(newLista);

    const body = {
      idIngrediente: idIngrediente,
      estadoNuevo: nuevoEstado
    };
    console.log(body);

    fetch(`${process.env.REACT_APP_API_URL}/listascompra/id/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error updating ingredient status');
        }
        return response.json();
      })
      .then(data => {
        console.log('Ingredient status updated successfully', data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  if (!lista) {
    return <div>Loading...</div>;
  }
  const texto = "¡Aquí tienes la lista de la compra!";
  const urlActual = window.location.href;
  const textoCodificado = encodeURIComponent(texto);
  const urlParaCompartir = `whatsapp://send?text=${textoCodificado}%20${encodeURIComponent(urlActual)}`;


  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
      <Grid container item xs={12} md={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ingrediente</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Comprado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lista.ingredientes
                .sort((a, b) => {
                  // Primero ordenar por estado
                  if (a.estado === 'pendiente' && b.estado === 'comprado') return -1;
                  if (a.estado === 'comprado' && b.estado === 'pendiente') return 1;

                  // Si el estado es el mismo, ordenar alfabéticamente
                  const ingredientDetailsA = allIngredients.find(i => i._id === a.idIngrediente);
                  const ingredientDetailsB = allIngredients.find(i => i._id === b.idIngrediente);

                  if (!ingredientDetailsA || !ingredientDetailsB) {
                    console.error(`No se encontró el ingrediente con ID ${a.idIngrediente} o ${b.idIngrediente}`);
                    return 0;
                  }

                  return ingredientDetailsA.name.localeCompare(ingredientDetailsB.name);
                })
                .map((ingrediente, index) => {
                  const ingredientDetails = allIngredients.find(i => i._id === ingrediente.idIngrediente);
                  if (!ingredientDetails) {
                    console.error(`No se encontró el ingrediente con ID ${ingrediente.idIngrediente}`);
                    return null; // o puedes devolver un componente de error o de carga aquí
                  }
                  return (
                    <TableRow key={ingrediente._id}>
                      <TableCell>{ingredientDetails.name}</TableCell>
                      <TableCell>{`${ingrediente.cantidad} ${ingredientDetails.standardUnit}`}</TableCell>
                      <TableCell>
                        <Checkbox
                          edge="end"
                          onChange={() => handleToggle(index, ingrediente._id)}
                          checked={ingrediente.estado === 'comprado'}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center" sx={{ mt: 4, width: '100%' }}>
          <Grid item xs={12} md={8}>
            <a href={urlParaCompartir} data-action="share/whatsapp/share">
              <Button variant="contained" sx={{ bgcolor: 'other.whatsapp' }}>
                Compartir por Whatsapp
              </Button>
            </a>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default MisListasSingle;