import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // Carga las traducciones
  .use(LanguageDetector) // Detecta el idioma del navegador
  .use(initReactI18next) // Inicializa react-i18next
  .init({
    supportedLngs: ['en', 'es'], // Idiomas soportados
    fallbackLng: 'es', // Idioma por defecto
    detection: {
        order: ['cookie'], // Solo usa cookies para detectar el idioma
        caches: ['cookie'], // Guardar la preferencia de idioma en una cookie
      },      
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Ruta para cargar las traducciones
    },
  });

export default i18n;
i18n.changeLanguage('es'); // Establece español como idioma inicial