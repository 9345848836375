import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Typography, CircularProgress, Select, MenuItem, FormControl, InputLabel, Chip } from '@mui/material';
import NoMetricsMessage from '../Dashboard/NoMetricsMessage';

const TimeFilters = {
  MONTH: '1 mes',
  QUARTER: '3 meses',
  HALF_YEAR: '6 meses',
  YEAR: '1 año',
  ALL: 'Todo'
};

const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088aa', '#ff5733', '#4caf50', '#9c27b0'];

const TrackingChart = ({ authToken }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeFilter, setTimeFilter] = useState(TimeFilters.ALL);
  const [metrics, setMetrics] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [status, setStatus] = useState('loading');
  const [metricColors, setMetricColors] = useState({});

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/metrics/user-metrics`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (!response.ok) throw new Error('Error fetching user metrics');
        const userMetrics = await response.json();
        const trackedMetrics = userMetrics.filter(metric => metric.isTracking);
        
        if (trackedMetrics.length === 0) {
          setStatus('noMetrics');
        } else {
          setMetrics(trackedMetrics);
          setSelectedMetrics(trackedMetrics.map(metric => metric.metricType._id));
          
          // Assign fixed colors to metrics
          const colorMap = {};
          trackedMetrics.forEach((metric, index) => {
            colorMap[metric.metricType._id] = colors[index % colors.length];
          });
          setMetricColors(colorMap);
          
          setStatus('hasData');
        }
      } catch (error) {
        console.error('Error fetching user metrics:', error);
        setStatus('noMetrics');
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [authToken]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedMetrics.length === 0) {
        setData([]);
        setFilteredData([]);
        setStatus('noSelection');
        return;
      }

      setLoading(true);
      try {
        const metricData = await Promise.all(
          selectedMetrics.map(async (metricId) => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/metrics/metric-entries/${metricId}`, {
              headers: { Authorization: `Bearer ${authToken}` },
            });
            if (!response.ok) throw new Error(`Error fetching data for metric ${metricId}`);
            return response.json();
          })
        );

        const combinedData = metricData.flat().reduce((acc, entry) => {
          const date = new Date(entry.date).toISOString().split('T')[0];
          if (!acc[date]) {
            acc[date] = { date };
          }
          acc[date][entry.metricType] = entry.value;
          return acc;
        }, {});

        const sortedData = Object.values(combinedData).sort((a, b) => new Date(a.date) - new Date(b.date));
        
        if (sortedData.length === 0) {
          setStatus('noData');
        } else {
          setData(sortedData);
          setFilteredData(sortedData);
          setStatus('hasData');
        }
      } catch (error) {
        console.error('Error fetching metric data:', error);
        setStatus('noData');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authToken, selectedMetrics]);

  useEffect(() => {
    const filterData = () => {
      const now = new Date();
      let filteredDate = new Date();
      
      switch(timeFilter) {
        case TimeFilters.MONTH:
          filteredDate.setMonth(now.getMonth() - 1);
          break;
        case TimeFilters.QUARTER:
          filteredDate.setMonth(now.getMonth() - 3);
          break;
        case TimeFilters.HALF_YEAR:
          filteredDate.setMonth(now.getMonth() - 6);
          break;
        case TimeFilters.YEAR:
          filteredDate.setFullYear(now.getFullYear() - 1);
          break;
        default:
          setFilteredData(data);
          return;
      }

      const filtered = data.filter(item => new Date(item.date) >= filteredDate);
      setFilteredData(filtered);
    };

    filterData();
  }, [timeFilter, data]);

  const handleTimeFilterChange = (event) => {
    setTimeFilter(event.target.value);
  };

  const handleMetricToggle = (metricId) => {
    setSelectedMetrics(prev => {
      const newSelection = prev.includes(metricId)
        ? prev.filter(id => id !== metricId)
        : [...prev, metricId];
      
      if (newSelection.length === 0) {
        setStatus('noSelection');
      } else {
        setStatus('hasData');
      }
      
      return newSelection;
    });
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (status === 'noMetrics') {
    return <NoMetricsMessage />;
  }

  const renderChartContent = () => {
    if (status === 'noData') {
      return (
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h6">Aún no has registrado ningún dato.</Typography>
        </Box>
      );
    }

    if (status === 'noSelection') {
      return (
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h6">Selecciona una métrica para ver tus datos.</Typography>
        </Box>
      );
    }

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={filteredData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date" 
            tickFormatter={(date) => new Date(date).toLocaleDateString()}
            domain={['dataMin', 'dataMax']}
          />
          <YAxis />
          <Tooltip 
            labelFormatter={(date) => new Date(date).toLocaleDateString()}
            formatter={(value, name) => [value !== undefined ? parseFloat(value).toFixed(2) : 'N/A', metrics.find(m => m.metricType._id === name)?.metricType.name]}
          />
          <Legend />
          {selectedMetrics.map((metricId) => (
            <Line
              key={metricId}
              type="monotone"
              dataKey={metricId}
              stroke={metricColors[metricId]}
              name={metrics.find(m => m.metricType._id === metricId)?.metricType.name}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <Box height={450}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Evolución del Seguimiento</Typography>
        <FormControl variant="outlined" size="small">
          <InputLabel>Período</InputLabel>
          <Select
            value={timeFilter}
            onChange={handleTimeFilterChange}
            label="Período"
          >
            {Object.values(TimeFilters).map((filter) => (
              <MenuItem key={filter} value={filter}>{filter}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box mb={2}>
        {metrics.map((metric) => (
          <Chip
            key={metric.metricType._id}
            label={metric.metricType.name}
            onClick={() => handleMetricToggle(metric.metricType._id)}
            color={selectedMetrics.includes(metric.metricType._id) ? "primary" : "default"}
            style={{ 
              margin: '0 4px 4px 0', 
              backgroundColor: selectedMetrics.includes(metric.metricType._id) ? metricColors[metric.metricType._id] : undefined 
            }}
          />
        ))}
      </Box>
      <Box height="calc(100% - 100px)">
        {renderChartContent()}
      </Box>
    </Box>
  );
};

export default TrackingChart;