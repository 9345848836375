import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Select, Menu, MenuItem, InputLabel, FormControl, Grid, Box, Autocomplete, FormHelperText, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert, Checkbox, ListItemText, FormControlLabel, ListItem, Paper, useMediaQuery, useTheme } from '@mui/material/';
import { useAuth } from "../context/AuthContext";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import IngredientItem from './Common/IngredientItem'; // Asegúrate de importar el nuevo componente

const NuevaReceta = () => {
  const theme = useTheme();
  const [name, setName] = useState('');
  const [ingredients, setIngredients] = useState([{ ingredientId: '', quantity: '' }]);
  const [steps, setSteps] = useState(['']);
  const [servings, setServings] = useState('');
  const [prepTime, setPrepTime] = useState({ hours: '', minutes: '' });
  const [tipoDeCocina, setTipoDeCocina] = useState([]);
  const [restricciones, setRestricciones] = useState([]);
  const [categoriaRecetas, setCategoriaRecetas] = useState([]); // Añadir estado para categoría de recetas
  const [momentoDelDia, setMomentoDelDia] = useState('');
  const [tipoPlato, setTipoPlato] = useState([]);
  const [allIngredients, setAllIngredients] = useState([]);
  const [difficulty, setDifficulty] = useState('');
  const { authToken, userRole } = useAuth(); // Add userRole to the destructured values
  const [createdBy, setUserId] = useState(null);
  const hasFetchedUser = useRef(false);
  const [totalMacronutrients, setTotalMacronutrients] = useState(null);
  const [categories, setCategories] = useState([]);
  const [allTipoDeCocina, setAllTipoDeCocina] = useState([]);
  useEffect(() => {
    //imprimir allTipoDeCocina cada vez que cambie
    console.log("allTipoDeCocina: ", allTipoDeCocina);
  }, [allTipoDeCocina]);
  const [allRestricciones, setAllRestricciones] = useState([]);
  const [message, setMessage] = useState(null);
  const [externalUrl, setExternalUrl] = useState('');
  const [allCategoriaRecetas, setAllCategoriaRecetas] = useState([]); // Añadir estado para todas las categorías de recetas
  const navigate = useNavigate();
  const [revisado, setRevisado] = useState(false);
  const isAdmin = userRole === 'admin';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Error fetching user');
        }

        const userData = await response.json();
        setUserId(userData.id);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (authToken && !hasFetchedUser.current) {
      fetchUser();
      hasFetchedUser.current = true;
    }
  }, [authToken]);

  useEffect(() => {
    Promise.all([
      fetch(`${process.env.REACT_APP_API_URL}/ingredients`),
      fetch(`${process.env.REACT_APP_API_URL}/categoriaRecetas`),
      fetch(`${process.env.REACT_APP_API_URL}/categorias`),
      fetch(`${process.env.REACT_APP_API_URL}/restricciones`),
      fetch(`${process.env.REACT_APP_API_URL}/tipoDeCocina`),
    ])
      .then((responses) => Promise.all(responses.map((response) => response.json())))
      .then(([ingredients, categoriaRecetas, categories, restricciones, tipoDeCocina]) => {
        setAllIngredients(ingredients);
        setAllCategoriaRecetas(categoriaRecetas);
        setCategories(categories);
        setAllRestricciones(restricciones);
        setAllTipoDeCocina(tipoDeCocina);
      });
  }, []);

  useEffect(() => {
    //console.log("CambioEnRestricciones: ", restricciones);
  }, [restricciones]);

  useEffect(() => {
    console.log('Debug - Ingredientes actualizados:', ingredients);
    console.log('Debug - Macronutrientes actuales:', totalMacronutrients);
  }, [ingredients, totalMacronutrients]);

  const handleRevisadoChange = (event) => {
    setRevisado(event.target.checked);
  };

  const calculoNutrientesReceta = async (ingredients, servings) => {
    console.log('Debug - Iniciando cálculo de nutrientes:', { ingredients, servings });
    const cantidadesDeIngredientes = {};

    for (const ingredient of ingredients) {
      if (!ingredient.ingredientId || ingredient.quantity === undefined) {
        continue;
      }

      if (!cantidadesDeIngredientes[ingredient.ingredientId]) {
        cantidadesDeIngredientes[ingredient.ingredientId] = {
          quantity: 0,
          gramsPerUnit: 1,
        };
      }

      cantidadesDeIngredientes[ingredient.ingredientId].quantity += ingredient.quantity / servings;
    }

    let totalMacronutrients = {
      kilocalories: 0,
      carbs: 0,
      protein: 0,
      fat: 0,
    };

    for (const ingredientId in cantidadesDeIngredientes) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/ingredients/${ingredientId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const ingredient = await response.json();

        let consumedQuantity = cantidadesDeIngredientes[ingredientId].quantity;

        if (ingredient.standardUnit !== 'gramos') {
          consumedQuantity *= ingredient.gramsPerUnit;
        }

        consumedQuantity /= 100; // Convertir a 100g

        totalMacronutrients.kilocalories += consumedQuantity * ingredient.kilocalories;
        totalMacronutrients.carbs += consumedQuantity * ingredient.carbs;
        totalMacronutrients.protein += consumedQuantity * ingredient.protein;
        totalMacronutrients.fat += consumedQuantity * ingredient.fat;
      } catch (error) {
        console.error(`Error al obtener el ingrediente ${ingredientId}:`, error);
      }
    }

    // Formatear los macronutrientes a un máximo de 2 decimales
    Object.keys(totalMacronutrients).forEach(key => {
      totalMacronutrients[key] = parseFloat(totalMacronutrients[key].toFixed(2));
    });

    return totalMacronutrients;
  };

  // const handleTipoDeCocinaChange = (event) => {
  //   const { value } = event.target;
  //   let newTipoDeCocina;
  //   if (tipoDeCocina.includes(value)) {
  //     // Si el valor ya está seleccionado, quítalo (deseleccionar)
  //     newTipoDeCocina = tipoDeCocina.filter(item => item !== value);
  //   } else {
  //     // Si el valor no está seleccionado, agrégalo (seleccionar)
  //     newTipoDeCocina = [...tipoDeCocina, value];
  //   }
  //   setTipoDeCocina(newTipoDeCocina);
  //   console.log(newTipoDeCocina)
  //   console.log("hola")
  // };

  const handleIngredientChange = (index, field) => async (event, newValue) => {
    console.log('Debug - handleIngredientChange llamado:', { index, field, event, newValue });
    let value;
    let unit;
    if (newValue) {
      value = newValue._id;
      unit = newValue.standardUnit !== 'grams' ? newValue.standardUnit : undefined;
    } else if (event.target) {
      value = event.target.value;
    }

    setIngredients(prevIngredients => {
      const newIngredients = [...prevIngredients];
      newIngredients[index] = { ...newIngredients[index], [field]: value };
      if (unit) {
        newIngredients[index] = { ...newIngredients[index], unit };
      }

      calculoNutrientesReceta(newIngredients, servings)
        .then(totalMacronutrients => {
          setTotalMacronutrients(totalMacronutrients);
        });

      return newIngredients;
    });
  };


  const handleAddIngredient = () => {
    setIngredients([...ingredients, { ingredientId: '', quantity: '' }]);
  };

  const handleIngredientDelete = (index) => {
    setIngredients(ingredients.filter((_, i) => i !== index));
  };

  const [mainImage, setMainImage] = useState('');

  useEffect(() => {
    console.log("mainimage: ", mainImage);
  }, [mainImage]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${authToken}` // Asume que el token se almacena en localStorage
        },
      });

      if (!response.ok) {
        throw new Error('Error al subir la imagen');
      }

      const data = await response.json();
      setMainImage(data.path);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleStepChange = (index) => (event) => {
    const newSteps = [...steps];
    newSteps[index] = event.target.value;
    setSteps(newSteps);
  };

  const handleAddStep = () => {
    setSteps([...steps, '']);
  };

  useEffect(() => {
    console.log('name:', name);
    console.log('ingredients:', ingredients);
    console.log('steps:', steps);
    console.log('servings:', servings);
    console.log('prepTime:', prepTime);
    console.log('tipoDeCocina:', tipoDeCocina);
    console.log('restricciones:', restricciones);
    console.log('momentoDelDia:', momentoDelDia);
    console.log('createdBy:', createdBy);
    console.log('externalUrl:', externalUrl);
    console.log('mainImage:', mainImage);
    console.log('tipoPlato:', tipoPlato);
    console.log('difficulty:', difficulty);
  }, [name, ingredients, steps, servings, prepTime, tipoDeCocina, restricciones, momentoDelDia, createdBy, externalUrl, tipoPlato, mainImage, difficulty]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      name,
      ingredients,
      steps,
      servings,
      prepTime,
      tipoDeCocina,
      restricciones,
      momentoDelDia,
      createdBy,
      externalUrl,
      mainImage,
      tipoPlato,
      difficulty,
      revisado: isAdmin ? revisado : undefined,
    };

    // Filtrar los campos vacíos
    const recipeData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v !== ''));

    console.log("RecetaFinal: ", recipeData);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/recipes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(recipeData)
      });

      if (!response.ok) {
        throw new Error('Error creating recipe');
      }

      const newRecipe = await response.json();
      console.log('New recipe created:', newRecipe);
      // Mostrar mensaje de éxito y redirigir al usuario
      setMessage('Receta creada con éxito');
      setTimeout(() => {
        navigate('/recetas');
      }, 2000); // Redirige después de 2 segundos
    } catch (error) {
      console.error('Error:', error);
      setMessage('Hubo un error al crear la receta');
    }
  };


  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [ingrediente, setIngrediente] = useState({
    name: '',
    kilocalories: '',
    carbs: '',
    protein: '',
    fat: '',
    categoria: '',
    subcategoria: '',
    standardUnit: 'gramos',
    gramsPerUnit: '',
    createdBy: ''
  });
  const [anchorEl, setAnchorEl] = useState(null);
  console.log("nuevoingrediente creado inicial", ingrediente);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/categorias`)
      .then(response => response.json())
      .then(data => {
        setCategorias(data);
      })
      .catch(error => {
        console.error('Error fetching categories', error);
      });
  }, []);

  const handleChangeNuevoIngrediente = (event) => {
    setIngrediente({
      ...ingrediente,
      [event.target.name]: event.target.value
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickMenuCategoria = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuCategoria = () => {
    setAnchorEl(null);
  };

  const handleAdd = () => {
    const ingredienteWithCreatedBy = {
      ...ingrediente,
      createdBy: createdBy
    };

    fetch(`${process.env.REACT_APP_API_URL}/ingredients`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(ingredienteWithCreatedBy)
    })
      .then(() => {
        setOpenSnackbar(true);
        setOpen(false);
        // Fetch the updated list of ingredients
        fetch(`${process.env.REACT_APP_API_URL}/ingredients`)
          .then(response => response.json())
          .then(data => setAllIngredients(data))
          .catch(error => console.error('Error fetching ingredients', error));
      })
      .catch(error => {
        console.error('Error adding ingredient', error);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', margin: '20px auto', maxWidth: isMobile ? '90%' : '60%' }}>
      <form onSubmit={handleSubmit}>
        <h1>Nueva Receta</h1>
        <h2>Macronutrientes:</h2>
        {totalMacronutrients && (
          <Box display="flex" justifyContent="center">
            <Grid container spacing={3} sx={{
              maxWidth: {
                xs: '85%', // Para móviles
                sm: '40%', // Para tabletas y PCs
              }
            }}>

              <Grid item xs={3}>
                <p>{totalMacronutrients.kilocalories}</p>
                <p>Kilocalorías</p>
              </Grid>
              <Grid item xs={3}>
                <p>{totalMacronutrients.protein}</p>
                <p>Proteínas</p>
              </Grid>
              <Grid item xs={3}>
                <p>{totalMacronutrients.carbs}</p>
                <p>Carbohidratos</p>
              </Grid>
              <Grid item xs={3}>
                <p>{totalMacronutrients.fat}</p>
                <p>Grasas</p>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <Grid container spacing={3} sx={{
            maxWidth: {
              xs: '85%', // Para móviles
              sm: '60%', // Para tabletas y PCs
            }
          }}>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <TextField label="Nombre" value={name} onChange={(e) => setName(e.target.value)} required />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Número de Raciones"
                  value={servings}
                  onChange={(e) => setServings(e.target.value)} // Actualiza el estado al cambiar el valor
                  type="number" // Considera agregar un tipo numérico si es adecuado
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="URL de la Receta"
                  value={externalUrl}
                  onChange={(e) => setExternalUrl(e.target.value)}
                // required
                />
              </FormControl>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <h2>Ingredientes</h2>
                <Button variant="contained" sx={{ bgcolor: 'primary.main', color: '#fff' }} onClick={handleOpen}>
                  ¿No encuentras un ingrediente? ¡Crea uno nuevo!
                </Button>

                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Añadir un nuevo ingrediente</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      name="name"
                      label="Nombre"
                      type="text"
                      fullWidth
                      onChange={handleChangeNuevoIngrediente}
                    />
                    {/* Add more TextField components for other fields */}
                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle>Danos algo de información sobre tu nuevo ingrediente</DialogTitle>
                      <DialogContent>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          <Grid container spacing={2}>

                            <Grid item xs={12}>
                              <Typography variant="h6">Información básica</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                autoFocus
                                margin="dense"
                                name="name"
                                label="Nombre"
                                type="text"
                                fullWidth
                                onChange={handleChangeNuevoIngrediente}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="h6">Categoría de supermercado</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel id="categoria-label">Categoría</InputLabel>
                                <Select
                                  labelId="categoria-label"
                                  value={ingrediente.categoria}
                                  onChange={(event) => {
                                    const categoria = categorias.find(cat => cat._id === event.target.value);
                                    setIngrediente(prev => ({
                                      ...prev,
                                      categoria: categoria._id,
                                      subcategoria: categoria.subcategorias[0]
                                    }));
                                  }}
                                >
                                  {categorias.map((categoria) => (
                                    <MenuItem key={categoria._id} value={categoria._id}>{categoria.nombre}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel id="subcategoria-label">Subcategoría</InputLabel>
                                <Select
                                  labelId="subcategoria-label"
                                  value={ingrediente.subcategoria}
                                  onChange={(event) => setIngrediente(prev => ({ ...prev, subcategoria: event.target.value }))}
                                >
                                  {categorias.find(categoria => categoria._id === ingrediente.categoria)?.subcategorias.map((subcategoria) => (
                                    <MenuItem key={subcategoria} value={subcategoria}>{subcategoria}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="h6">Información de medidas</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel id="standardUnit-label">Unidad estándar</InputLabel>
                                <Select
                                  labelId="standardUnit-label"
                                  name="standardUnit"
                                  value={ingrediente.standardUnit}
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    handleChangeNuevoIngrediente(event);
                                    if (value === 'gramos') {
                                      setIngrediente(prev => ({ ...prev, gramsPerUnit: 1 }));
                                    }
                                  }}
                                >
                                  <MenuItem value="gramos">Gramos</MenuItem>
                                  <MenuItem value="uds">Unidades</MenuItem>
                                  <MenuItem value="cucharadas">Cucharadas</MenuItem>
                                  <MenuItem value="cucharaditas">Cucharaditas</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                margin="dense"
                                name="gramsPerUnit"
                                label="Gramos por unidad"
                                type="number"
                                fullWidth
                                onChange={handleChangeNuevoIngrediente}
                              />
                            </Grid>
                            <Typography variant="h6">Indica los macronutrientes por cada 100g</Typography>
                            <Typography variant="body2">Esta parte es opcional, pero si no lo rellenas, no podremos determinar los macronutrientes que contiene la receta 😉.</Typography>
                            <Typography variant="body2">Puedes encontrar esta información en la parte trasera de la etiqueta de cualquier alimento.</Typography>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                margin="dense"
                                name="kilocalories"
                                label="Kilocalorías"
                                type="number"
                                fullWidth
                                onChange={handleChangeNuevoIngrediente}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                margin="dense"
                                name="carbs"
                                label="Carbohidratos"
                                type="number"
                                fullWidth
                                onChange={handleChangeNuevoIngrediente}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                margin="dense"
                                name="protein"
                                label="Proteínas"
                                type="number"
                                fullWidth
                                onChange={handleChangeNuevoIngrediente}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                margin="dense"
                                name="fat"
                                label="Grasas"
                                type="number"
                                fullWidth
                                onChange={handleChangeNuevoIngrediente}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Cancelar
                        </Button>
                        <Button onClick={handleAdd} color="primary">
                          Añadir
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancelar
                    </Button>
                    <Button onClick={() => {
                      console.log("nuevoingrediente Añadido a API:", ingrediente);
                      handleAdd();
                    }} color="primary">
                      Añadir
                    </Button>
                  </DialogActions>
                </Dialog>

                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                  <Alert onClose={handleCloseSnackbar} severity="success">
                    El ingrediente se ha añadido con éxito.
                  </Alert>
                </Snackbar>
              </Grid>
              {ingredients.map((ingredient, index) => (
                <Grid item xs={12}>
                  <Typography align="left" style={{ marginTop: '10px' }}>Ingrediente {index + 1}</Typography>
                  <Grid container key={index}>
                    <Grid item xs={12} sm={5}>
                      <FormControl fullWidth margin="normal">
                        <Autocomplete
                          options={allIngredients}
                          getOptionLabel={(option) => option.name}
                          value={allIngredients.find((ing) => ing._id === ingredient.ingredientId)}
                          onChange={(event, newValue) => {
                            handleIngredientChange(index, 'ingredientId')(event, newValue);
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <IngredientItem ingredient={option} isAdmin={userRole === 'admin'} />
                            </li>
                          )}
                          renderInput={(params) => <TextField {...params} label="Ingrediente" required />}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          label={`Cantidad (en ${allIngredients.find((ing) => ing._id === ingredient.ingredientId)?.standardUnit})`}
                          value={ingredient.quantity}
                          onChange={handleIngredientChange(index, 'quantity')}
                          type="number"
                          required
                        />
                        {allIngredients.find((ing) => ing._id === ingredient.ingredientId)?.gramsPerUnit > 1 && (
                          <FormHelperText>
                            {'Una unidad son aproximadamente '}
                            {allIngredients.find((ing) => ing._id === ingredient.ingredientId)?.gramsPerUnit}
                            {' gramos'}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    {ingredient.ingredientId && (
                      <Grid item xs={2} sm={1}>
                        <Box display="flex" alignItems="center">
                          <IngredientItem
                            ingredient={allIngredients.find(ing => ing._id === ingredient.ingredientId)}
                            isAdmin={userRole === 'admin'}
                            showName={false}
                          />
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={2} sm={2}>
                      <IconButton onClick={() => handleIngredientDelete(index)}>
                        <DeleteIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Button onClick={handleAddIngredient}>Añadir ingrediente</Button>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5">Información Adicional (opcional)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                      <input
                        accept="image/*"
                        id="mainImage"
                        type="file"
                        onChange={(e) => handleImageUpload(e)}
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="mainImage">
                        <Button variant="contained" color="primary" component="span">
                          Subir imagen principal
                        </Button>
                      </label>
                      {mainImage && (
                        <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                          <Grid item>
                            <img
                              src={mainImage}
                              alt="Imagen principal"
                              style={{ width: '100px', height: '100px' }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <h3>Pasos</h3>
                    </Grid>
                    {steps.map((step, index) => (
                      <Grid item xs={12} key={index}>
                        <FormControl fullWidth margin="normal">
                          <TextField
                            label={`Paso ${index + 1}`}
                            value={step}
                            onChange={handleStepChange(index)}
                          />
                        </FormControl>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Button onClick={handleAddStep}>Añadir paso</Button>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3>Tiempo de preparación</h3>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <FormControl fullWidth margin="normal">
                        <TextField label="Horas" type="Number" value={prepTime.hours} onChange={(e) => setPrepTime({ ...prepTime, hours: e.target.value })} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          type="number"
                          label="Minutos"
                          value={prepTime.minutes}
                          onChange={(e) => setPrepTime({ ...prepTime, minutes: e.target.value })}
                          inputProps={{ min: 0, max: 59 }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <h3>Categorías</h3>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: '8px', marginTop: '16px' }}>
                    <Autocomplete
                      multiple
                      id="tipoDeCocina"
                      options={allTipoDeCocina}
                      getOptionLabel={(option) => option.name}
                      value={tipoDeCocina}
                      onChange={(event, newValue) => setTipoDeCocina(newValue)}
                      filterSelectedOptions
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox checked={selected} />
                          <ListItemText primary={option.name} />
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Tipo de Cocina" placeholder="Tipo de Cocina" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: '8px', marginTop: '16px' }}>
                    <Autocomplete
                      multiple
                      id="restricciones"
                      options={allRestricciones}
                      getOptionLabel={(option) => option.name}
                      value={restricciones}
                      onChange={(event, newValue) => setRestricciones(newValue)}
                      filterSelectedOptions
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox checked={selected} />
                          <ListItemText primary={option.name} />
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Restricciones alimentarias" placeholder="Restricciones alimentarias" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: '8px', marginTop: '16px' }}>
                    <Autocomplete
                      multiple
                      id="categoriaRecetas"
                      options={allCategoriaRecetas}
                      getOptionLabel={(option) => option.nombre}
                      value={categoriaRecetas}
                      onChange={(event, newValue) => setCategoriaRecetas(newValue)}
                      filterSelectedOptions
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox checked={selected} />
                          <ListItemText primary={option.nombre} />
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Categorias" placeholder="Categorias" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: '8px', marginTop: '16px' }}>
                    <Autocomplete
                      multiple
                      id="tipoPlato"
                      options={['Desayuno', 'Snack', 'Entrante', 'Principal', 'Postre', 'Guarnición']} // Listado predefinido de opciones
                      getOptionLabel={(option) => option}
                      value={tipoPlato}
                      onChange={(event, newValue) => {
                        setTipoPlato(newValue);
                      }}
                      filterSelectedOptions
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox checked={selected} />
                          <ListItemText primary={option} />
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Tipo Plato" placeholder="Tipo Plato" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <FormControl variant="outlined" margin="normal" fullWidth>
                      <InputLabel id="dificultad-label">Dificultad</InputLabel>
                      <Select
                        labelId="dificultad-label"
                        id="dificultad"
                        value={difficulty}
                        onChange={(event) => {
                          setDifficulty(event.target.value);
                        }}
                        label="Dificultad"
                      >
                        <MenuItem value={'Baja'}>Baja</MenuItem>
                        <MenuItem value={'Media'}>Media</MenuItem>
                        <MenuItem value={'Alta'}>Alta</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {isAdmin && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={revisado}
                      onChange={handleRevisadoChange}
                      name="revisado"
                      color="primary"
                    />
                  }
                  label="Revisado"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button type="submit" sx={{ bgcolor: 'primary.main', color: '#fff', padding: '5px', fontSize: '20px' }}>Crear receta</Button>          </Grid>
          </Grid>
        </Box>
      </form>
    </Paper>
  );
};

export default NuevaReceta;