import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress } from '@mui/material';

function ConfirmEmail() {
  const [status, setStatus] = useState('verificando');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();
  const confirmAttempted = useRef(false);

  useEffect(() => {
    const confirmEmail = async () => {
      if (confirmAttempted.current) return;
      confirmAttempted.current = true;

      try {
        console.log('Intentando confirmar cuenta con token:', token);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/confirm/${token}`, {
          method: 'GET',
        });
        const data = await response.json();
        console.log('Respuesta del servidor:', response);
        
        if (response.ok) {
          setStatus('confirmado');
          setMessage(data.message || '¡Cuenta confirmada con éxito!');
          setTimeout(() => {
            navigate('/login', { state: { confirmationSuccess: true } });
          }, 2000);
        } else {
          setStatus('error');
          setMessage(data.message || 'Error al confirmar la cuenta');
        }
      } catch (error) {
        console.error('Error al confirmar la cuenta:', error);
        setStatus('error');
        setMessage('Error de conexión al confirmar la cuenta');
      }
    };

    confirmEmail();
  }, [token, navigate]);

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {status === 'verificando' && (
          <>
            <CircularProgress />
            <Typography variant="h5" sx={{ mt: 2 }}>Verificando tu cuenta...</Typography>
          </>
        )}

        {status === 'confirmado' && (
          <>
            <Typography variant="h5" color="primary">¡Cuenta confirmada con éxito!</Typography>
            <Typography sx={{ mt: 2 }}>{message}</Typography>
            <Typography sx={{ mt: 2 }}>Redirigiendo al inicio de sesión...</Typography>
          </>
        )}

        {status === 'error' && (
          <>
            <Typography variant="h5" color="error">Error al confirmar la cuenta</Typography>
            <Typography sx={{ mt: 2 }}>{message}</Typography>
          </>
        )}
      </Box>
    </Container>
  );
}

export default ConfirmEmail;