import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Snackbar, CircularProgress } from '@mui/material';
import NoMetricsMessage from './NoMetricsMessage';

const TrackingForm = ({ authToken }) => {
  const [formData, setFormData] = useState({});
  const [metrics, setMetrics] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/metrics/user-metrics`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (!response.ok) throw new Error('Error fetching user metrics');
        const userMetrics = await response.json();
        const trackedMetrics = userMetrics.filter(metric => metric.isTracking);
        setMetrics(trackedMetrics);
        
        const initialFormData = trackedMetrics.reduce((acc, metric) => {
          acc[metric.metricType._id] = '';
          return acc;
        }, {});
        setFormData({ ...initialFormData, date: new Date().toISOString().split('T')[0], notes: '' });
        
      } catch (error) {
        console.error('Error fetching user metrics:', error);
        setSnackbar({ open: true, message: 'Error al cargar las métricas' });
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [authToken]);

  const handleChange = (e) => {
    let value = e.target.value;
    
    if (e.target.name !== 'date' && e.target.name !== 'notes') {
      value = value.replace(',', '.');
    }
    
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const date = formData.date;
    const notes = formData.notes;

    const metricsToSend = Object.entries(formData)
      .filter(([key, value]) => key !== 'date' && key !== 'notes' && value !== '')
      .map(([metricTypeId, value]) => ({
        metricTypeId,
        value: parseFloat(value),
        date,
        notes
      }));

    let allSuccess = true;
    for (const metricEntry of metricsToSend) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/metrics/metric-entries`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify(metricEntry)
        });

        if (!response.ok) {
          throw new Error(`Error al guardar la métrica ${metricEntry.metricTypeId}`);
        }
      } catch (error) {
        console.error('Error:', error);
        allSuccess = false;
        break;
      }
    }

    if (allSuccess) {
      setSnackbar({ open: true, message: 'Datos guardados correctamente' });
      setFormData(prev => ({
        ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = key === 'date' ? new Date().toISOString().split('T')[0] : '';
          return acc;
        }, {})
      }));
    } else {
      setSnackbar({ open: true, message: 'Error al guardar algunos datos' });
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (metrics.length === 0) {
    return <NoMetricsMessage />;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Registrar Seguimiento</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          name="date"
          label="Fecha"
          type="date"
          value={formData.date}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            max: new Date().toISOString().split('T')[0],
          }}
        />
        {metrics.map(metric => (
          <TextField
            key={metric.metricType._id}
            name={metric.metricType._id}
            label={`${metric.metricType.name} (${metric.metricType.unit})`}
            type="text"
            value={formData[metric.metricType._id]}
            onChange={handleChange}
            fullWidth
            margin="normal"
            inputProps={{ inputMode: 'decimal' }}
          />
        ))}
        <TextField
          name="notes"
          label="Notas"
          multiline
          rows={4}
          value={formData.notes}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Guardar
        </Button>
      </form>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default TrackingForm;