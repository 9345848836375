import React, { useState, useEffect } from 'react';
import { 
  FormControl, 
  FormLabel, 
  RadioGroup, 
  FormControlLabel, 
  Radio, 
  Select, 
  MenuItem, 
  Checkbox, 
  Box, 
  Typography,
  CircularProgress,
  InputLabel
} from '@mui/material';
import { useAuth } from '../../../context/AuthContext';

const GoalsPreferences = ({ userData, handleInputChange }) => {
  const [dietaryOptions, setDietaryOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { authToken, refreshToken } = useAuth();

  useEffect(() => {
    fetchDietaryOptions();
  }, []);

  const fetchDietaryOptions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/restricciones`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          // Token expirado, intentar refrescar
          await refreshToken();
          // Reintentar la solicitud con el nuevo token
          return fetchDietaryOptions();
        }
        throw new Error('Error al obtener las preferencias dietéticas');
      }

      const data = await response.json();
      setDietaryOptions(data);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setError('No se pudieron cargar las preferencias dietéticas');
      setLoading(false);
    }
  };

  const handleDietaryPreferenceChange = (preferenceId) => {
    const newPreferences = userData.dietaryPreferences.includes(preferenceId)
      ? userData.dietaryPreferences.filter(id => id !== preferenceId)
      : [...userData.dietaryPreferences, preferenceId];
    handleInputChange('dietaryPreferences', newPreferences);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      <FormControl fullWidth margin="normal" required>
        <InputLabel>Objetivo</InputLabel>
        <Select
          value={userData.goal}
          onChange={(e) => handleInputChange('goal', e.target.value)}
        >
          <MenuItem value="Perder peso">Perder peso</MenuItem>
          <MenuItem value="Ganar masa muscular">Ganar masa muscular</MenuItem>
          <MenuItem value="Mantener peso">Mantener peso</MenuItem>
          <MenuItem value="Mejorar salud general">Mejorar salud general</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal" required>
        <InputLabel>Nivel de actividad</InputLabel>
        <Select
          value={userData.activityLevel}
          onChange={(e) => handleInputChange('activityLevel', e.target.value)}
        >
          <MenuItem value="Sedentario">Sedentario</MenuItem>
          <MenuItem value="Ligeramente activo">Ligeramente activo</MenuItem>
          <MenuItem value="Moderadamente activo">Moderadamente activo</MenuItem>
          <MenuItem value="Muy activo">Muy activo</MenuItem>
          <MenuItem value="Extremadamente activo">Extremadamente activo</MenuItem>
        </Select>
      </FormControl>
      <Box margin="normal">
        <Typography variant="subtitle1">Preferencias dietéticas</Typography>
        {dietaryOptions.map((preference) => (
          <FormControlLabel
            key={preference._id}
            control={
              <Checkbox
                checked={userData.dietaryPreferences.includes(preference._id)}
                onChange={() => handleDietaryPreferenceChange(preference._id)}
              />
            }
            label={preference.name}
          />
        ))}
      </Box>
    </Box>
  );
};

export default GoalsPreferences;