import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CancelOutlined from '@mui/icons-material/CancelOutlined';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);


  const passwordCriteria = [
    { label: 'Al menos 8 caracteres', test: (password) => password.length >= 8 },
    { label: 'Una letra mayúscula', test: (password) => /[A-Z]/.test(password) },
    { label: 'Una letra minúscula', test: (password) => /[a-z]/.test(password) },
    { label: 'Un número', test: (password) => /\d/.test(password) },
    { label: 'Un carácter especial', test: (password) => /[\W_]/.test(password) },
  ];

  const isPasswordSecure = (password) => {
    return passwordCriteria.every((criteria) => criteria.test(password));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    if (!isPasswordSecure(password)) {
      setError('La contraseña no cumple con los requisitos de seguridad.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setRegistrationSuccess(true);
        // No guardamos el token ni redirigimos al dashboard
      } else {
        setError(data.message || 'Error al registrar el usuario');
      }
    } catch (error) {
      setError('Error al conectar con el servidor');
    }
  };

  if (registrationSuccess) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Alert severity="success">
            Registro exitoso. Por favor, verifica tu correo electrónico para confirmar tu cuenta.
          </Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Registrarse
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo Electrónico"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <List>
            {passwordCriteria.map((criteria, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  {criteria.test(password) ? <CheckCircleOutline color="success" /> : <CancelOutlined color="error" />}
                </ListItemIcon>
                <ListItemText primary={criteria.label} />
              </ListItem>
            ))}
          </List>
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirmar Contraseña"
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            autoComplete="current-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {confirmPassword && password !== confirmPassword && (
            <Typography color="error">
              Las contraseñas no coinciden.
            </Typography>
          )}
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Registrarse
          </Button>
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            ¿Ya tienes una cuenta? <Link to="/login" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Inicia sesión aquí</Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Register;
