import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "../context/AuthContext";
import { 
    Typography, 
    Paper,
    Grid,
    Container,
    Box,
    Alert,
    AlertTitle,
    Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: '10px',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .03)',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
}));

const FieldLabel = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
}));

const FieldValue = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

function UserInfo() {
    const { authToken } = useAuth();
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        fetchUserData();
    }, [authToken]);

    const fetchUserData = async () => {
        try {
            const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            if (!userResponse.ok) throw new Error('Error fetching user');
            const userData = await userResponse.json();

            const userInfoResponse = await fetch(`${process.env.REACT_APP_API_URL}/userinfo/user/${userData.id}`, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            if (userInfoResponse.ok) {
                const userInfoData = await userInfoResponse.json();
                setUserInfo(userInfoData);
            } else if (userInfoResponse.status !== 404) {
                throw new Error('Error fetching user info');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const renderField = (label, value) => (
        <Grid item xs={12} sm={6} md={4}>
            <Box mb={2}>
                <FieldLabel variant="subtitle2">{label}</FieldLabel>
                <FieldValue>{Array.isArray(value) ? value.map(v => v.name).join(', ') : value}</FieldValue>
            </Box>
        </Grid>
    );

    if (!userInfo) return <Typography>Cargando...</Typography>;

    return (
        <Container maxWidth="lg">
            <Alert severity="info" sx={{ mb: 3 }}>
                <AlertTitle>¿Quieres modificar tu perfil nutricional y tus objetivos?</AlertTitle>
                <Button component={Link} to="/onboarding" variant="outlined" color="info">
                    Haz clic aquí para modificar tu perfil
                </Button>
            </Alert>

            <Typography variant="h4" component="h1" gutterBottom align="center" color="primary">
                Tu Perfil Nutricional
            </Typography>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <StyledPaper elevation={3}>
                        <SectionTitle variant="h5">Información Personal</SectionTitle>
                        <Grid container spacing={2}>
                            {renderField('Nombre', userInfo.name)}
                            {renderField('Fecha de Nacimiento', userInfo.birthDate ? new Date(userInfo.birthDate).toLocaleDateString() : 'No especificado')}
                            {renderField('Sexo', userInfo.sex)}
                            {renderField('Altura (cm)', userInfo.heightCM)}
                            {renderField('Peso Actual (kg)', userInfo.currentWeight?.$numberDecimal || userInfo.currentWeight)}
                        </Grid>
                    </StyledPaper>
                </Grid>

                <Grid item xs={12}>
                    <StyledPaper elevation={3}>
                        <SectionTitle variant="h5">Objetivos y Actividad</SectionTitle>
                        <Grid container spacing={2}>
                            {renderField('Nivel de Actividad', userInfo.activityLevel)}
                            {renderField('Objetivo', userInfo.goal)}
                            {renderField('Peso Objetivo (kg)', userInfo.targetWeight?.$numberDecimal || userInfo.targetWeight)}
                            {renderField('Fecha Objetivo', userInfo.targetDate ? new Date(userInfo.targetDate).toLocaleDateString() : 'No especificado')}
                        </Grid>
                    </StyledPaper>
                </Grid>

                <Grid item xs={12}>
                    <StyledPaper elevation={3}>
                        <SectionTitle variant="h5">Preferencias Alimentarias</SectionTitle>
                        <Grid container spacing={2}>
                            {renderField('Alergias', userInfo.allergies)}
                            {renderField('Alimentos no deseados', userInfo.dislikedFoods)}
                        </Grid>
                    </StyledPaper>
                </Grid>

                <Grid item xs={12}>
                    <StyledPaper elevation={3}>
                        <SectionTitle variant="h5">Hábitos de Cocina</SectionTitle>
                        <Grid container spacing={2}>
                            {renderField('Nivel de habilidad en la cocina', userInfo.cookingSkillLevel)}
                            {renderField('Tiempo disponible para cocinar', userInfo.cookingTime)}
                            {renderField('Comidas por día', userInfo.mealsPerDay)}
                        </Grid>
                    </StyledPaper>
                </Grid>

                <Grid item xs={12}>
                    <StyledPaper elevation={3}>
                        <SectionTitle variant="h5">Seguimiento</SectionTitle>
                        <Grid container spacing={2}>
                            {renderField('Frecuencia de seguimiento de peso', userInfo.weightTrackingFrequency)}
                            {renderField('Integración con app de salud', userInfo.healthAppIntegration ? 'Sí' : 'No')}
                        </Grid>
                    </StyledPaper>
                </Grid>

                <Grid item xs={12}>
                    <StyledPaper elevation={3}>
                        <SectionTitle variant="h5">Objetivos de Macronutrientes</SectionTitle>
                        <Grid container spacing={2}>
                            {renderField('Kilocalorías', userInfo.macros?.kilocalories)}
                            {renderField('Carbohidratos', userInfo.macros?.carbs)}
                            {renderField('Proteínas', userInfo.macros?.protein)}
                            {renderField('Grasas', userInfo.macros?.fat)}
                        </Grid>
                    </StyledPaper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default UserInfo;